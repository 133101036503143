import { US_STATES } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import { KnownClientData } from "@qw/qw-common";
import React, { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Currency } from "@/forms/borderless-currency";
import { Reform } from "@/forms/reform";
import { reloadOpportunity } from "@/hooks/useOpportunity";
import { InsuredFragment, useCreateClientDataMutation, useUpdateInsuredMutation } from "src/generated/graphql";

const FORM_ID = "update-insured-details-form";

interface Props {
  insured: InsuredFragment;
}

const InsuredFormSchema = z.object({
  name: z.string().min(1),
  primaryState: z.string().optional().nullable(),
  description: z.string().min(1, { message: "Business description is required" }),
  exposures: z.string().nullable(),
  revenue: z.number().optional(),
  tiv: z.number().optional(),
});

type InsuredForm = z.infer<typeof InsuredFormSchema>;

export const Summary: React.FC<Props> = ({ insured }) => {
  const [editing, setEditing] = useState(false);

  return editing ? (
    <Card>
      <CardHeader>
        <CardTitle>Edit Named Insured</CardTitle>
      </CardHeader>
      <UpdateInsuredForm insured={insured} setEditing={setEditing} />
      <CardFooter className="flex items-center justify-between">
        <Button variant="outline" size="sm" onClick={() => setEditing(false)}>
          Cancel
        </Button>
        <Button type="submit" size="sm" form={FORM_ID}>
          Save
        </Button>
      </CardFooter>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <CardTitle>Named Insured</CardTitle>
      </CardHeader>
      <ClientDetails insured={insured} />
      <CardFooter>
        <Button variant="secondary" size="sm" onClick={() => setEditing(true)} className="w-full">
          Edit Applicant Details
        </Button>
      </CardFooter>
    </Card>
  );
};

const UpdateInsuredForm = ({
  insured,
  setEditing,
}: {
  insured: InsuredFragment;
  setEditing: (bool: boolean) => void;
  desiredEffectiveDate?: string;
}) => {
  const formMethods = useForm<z.infer<typeof InsuredFormSchema>>({
    resolver: zodResolver(InsuredFormSchema),
    defaultValues: {
      ...insured,
      description: insured.description ?? undefined,
      revenue: insured.revenue ?? undefined,
      tiv: insured.tiv ?? undefined,
    },
  });

  const [updateInsured] = useUpdateInsuredMutation();
  const [updateClientData] = useCreateClientDataMutation();

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    { name, primaryState, description, exposures, revenue, tiv }: InsuredForm
  ) => {
    const dataToWrite = [];
    if (revenue && revenue !== insured.revenue) {
      dataToWrite.push({ key: KnownClientData.Revenue.CurrentYear, value: revenue.toString() });
    }
    if (tiv && tiv !== insured.tiv) {
      dataToWrite.push({ key: KnownClientData.TotalInsurableValue, value: tiv.toString() });
    }
    if (dataToWrite.length > 0) {
      await updateClientData({
        variables: {
          input: {
            insuredId: insured.id,
            clientDataInputs: dataToWrite,
            source: "UPDATE_CLIENT",
          },
        },
      });
    }

    await updateInsured({
      variables: {
        input: {
          id: insured.id,
          name: name,
          primaryState: primaryState,
          description: description,
          exposures,
        },
      },
      refetchQueries: [...reloadOpportunity.refetchQueries, "SearchAppetiteForOpportunity"],
    });

    setEditing(false);
  };

  const errors = formMethods.formState.errors;

  return (
    <CardContent className="space-y-3">
      <Reform
        schema={InsuredFormSchema}
        id={FORM_ID}
        onSubmit={handleSubmit}
        methods={formMethods}
        className="space-y-4"
      >
        <div>
          <h5 className="text-muted-foreground">Business Name *</h5>
          <span className="text-destructive">{errors?.name?.message}</span>
          <Input {...formMethods.register("name")} />
        </div>
        <div>
          <h5 className="text-muted-foreground">Primary State *</h5>
          <span className="text-destructive">{errors?.primaryState?.message}</span>
          <select {...formMethods.register("primaryState")}>
            {US_STATES.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div>
          <h5 className="text-muted-foreground">Gross Revenue</h5>
          <Currency
            className="pl-0 border"
            control={formMethods.control}
            name="revenue"
            required={false}
            placeholder="Gross Revenue"
          />
        </div>
        <div>
          <h5 className="text-muted-foreground">Business Description *</h5>
          <span className="text-destructive">{errors?.description?.message}</span>
          <Textarea {...formMethods.register("description")} />
        </div>
        <div>
          <h5 className="text-muted-foreground">Exposures</h5>
          <span className="text-destructive">{errors?.exposures?.message}</span>
          <Textarea {...formMethods.register("exposures")} />
        </div>
        {insured.tiv && (
          <div>
            <h5 className="text-muted-foreground">TIV</h5>
            <Currency
              className="pl-0 border"
              control={formMethods.control}
              name="tiv"
              required={false}
              placeholder="Total Insurable Value"
            />
          </div>
        )}
      </Reform>
    </CardContent>
  );
};

const ClientDetails: React.FC<Props> = ({ insured: client }) => (
  <CardContent className="space-y-3">
    <div>
      <h5 className="text-muted-foreground">Business Name</h5>
      <p>{client.name}</p>
    </div>
    <div>
      <h5 className="text-muted-foreground">Primary State</h5>
      <p>{client.primaryState ?? "-"}</p>
    </div>
    {client.revenue && (
      <div>
        <h5 className="text-muted-foreground">Gross Revenue</h5>
        <p className="text-sm">
          {client.revenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          })}
        </p>
      </div>
    )}
    <div className="3xl:col-span-2">
      <h5 className="text-muted-foreground">Business Description</h5>
      <p className="text-sm">{client.description ?? "-"}</p>
    </div>
    {client.exposures && (
      <div className="3xl:col-span-2">
        <h5 className="text-muted-foreground">Exposures</h5>
        <p className="text-sm">{client.exposures}</p>
      </div>
    )}
    {client.tiv && (
      <div>
        <h5 className="text-muted-foreground">TIV</h5>
        <p className="text-sm">
          {client.tiv.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          })}
        </p>
      </div>
    )}
  </CardContent>
);
