import { useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { toast, useToast } from "@/components/ui/use-toast";
import { File_Audience, useUpdateFileUploadAudienceMutation } from "src/generated/graphql";
import { uploadFiles } from "src/utils/file.utils";
import { RenameFileContent } from "./file-menu";
import TagSelector from "./tag-selector";

interface Props extends React.PropsWithChildren {
  onDrop?: () => void;
  audience: File_Audience;
  source?: string;
  clickable?: boolean;
  editFileDialog?: boolean;
  className?: string;
}

export const FileDropZone = ({
  children,
  onDrop,
  audience,
  source = "MANUAL_UPLOAD",
  clickable = true,
  editFileDialog = false,
  className,
}: Props) => {
  const { insuredId } = useParams<"insuredId">();
  const [fileId, setFileId] = useState(undefined);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Dropzone
      noClick={!clickable}
      onDrop={async (files) => {
        if (editFileDialog && files.length > 1) {
          toast({ title: "Please upload one file at a time" });
          return;
        }

        setIsUploading(true);
        const id = await uploadFiles(files, insuredId ?? "", audience, source);
        setFileId(id);
        setIsUploading(false);
        onDrop && onDrop();
      }}
    >
      {({ getRootProps, isDragActive }) => (
        <div className={className} {...getRootProps()} style={{ position: "relative" }}>
          {children}
          <Dialog onOpenChange={() => setFileId(undefined)} open={fileId ? true : false}>
            {editFileDialog && fileId && <EditFileDialog fileId={fileId} setFileId={setFileId} />}
          </Dialog>
          {isDragActive && <GreyedOutDiv />}
          {isUploading && <GreyedOutSpinner />}
        </div>
      )}
    </Dropzone>
  );
};

export const EditFileDialog = ({ fileId, setFileId }: { fileId: string; setFileId: (v: any) => void }) => {
  const { toast } = useToast();
  const [update] = useUpdateFileUploadAudienceMutation();

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Edit Permissions</DialogTitle>
        <DialogDescription>Select who should have access to this file</DialogDescription>
        <DialogDescription>
          The default is `All Users`. Please select `Internal Users Only` if you do not want the agent to have access to
          this file.
        </DialogDescription>
      </DialogHeader>
      <div>
        <Select
          defaultValue={File_Audience.Internal}
          onValueChange={(value) => {
            void update({
              variables: {
                input: {
                  id: fileId,
                  audience: value as File_Audience,
                },
              },
              onError: (e) => {
                toast({ title: e.message });
              },
              onCompleted: () => {
                toast({ title: `Permission Updated` });
              },
              refetchQueries: ["FindFilesByLabel", "FilesByLabel"],
            });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Audience" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={File_Audience.Internal}>Internal Users Only</SelectItem>
            <SelectItem value={File_Audience.External}>All Users</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <DialogHeader>
        <DialogTitle>Add Tags</DialogTitle>
      </DialogHeader>
      <div>
        <div>
          <TagSelector fileId={fileId} />
        </div>
        <Separator />
        <div className="my-2">
          <DialogHeader>
            <DialogTitle>Edit Filename</DialogTitle>
          </DialogHeader>
          <RenameFileContent fileId={fileId} />
        </div>
        <div>
          <Button className="my-2 w-full" variant="ghost" onClick={() => setFileId(undefined)}>
            Close
          </Button>
        </div>
      </div>
    </DialogContent>
  );
};

// Greys out the contents of the dropzone
const GreyedOutDiv = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  );
};

// Greys out the contents of the dropzone, and displays a spinner
const GreyedOutSpinner = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
};
