import { Link } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { isDefined } from "@cp/toolkit";
import { first } from "lodash";
import { useState } from "react";
import {
  AgentActionType,
  File_Source,
  OpportunityRequirementsQuery,
  SupplementalForm,
  useCreateAgentActionLogMutation,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { formatDate } from "../../../utils/date";
import { Textarea } from "../../components/ui/textarea";
import { ViewPDF } from "../../files/file-menu";

export const AgentAction = ({
  action,
  opportunity,
  clientId,
  onCompleteAgentAction,
  onDeleteAgentAction,
}: {
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunity: NonNullable<OpportunityRequirementsQuery["opportunity"]>;
  clientId: string;
  onCompleteAgentAction: (id: string) => void;
  onDeleteAgentAction: (id: string) => void;
}) => {
  return (
    <AgentActionWrapper action={action} opportunityId={opportunity.id} clientId={clientId}>
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <Icon
            icon={
              action.complete
                ? "check_circle"
                : opportunity.filesProcessing && action.actionType === AgentActionType.UploadFile
                ? "cached"
                : "circle"
            }
            className={cn("", {
              "text-green-600": action.complete,
              "filled opacity-10": !action.complete,
              "animate-spin":
                opportunity.filesProcessing && !action.complete && action.actionType === AgentActionType.UploadFile,
            })}
          />
          <AgentActionTitle action={action} />
        </div>
        <div className="">
          <AgentActionRelatedDocuments action={action} />
          <AgentActionResponse action={action} onCompleteAgentAction={onCompleteAgentAction} />
        </div>
        {!action.complete && (
          <HasInternalRole>
            <div className="flex justify-end">
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onDeleteAgentAction(action.id);
                }}
              >
                <Icon icon="block" className="text-destructive" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onCompleteAgentAction(action.id);
                }}
              >
                <Icon icon="check" className="text-success" />
              </Button>
            </div>
          </HasInternalRole>
        )}
      </div>
    </AgentActionWrapper>
  );
};

const AgentActionTitle = ({
  action,
}: {
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
}) => {
  return <span>{action.title}</span>;
};

const AgentActionWrapper = ({
  action,
  opportunityId,
  clientId,
  children,
}: React.PropsWithChildren<{
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunityId: string;
  clientId: string;
}>) => {
  if (action.supplementalForm) {
    const link = (() => {
      switch (action.supplementalForm) {
        case SupplementalForm.Daycare:
          return "/ategrity-daycare-supplemental.pdf";
        case SupplementalForm.ResidentialLro:
          return "/Please fill out if vacant - GenStar - LRO Supplemental.pdf";
        default:
          return `/supplementals/${clientId}?form=${action.supplementalForm}&opportunityId=${opportunityId}`;
      }
    })();

    return (
      <Link to={link} target="_blank" className="w-full my-2">
        {children}
      </Link>
    );
  }

  return <div className="w-full my-2">{children}</div>;
};

const AgentActionResponse = ({
  action,
  onCompleteAgentAction,
}: {
  onCompleteAgentAction: (id: string) => void;

  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
}) => {
  const [response, setResponse] = useState<string | undefined>(undefined);
  const [addResponse] = useCreateAgentActionLogMutation();

  if (!action.logs) {
    return null;
  }
  if (action.actionType === AgentActionType.UploadFile) {
    return null;
  }

  const latestRepsonse = first(action.logs.filter((l) => l.response))?.response;
  if (!latestRepsonse) {
    return (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <Button
              size="xs"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon icon="add" />
              Add Response
            </Button>
          </DialogTrigger>
          <DialogContent
            className="sm:max-w-[425px]"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DialogHeader>
              <DialogTitle> Add Your Response</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <Textarea
                className="w-full h-32 p-2 border border-gray-300 rounded-md"
                onChange={(e) => setResponse(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button
                onClick={() => {
                  addResponse({
                    variables: {
                      type: action.actionType,
                      agentActionId: action.id,
                      response: response,
                    },
                    onCompleted: () => {
                      onCompleteAgentAction(action.id);
                    },
                  });
                }}
                size="sm"
              >
                Save changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return <div className="flex gap-1 text-sm overflow-hidden whitespace-nowrap text-ellipsis">{latestRepsonse}</div>;
};

interface FileType {
  __typename?: "FileUpload";
  deletedAt?: any;
  createdAt: any;
  labels: string[];
  id: string;
  filename: string;
  mimeType: string;
  fileSource?: File_Source | null;
  uploader?: {
    __typename?: "UserAccount";
    firstName: string;
    lastName: string;
  } | null;
}

const AgentActionRelatedDocuments = ({
  action,
}: {
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
}) => {
  if (!action.logs) {
    return null;
  }

  const files = action.logs.flatMap((l) => l.file).filter((f) => isDefined(f) && f !== undefined) ?? [];

  if (files.length === 0) {
    return null;
  }

  const [selectedFile, setSelectedFile] = useState<FileType | null | undefined>();

  return (
    <div className="flex gap-1">
      {files.map((file) => (
        <>
          <div
            className="flex flex-col justify-center content-center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedFile(file);
            }}
          >
            <Icon icon="description" className="text-muted-foreground self-center text-xl" />
            <div className="text-xs">{formatDate(new Date(file?.createdAt), "LL/dd/yy")}</div>
          </div>
        </>
      ))}
      {selectedFile && (
        <Dialog open={!!selectedFile} onOpenChange={() => setSelectedFile(undefined)}>
          <ViewPDF file={selectedFile} files={files as FileType[]} setSelectedFile={setSelectedFile} />
        </Dialog>
      )}
    </div>
  );
};
