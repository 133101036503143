import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { QuoteState, useQuoteQuery } from "src/generated/graphql";
import { QuoteActions } from "./actions/quote-actions";
import { QuoteFiles } from "./quote-files";
import { QuoteInfo } from "./quote-info";
import { QuoteProposalForm } from "./quote-proposal-form";

export const Quote = () => {
  const { quoteId } = useParams<"quoteId">();

  const { data: { quote } = {} } = useQuoteQuery({
    variables: {
      id: quoteId ?? quoteId!,
    },
    skip: !quoteId,
    fetchPolicy: "cache-and-network",
  });

  if (!quote) {
    return null;
  }

  return (
    <div className="flex gap-3">
      <div className="flex-auto space-y-3">
        <AgentMessage state={quote.state} />
        {quote.state === QuoteState.Pending ? <QuoteProposalForm quote={quote} /> : <QuoteInfo quote={quote} />}
        <Activity opportunityId={quote.submission.opportunityId} />
      </div>
      <div className="flex-none space-y-3 w-80">
        <QuoteFiles quote={quote} />
        <QuoteActions quote={quote} />
      </div>
    </div>
  );
};
