import { InsuredFragment } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText } from "./bankan-cards";

export const InsuredCard = ({ insured }: { insured: InsuredFragment }) => {
  const { id, name, createdAt, agency } = insured;
  return (
    <BankanCard header={agency.name}>
      <BankanLink to={`/insured/${id}`}>
        <BankanLinkText className="font-semibold">{name}</BankanLinkText>
        <BankanLinkFooter>Created {formatTimezoneDateString(createdAt, "MMM d, yyyy")}</BankanLinkFooter>
      </BankanLink>
    </BankanCard>
  );
};
