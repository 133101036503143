import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { HasRole } from "@/components/has-role";
import { ScrollPane } from "@/components/layout";
import { Bar, BarLink } from "@/components/ui/bar";
import { Roles } from "src/generated/graphql";

const settingsPages = [
  { to: "/admin/enablement", label: "Enablement" },
  { to: "/admin/appetite", label: "Appetite" },
  { to: "/admin/appetite-notes", label: "Appetite Notes" },
  { to: "/admin/agency-ownership", label: "Agency Ownership" },
  { to: "/admin/gmail-filters", label: "Gmail Filters" },
  { to: "/admin/tags", label: "Tags" },
];

export function AdminRoot() {
  useDocumentTitle("Admin: QuoteWell");
  return (
    <ScrollPane id="AdminRoot">
      <Bar as="header">
        <nav className="flex gap-2 items-center">
          <HasRole roles={[Roles.Admin]}>
            {settingsPages.map(({ label, to }) => (
              <BarLink key={to} to={to}>
                {label}
              </BarLink>
            ))}
          </HasRole>
          <BarLink to="/admin/verticals">Verticals</BarLink>
        </nav>
      </Bar>
      <Outlet />
    </ScrollPane>
  );
}
