import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { PublicUploadTipCard, SubmissionTipCard } from "@/appetite/appetite-cards";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { QuoteWellLogo } from "@/components/ui/quotewell-logo";
import { useToast } from "@/components/ui/use-toast";
import { usePublicCreateOpportunityMutation } from "src/generated/graphql";
import { FirstSubmissionForm } from "./first-submission-form";

export const FirstSubmissionSchema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  agentEmail: z.string().email(),
  agentPhone: z.string().optional(),
  agencyName: z.string().min(1, { message: "Agency name is required" }),
  agencyPrimaryState: z.string().length(2, { message: "State is required" }),
  clientName: z.string().min(1, { message: "Business name is required" }),
  clientState: z.string().length(2, { message: "State is required" }),
  desiredEffectiveDate: z.string().min(1, { message: "Desired effective date is required" }),
  revenue: z.number().optional(),
  description: z.string().min(1, { message: "Business description is required" }),
});

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export const FirstSubmissionPage: React.FC = () => {
  const { openModal } = useModal();
  const [files, setFiles] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const { toast } = useToast();

  const [createOpportunity] = usePublicCreateOpportunityMutation();

  const formMethods = useForm<z.infer<typeof FirstSubmissionSchema>>({
    resolver: zodResolver(FirstSubmissionSchema),
    defaultValues: {},
  });

  const onDrop = async (acceptedFiles: File[]) => {
    setFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const reset = () => {
    setFiles([]);
  };

  const { getRootProps } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = formMethods.getValues();
      setSubmitting(true);

      let success = true;
      const res = await createOpportunity({
        variables: {
          input: {
            clientInput: {
              name: values.clientName,
              primaryState: values.clientState,
              revenue: values.revenue,
              description: values.description,
            },
            desiredEffectiveDate: values.desiredEffectiveDate,
            agentInput: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.agentEmail,
              phone: values.agentPhone,
              agencyName: values.agencyName,
              agencyState: values.agencyPrimaryState,
            },
          },
        },
        onError: async () => {
          success = false;
          await openModal(() => (
            <>
              <h3>There was a problem!</h3>
              <div className="flex justify-between gap-2">
                Please login or contact a colleague to invite you to the platform. Email us{" "}
                <a
                  href={`mailto:support@quotewell.com`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-700 underline"
                >
                  support@quotewell.com
                </a>{" "}
                if you need further assistance.
              </div>
            </>
          ));
        },
        onCompleted: () => {
          toast({
            title: "Opportunity Created. Now uploading file(s).",
          });
        },
      });

      const opportunityId = res.data?.publicCreateOpportunity.id;
      const createdById = res.data?.publicCreateOpportunity.createdById;

      if (files && opportunityId && createdById) {
        const fd = new FormData();
        files.map((file) => fd.append("files", file));

        const uploadResponse = await fetch(`/api/opportunities/${opportunityId}/${createdById}/files`, {
          method: "POST",
          body: fd,
        });

        if (!uploadResponse.ok) {
          success = false;
          await openModal(() => (
            <>
              <h3>Something went wrong!</h3>
              <div className="flex justify-between gap-2">
                It looks like there was an issue uploading your files. Please check your email for an activation to your
                portal account, where you can view your submission, or email submissions@quotewell.com.
              </div>
            </>
          ));
        }
      }

      setSubmitting(false);

      if (success) {
        await openModal(() => (
          <>
            <h3>Thank you for submitting!</h3>
            <div className="flex justify-between gap-2">Check your email for an activation to your portal account.</div>
          </>
        ));
      }
    }
  };

  return (
    <div className="flex flex-col max-lg:flex-col gap-4 max-w-6xl mx-auto p-4" {...rootProps}>
      <div className="flex justify-between">
        <QuoteWellLogo className="size-14" />
        <Button asChild>
          <a href="/login">Login</a>
        </Button>
      </div>
      <h1>Ready to send us your submission?</h1>
      <div>
        We’re excited to work together! Please answer the following questions about your business and the risk you are
        looking to place today.
      </div>
      <div>
        {"Questions? Email us at "}
        <a href={`mailto:support@quotewell.com`} target="_blank" rel="noreferrer" className="text-blue-700 underline">
          support@quotewell.com
        </a>
      </div>
      <div>
        If you already have an account with us, please{" "}
        <a className="text-blue-700 underline" href="/login">
          login
        </a>
        ! If a colleague at your agency has an account, please contact them for an invitation.
      </div>
      <div className="flex flex-auto gap-4">
        <FirstSubmissionForm formMethods={formMethods} onSubmit={onSubmit} submitting={submitting} />
        <div className="flex-none space-y-4 lg:w-1/3">
          <SubmissionTipCard />
          <PublicUploadTipCard clickToUpload={onClick} files={files} reset={reset} />
        </div>
      </div>
    </div>
  );
};
