import { HasInternalRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import {
  SubmissionFragment,
  SubmissionStateRuledOutActions,
  useTransitionSubmissionMutation,
} from "src/generated/graphql";

export const RuledOut = ({ submission }: { submission: SubmissionFragment }) => {
  const { openConfirmation } = useModal();
  const [transition] = useTransitionSubmissionMutation();

  const handleTransition = async (action: SubmissionStateRuledOutActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm revert ruling out`,
      description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          submissionId: submission.id,
          action,
        },
      },
      refetchQueries: ["OpportunityKitchenSink"],
    });
  };

  return (
    <HasInternalRole>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button onClick={() => handleTransition(SubmissionStateRuledOutActions.Revert)} variant="ghost" size="icon">
            <Icon icon="arrow_back" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Revert</TooltipContent>
      </Tooltip>
    </HasInternalRole>
  );
};
