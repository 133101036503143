import * as Tabs from "@radix-ui/react-tabs";
import { useAtom } from "jotai";

import { bankanTabAtom } from "@/atoms";
import { ScrollPane } from "@/components/layout";
import { Bar } from "@/components/ui/bar";
import { ROLE_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { Roles } from "src/generated/graphql";
import { BankanColumns } from "./bankan-columns";
import { BankanFilter } from "./bankan-filter";

const roles = [Roles.Dj, Roles.Broker, Roles.UnderwritingAssistant];

export const Bankan = () => {
  const [selectedRole, setSelectedRole] = useAtom(bankanTabAtom);

  return (
    <ScrollPane>
      <Bar as="header" position="sticky">
        <Tabs.Root value={selectedRole} onValueChange={(v) => setSelectedRole(v as Roles)}>
          <Tabs.List className="bg-muted flex items-center p rounded-md shadow-inner">
            {roles.map((role) => {
              return (
                <Tabs.Trigger
                  key={role}
                  value={role}
                  className={cn(
                    "flex font-semibold px-4 py-2 rounded text-muted-foreground text-xs",
                    selectedRole === role && "bg-background shadow text-foreground"
                  )}
                >
                  {ROLE_METADATA[role].label}
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
        </Tabs.Root>
        <BankanFilter />
      </Bar>
      <BankanColumns />
    </ScrollPane>
  );
};
