import { useAtom } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router";

import {
  eventsBotAtom,
  eventsCommentAtom,
  eventsDescAtom,
  eventsEmailAtom,
  eventsOpportunityAtom,
  eventsSubmissionAtom,
  eventsUploadAtom,
} from "@/atoms";
import { useMyAccount } from "@/auth/useMyAccount";
import { eventTypes } from "@/components/activity/activity";
import { EventFilter } from "@/components/activity/event-filter";
import { EventRenderer } from "@/components/activity/event-renderer";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/utils";
import {
  OnOpportunityEventAddedDocument,
  OnOpportunityEventAddedSubscription,
  OnOpportunityEventAddedSubscriptionVariables,
  useOpportunityEventsQuery,
} from "src/generated/graphql";

export const History = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: user } = useMyAccount();

  const [comment] = useAtom(eventsCommentAtom);
  const [opportunity] = useAtom(eventsOpportunityAtom);
  const [submission] = useAtom(eventsSubmissionAtom);
  const [email] = useAtom(eventsEmailAtom);
  const [bot] = useAtom(eventsBotAtom);
  const [upload] = useAtom(eventsUploadAtom);
  const [desc] = useAtom(eventsDescAtom);

  const types = [
    ...(opportunity ? eventTypes.opportunity : []),
    ...(submission ? eventTypes.submission : []),
    ...(bot ? eventTypes.bot : []),
    ...(email ? eventTypes.email : []),
    ...(comment ? eventTypes.comment : []),
    ...(upload ? eventTypes.upload : []),
  ];

  const { data: { opportunityEvents = [] } = {}, subscribeToMore } = useOpportunityEventsQuery({
    variables: {
      input: { opportunityId: opportunityId || "", types },
    },
    skip: !opportunityId,
  });

  useEffect(() => {
    return subscribeToMore<OnOpportunityEventAddedSubscription, OnOpportunityEventAddedSubscriptionVariables>({
      document: OnOpportunityEventAddedDocument,
      variables: { id: opportunityId || "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newEvent = subscriptionData.data.opportunityEventAdded;

        return {
          opportunityEvents: [...prev.opportunityEvents, newEvent],
        };
      },
    });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>
          History
          <EventFilter allActivity />
        </CardTitle>
      </CardHeader>
      <section className={cn("bg-border border-t flex gap-px", desc ? "flex-col" : "flex-col-reverse")} id="history">
        {opportunityEvents.map((event) => (
          <EventRenderer key={event.id} event={event} user={user} />
        ))}
      </section>
    </Card>
  );
};
