import { Link } from "react-router-dom";

import { Cell, RowHeader } from "@/components/grid-table";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { MarketingPlanState, useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredRow } from "./insured-row";

export const InsuredsList = ({ id }: { id: string }) => {
  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans: activeMarketingPlans = [] } = {} } = {},
    loading: activeLoading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        agencyId: id,
        states: [
          MarketingPlanState.WaitingForCoverages,
          MarketingPlanState.InformationGathering,
          MarketingPlanState.InformationReview,
          MarketingPlanState.Marketing,
        ],
      },
    },
    skip: !id,
    fetchPolicy: "cache-first",
  });

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans: allMarketingPlans = [] } = {} } = {},
    loading: allLoading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: { agencyId: id },
    },
    skip: !id,
    fetchPolicy: "cache-first",
  });

  return (
    <div className="flex-auto space-y-3">
      {activeLoading && <Loading label="Loading Active Marketing Plans..." className="p-4" />}
      {activeMarketingPlans[0] && (
        <Card>
          <CardHeader className="bg-accent">
            <CardTitle>
              {activeMarketingPlans.length} Active Marketing Plan
              {activeMarketingPlans.length !== 1 && "s"}
            </CardTitle>
          </CardHeader>
          <TableHeader />
          {activeMarketingPlans.map((insured) => (
            <InsuredRow key={insured.insuredId} insured={insured} />
          ))}
        </Card>
      )}
      {allLoading && <Loading label="Loading Insureds..." className="p-4" />}
      <Card>
        <CardHeader className="bg-accent">
          <CardTitle>
            {allMarketingPlans.length} Insured
            {allMarketingPlans.length !== 1 && "s"}
            <Button variant="outline" size="sm" asChild>
              <Link to="/insured/new">Create Insured</Link>
            </Button>
          </CardTitle>
        </CardHeader>
        {allMarketingPlans[0] && <TableHeader />}
        {allMarketingPlans.map((insured) => (
          <InsuredRow key={insured.insuredId} insured={insured} />
        ))}
      </Card>
    </div>
  );
};

const TableHeader = () => (
  <RowHeader className="grid-cols-[1rem_repeat(auto-fit,_minmax(1px,_1fr))]">
    <Cell />
    <Cell>Insured</Cell>
    <Cell>Lines</Cell>
    <Cell>Status / Eff. Date</Cell>
    <Cell>Broker</Cell>
  </RowHeader>
);
