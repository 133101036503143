import { Button } from "@/components/ui/button";
import { MarketingPlanStateInformationReviewActions, useTransitionMarketingPlanMutation } from "src/generated/graphql";
import { RequirementsContextData } from "../requirements";

export const InformationReview = ({ opportunity }: RequirementsContextData) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!opportunity) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanStateInformationReviewActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: opportunity.id,
          action,
        },
      },
    });
  };

  return (
    <Button
      onClick={() => handleTransition(MarketingPlanStateInformationReviewActions.StartMarketing)}
      variant="outline"
      size="sm"
    >
      Start Marketing
    </Button>
  );
};
