import { Row } from "@/components/grid-table";
import { StateIndicator } from "@/metadata";
import { SubmissionFragment } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { SubmissionActions } from "./actions/submission-actions";

export const Submission = ({ submission, refetch }: { submission: SubmissionFragment; refetch: () => void }) => {
  const { appetiteProduct, state, stateTransitions, createdAt } = submission;
  const { carrierName, carrierProductName } = appetiteProduct;
  const lastTransition =
    stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt, details: "" };

  return (
    <Row className="grid-cols-6 min-h-16">
      <div className="col-span-2 space-y-1">
        <div className="flex font-semibold items-center">{carrierName}</div>
        <div className="text-2xs truncate">{carrierProductName}</div>
      </div>
      <div className="space-y-1">
        <div className="font-semibold">
          <StateIndicator state={state} />
        </div>
        <div>{lastTransition.details}</div>
        <div className="text-2xs">{formatTimezoneDateString(lastTransition.createdAt, "MMM d, yyyy")}</div>
      </div>
      <SubmissionActions submission={submission} refetch={refetch} />
    </Row>
  );
};
