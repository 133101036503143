import { createBrowserRouter, Link, Navigate, Outlet, redirect, RouteObject } from "react-router-dom";
import { Roles } from "src/generated/graphql";
import { AdminRoot } from "./admin/admin-root";
import { AgencyOwnership } from "./admin/agency-ownership/agency-ownership";
import { AppetiteNote } from "./admin/appetite-notes/appetite-note";
import { AppetiteNotes } from "./admin/appetite-notes/appetite-notes";
import { Appetite } from "./admin/appetite/appetite";
import { Enablement } from "./admin/enablement/enablement";
import { GmailFilters } from "./admin/gmail-filters/gmail-filters";
import { Tags } from "./admin/tags";
import { EditVertical } from "./admin/verticals/edit-vertical";
import { Vertical } from "./admin/verticals/vertical";
import { Verticals } from "./admin/verticals/verticals";
import { CreateInsuredPage } from "./appetite/create-insured-page";
import { ActivateAccount, activateAccountLoader } from "./auth/ActivateAccount";
import { AuthGuard } from "./auth/AuthGuard";
import { ForgotPassword } from "./auth/ForgotPassword";
import { Login } from "./auth/Login";
import { getAuthedUserAndAgency } from "./auth/user";
import { Bankan } from "./bankan/bankan";
import { HasInternalRole } from "./components/has-role";
import { Centered, Page } from "./components/layout";
import { useTheme } from "./components/theme-provider";
import { ErrorPage } from "./error";
import { Files } from "./files/files";
import { InsuredDetails } from "./insured/details/details";
import { InsuredEmail } from "./insured/insured-email";
import { InsuredFiles } from "./insured/insured-files";
import { InsuredRedirect } from "./insured/insured-redirect";
import { insuredLoader, InsuredRoot } from "./insured/insured-root";
import { Coverage } from "./insured/marketing-plans/coverage";
import { Data } from "./insured/marketing-plans/data";
import { EscapeHatch } from "./insured/marketing-plans/escape-hatch";
import { History } from "./insured/marketing-plans/history";
import { MarketingPlan } from "./insured/marketing-plans/marketing-plan";
import { MarketingPlanDetails } from "./insured/marketing-plans/marketing-plan-details";
import { MarketingPlans } from "./insured/marketing-plans/marketing-plans";
import { MarketingPlansIndex } from "./insured/marketing-plans/marketing-plans-index";
import { Notes } from "./insured/marketing-plans/notes";
import { SelectMarkets } from "./insured/marketing-plans/select-markets/select-markets";
import { Policies, PoliciesIndex } from "./insured/policies/policies";
import { Policy } from "./insured/policies/policy";
import { Quote } from "./insured/quotes/quote";
import { QuoteBind } from "./insured/quotes/quote-bind";
import { Quotes, QuotesIndex } from "./insured/quotes/quotes";
import { RequirementForm } from "./insured/requirements/requirement-form";
import { InsuredsAgency } from "./insureds/insureds-agency";
import { InsuredsBroker } from "./insureds/insureds-broker";
import { InsuredsRoot } from "./insureds/insureds-root";
import { PageNotFound } from "./page-not-found";
import { FirstSubmissionPage } from "./public/first-submission-page";
import { Supplementals } from "./supplementals/supplementals";

const Beef = () => {
  const { setTheme } = useTheme();
  setTheme("beef");
  return <Navigate to="/" />;
};

export const roleHomepageLoader = async () => {
  const { user } = (await getAuthedUserAndAgency()) || {};
  let path = "/login";

  if (user) {
    if (user && user.roles.includes(Roles.Broker)) {
      path = `/bankan/${user.id}`;
    } else if (user.roles.includes(Roles.Admin)) {
      path = "/bankan";
    } else {
      path = "/insureds";
    }
  }

  return redirect(path);
};

const internalRoutes: RouteObject[] = [
  {
    element: (
      <HasInternalRole elseShow={<PageNotFound />}>
        <Outlet />
      </HasInternalRole>
    ),
    children: [
      {
        path: "admin",
        element: <AdminRoot />,
        children: [
          {
            path: "appetite",
            element: <Appetite />,
          },
          {
            path: "appetite-notes",
            children: [
              {
                index: true,
                element: <AppetiteNotes />,
              },
              {
                path: "create",
                element: <AppetiteNote mode="create" />,
              },
              {
                path: ":appetiteNoteId/edit",
                element: <AppetiteNote mode="edit" />,
              },
            ],
          },
          {
            path: "agency-ownership",
            element: <AgencyOwnership />,
          },
          {
            path: "enablement",
            element: <Enablement />,
          },
          {
            path: "gmail-filters",
            element: <GmailFilters />,
          },
          {
            path: "verticals",
            children: [
              {
                index: true,
                element: <Verticals />,
              },
              {
                path: "create",
                element: <EditVertical />,
              },
              {
                path: ":verticalId",
                element: <Vertical />,
              },
              {
                path: ":verticalId/edit",
                element: <EditVertical />,
              },
            ],
          },
          {
            path: "tags",
            element: <Tags />,
          },
        ],
      },
      {
        path: "bankan/:brokerId?",
        element: <Bankan />,
      },
    ],
  },
];

const authProtectedRoutes: RouteObject[] = [
  { path: "agency", loader: () => redirect("/insureds") },
  {
    path: "insureds",
    element: <InsuredsRoot />,
    children: [
      {
        index: true,
        element: (
          <HasInternalRole elseShow={<InsuredsAgency />}>
            <InsuredsBroker />
          </HasInternalRole>
        ),
      },
      {
        path: ":agencyId",
        element: <InsuredsAgency />,
      },
    ],
  },
  {
    path: "insured/new",
    element: <CreateInsuredPage />,
  },
  {
    path: "insured/:insuredId",
    element: <InsuredRoot />,
    loader: insuredLoader,
    handle: {
      crumb: ({ insured }: Awaited<ReturnType<typeof insuredLoader>>) => (
        <Link to={`/insured/${insured?.id}`}>{insured?.name}</Link>
      ),
    },
    children: [
      {
        index: true,
        element: <InsuredDetails />,
      },
      {
        path: ":opportunityId/*",
        loader: ({ params }) => redirect(`/insured/${params.insuredId}/plans/${params.opportunityId}/${params["*"]}`),
      },
      {
        path: "email",
        element: <InsuredEmail />,
        handle: {
          crumb: () => <span>Email Threads</span>,
        },
      },
      {
        path: "files",
        element: <InsuredFiles />,
        handle: {
          crumb: () => <span>Files</span>,
        },
      },
      {
        path: "plans",
        element: <MarketingPlans />,
        handle: {
          crumb: () => <span>Marketing Plans</span>,
        },
        children: [{ index: true, element: <MarketingPlansIndex /> }],
      },
      {
        path: "plans/:opportunityId",
        element: <MarketingPlan />,
        handle: {
          // TODO: marketing plan crumb
        },
        children: [
          {
            index: true,
            element: <MarketingPlanDetails />,
          },
          {
            path: "coverage",
            element: <Coverage />,
          },
          {
            path: "data",
            element: <Data />,
          },
          {
            path: "escape-hatch",
            element: <EscapeHatch />,
          },
          {
            path: "files",
            element: <Files />,
          },
          {
            path: "history",
            element: <History />,
          },
          {
            path: "notes",
            element: <Notes />,
          },
          {
            path: "markets",
            element: <SelectMarkets />,
          },
          {
            path: "requirements/:requirementId",
            element: <RequirementForm />,
          },
        ],
      },
      {
        path: "quotes",
        element: <Quotes />,
        handle: {
          crumb: () => <span>Quotes</span>,
        },
        children: [
          {
            index: true,
            element: <QuotesIndex />,
          },
          {
            path: ":quoteId",
            element: <Quote />,
            handle: {
              // TODO: quote crumb
            },
          },
        ],
      },
      {
        path: "quotes/:quoteId/bind",
        element: <QuoteBind />,
      },
      {
        path: "policies",
        element: <Policies />,
        handle: {
          crumb: () => <span>Policies</span>,
        },
        children: [
          {
            index: true,
            element: <PoliciesIndex />,
          },
          {
            path: ":policyId",
            element: <Policy />,
            handle: {
              // TODO: policy crumb
            },
          },
        ],
      },
    ],
  },
  {
    path: "opportunity/:opportunityId",
    element: <InsuredRedirect />,
  },
  {
    // Note: without a clientId, the form won't save
    path: "supplementals/:clientId?",
    element: <Supplementals />,
  },
  { index: true, loader: roleHomepageLoader, element: <div /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Page>
        <Outlet />
      </Page>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [...authProtectedRoutes, ...internalRoutes],
      },
    ],
  },
  {
    path: "first-submission",
    element: <FirstSubmissionPage />,
  },
  {
    element: <Centered />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
  {
    path: "appetite/*",
    loader: () => redirect("/new"),
  },
  {
    path: "broker/*",
    loader: () => redirect("/new"),
  },
  {
    path: "beef",
    element: <Beef />,
  },
  {
    path: "*",
    element: (
      <Page>
        <PageNotFound />
      </Page>
    ),
  },
]);
