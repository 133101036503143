import { Dates, US_STATES, UUID } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import { KnownClientData } from "@qw/qw-common";
import { add } from "date-fns";
import { intersection, sortBy, startCase, uniq } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { Control, Controller, FieldError, FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { Autocomplete } from "@/components/ui/autocomplete";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { FormField, FormItem } from "@/components/ui/form";
import { Icon, IconString } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { Field, FieldInput, fieldInputClassName } from "@/forms/borderless";
import { Currency } from "@/forms/borderless-currency";
import { parseError } from "@/utils";
import {
  BindHqApplicationType,
  BindHqInsuredType,
  BindHqLineOfBusiness,
  BindHqQuoteBillingType,
  BindHqQuoteBusinessType,
  BindHqQuoteLineItemType,
  BindHqQuoteTaxesCollectedBy,
  MarketTypes,
  useBindHqAgenciesQuery,
  useBindHqAgentsQuery,
  useBindHqCarriersQuery,
  useBindHqInsuredsQuery,
  useBindHqMarketingCompaniesQuery,
  useBindQuoteMutation,
  useClientDataQuery,
  useCreateClientDataMutation,
  useInsuredQuery,
  useQuoteKitchenSinkQuery,
  useUpdateAgencyMutation,
  useUpdateInsuredMutation,
} from "src/generated/graphql";
import { QuoteInfo } from "./quote-info";

const formId = "bindHqPolicyForm";
const bindHqPercentageRegex =
  "^(?<sign>[-+])?(?:(?:(?<integral>[0-9]+)?(?<point>.)?(?<fractional>[0-9]+)?(?:[eE](?<exponent>[-+]?[0-9]+))?)|(?:(?<numerator>[0-9]+)/?(?<denominator>[0-9]+)))$";
const BindHqPolicyRequirementsSchema = z
  .object({
    agency: z.object({
      agencyId: z.string().min(1),
      bindHqId: z.string().min(1, { message: "Required" }),
    }),
    insured: z.object({
      id: z.string().min(1),
      bindHqId: z.string().optional(),
      name: z.string().min(1),
      dba: z.string().optional(),
      legalEntityType: z.enum(Object.values(BindHqInsuredType) as [BindHqInsuredType]).optional(),
    }),
    clientData: z.object({
      [KnownClientData.MailingAddress.AddressLine1]: z.string().optional(),
      [KnownClientData.MailingAddress.AddressLine2]: z.string().optional(),
      [KnownClientData.MailingAddress.City]: z.string().optional(),
      [KnownClientData.MailingAddress.State]: z.string().optional(),
      [KnownClientData.MailingAddress.Zip]: z.string().optional(),
    }),
    bindHqInput: z.object({
      createApplication: z.object({
        agentBindHqId: z.string().min(1),
        linesOfBusiness: z.array(z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness])).min(1),
        type: z.enum(Object.values(BindHqApplicationType) as [BindHqApplicationType]),
      }),
      createQuote: z.object({
        marketingCompanyId: z.string().min(1),
        carrierId: z.string().min(1),
        businessType: z.enum(Object.values(BindHqQuoteBusinessType) as [BindHqQuoteBusinessType]),
        billingType: z.enum(Object.values(BindHqQuoteBillingType) as [BindHqQuoteBillingType]),
        taxesCollectedBy: z.enum(Object.values(BindHqQuoteTaxesCollectedBy) as [BindHqQuoteTaxesCollectedBy]),
        taxationHomeState: z.string().min(2).max(2),
        minimumEarnedPremiumPercentage: z
          .string()
          .min(1, { message: "Required" })
          .regex(new RegExp(bindHqPercentageRegex)),
        grossCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        agentCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        lineItems: z.array(
          z.object({
            type: z.enum(Object.values(BindHqQuoteLineItemType) as [BindHqQuoteLineItemType]),
            amount: z.union([z.string().min(1), z.number()]).transform((v) => v.toString()),
            lineOfBusiness: z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness]),
            description: z.string().optional(),
          })
        ),
      }),
      createPolicy: z.object({
        policyNumber: z.string().min(1, { message: "Required" }),
        invoiceDueDate: z.string({ required_error: "Required" }).min(1),
        effectiveDate: z.string({ required_error: "Required" }).min(1),
        expirationDate: z.string({ required_error: "Required" }).min(1),
      }),
    }),
  })
  .superRefine((values, context) => {
    if (!values.insured.bindHqId && !values.insured.legalEntityType) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["insured", "legalEntityType"],
      });
    }
  });

export function QuoteBind() {
  const { insuredId } = useParams<"insuredId">();
  const { quoteId } = useParams<"quoteId">();

  const { data: { insured } = {}, loading: insuredLoading } = useInsuredQuery({
    variables: { id: insuredId ?? "" },
    skip: !insuredId,
  });

  const { data: { quote } = {}, loading: quoteLoading } = useQuoteKitchenSinkQuery({
    variables: { id: quoteId ?? "" },
    skip: !quoteId,
  });

  const { data: { clientData } = {} } = useClientDataQuery({
    variables: { input: { insuredId: insuredId as UUID } },
    skip: !insuredId,
  });

  const { data: { bindHqAgencies = [] } = {}, loading: bindHqAgenciesLoading } = useBindHqAgenciesQuery();
  // Store selected BindHQ agency ID in state so we can use it to fetch BindHQ agents for that agency only
  const [selectedBindHqAgencyId, setSelectedBindHqAgencyId] = useState<string | undefined>(
    insured?.agency?.bindHqId ?? undefined
  );

  const { data: { bindHqAgents = [] } = {}, loading: bindHqAgentsLoading } = useBindHqAgentsQuery({
    variables: {
      input: { bindHqAgencyId: selectedBindHqAgencyId },
    },
  });

  const { data: { bindHqInsureds = [] } = {}, loading: bindHqInsuredsLoading } = useBindHqInsuredsQuery({
    variables: {},
  });
  const { data: { bindHqMarketingCompanies = [] } = {}, loading: bindHqMarketingCompaniesLoading } =
    useBindHqMarketingCompaniesQuery();
  const { data: { bindHqCarriers = [] } = {}, loading: bindHqCarriersLoading } = useBindHqCarriersQuery();

  const submitUpdates = useBindQuote(insuredId, quoteId);
  const [submitting, setSubmitting] = useState(false);
  const formMethods = useForm<z.infer<typeof BindHqPolicyRequirementsSchema>>({
    resolver: zodResolver(BindHqPolicyRequirementsSchema),
  });

  const submission = quote?.submission;
  const opportunity = submission?.opportunity;
  const appetiteProduct = submission?.appetiteProduct;
  const { agent, agency } = insured ?? {};
  // Find the lines of business that are both in the submission and the appetite product
  const bindHqLinesOnSubmission = intersection(
    submission?.appetiteProduct?.coreLines,
    opportunity?.selectedLinesOfBusiness
  )
    .map((lob) => QwLobToBindHqLob[lob]) // Convert the QW lines of business to BindHQ lines of business
    .filter((v): v is BindHqLineOfBusiness => v !== undefined); // Remove undefined values
  const selectedBindHqLines = formMethods.watch("bindHqInput.createApplication.linesOfBusiness") ?? [];
  const bindHqInsuredOptions = [{ id: undefined, name: "Create New Insured" }, ...bindHqInsureds];
  const createNewInsuredSelected = formMethods.watch("insured.bindHqId") === undefined;

  useEffect(() => {
    if (opportunity && insured && submission && quote && clientData && !submitting) {
      // Triggers refetch of BindHQ agents for this agency
      agency?.bindHqId && setSelectedBindHqAgencyId(agency?.bindHqId);

      formMethods.reset({
        agency: {
          agencyId: agency?.id,
          bindHqId: agency?.bindHqId ?? undefined,
        },
        insured: {
          id: insured?.id,
          bindHqId: insured?.bindHqId ?? undefined,
          name: insured?.name,
          dba: insured?.dba ?? undefined,
          legalEntityType:
            insured?.legalEntityType && Object.keys(BindHqLegalEntityMap).includes(insured.legalEntityType)
              ? BindHqLegalEntityMap[insured.legalEntityType]
              : undefined,
        },
        clientData: {
          [KnownClientData.MailingAddress.AddressLine1]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine1
          )?.value,
          [KnownClientData.MailingAddress.AddressLine2]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine2
          )?.value,
          [KnownClientData.MailingAddress.City]: clientData.find((d) => d.key === KnownClientData.MailingAddress.City)
            ?.value,
          [KnownClientData.MailingAddress.State]: clientData.find((d) => d.key === KnownClientData.MailingAddress.State)
            ?.value,
          [KnownClientData.MailingAddress.Zip]: clientData.find((d) => d.key === KnownClientData.MailingAddress.Zip)
            ?.value,
        },
        bindHqInput: {
          createApplication: {
            agentBindHqId: agent?.bindHqId ?? undefined,
            linesOfBusiness: bindHqLinesOnSubmission,
            type: opportunity.renewalOf ? BindHqApplicationType.Renewal : BindHqApplicationType.New,
          },
          createQuote: {
            marketingCompanyId: appetiteProduct?.bindHqMarketingCompanyId ?? undefined,
            carrierId: appetiteProduct?.bindHqCarrierIds.length === 1 ? appetiteProduct.bindHqCarrierIds[0] : undefined,
            businessType:
              submission.appetiteProduct.marketType === MarketTypes.BindingAuthority
                ? BindHqQuoteBusinessType.Binding
                : BindHqQuoteBusinessType.Brokerage,
            taxationHomeState: insured?.primaryState ?? undefined,
            minimumEarnedPremiumPercentage: quote.mep?.toString(),
            agentCommissionPercentage: quote.commission?.toString(),
            lineItems: [
              {
                type: BindHqQuoteLineItemType.Premium,
                amount: quote?.premium?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
              },
              {
                type: BindHqQuoteLineItemType.MgaFee,
                amount: quote.brokerFee?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                description:
                  appetiteProduct?.marketType === MarketTypes.BindingAuthority ? "Policy Fee" : "Wholesale Broker Fee",
              },
              ...(quote.carrierFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.CarrierFee,
                      amount: quote.carrierFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
              ...(quote.inspectionFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.InspectionFee,
                      amount: quote.inspectionFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
            ],
          },
          createPolicy: {
            effectiveDate: quote.effectiveDate,
            expirationDate:
              quote.expirationDate ?? quote.effectiveDate
                ? add(new Date(quote.effectiveDate), { years: 1 }).toISOString()
                : undefined,
            // try to use quote effective date, if not available, use opportunity desired effective date
            invoiceDueDate: quote.effectiveDate
              ? Dates.incrementDate({ date: quote.effectiveDate, addDays: 10 }).toISOString()
              : opportunity.desiredEffectiveDate
              ? Dates.incrementDate({ date: opportunity.desiredEffectiveDate, addDays: 10 }).toISOString()
              : undefined,
          },
        },
      });
    }
  }, [opportunity, insured, clientData]);

  // Trigger validation on each field as it changes to update field error messages in real time
  useEffect(() => {
    const subscription = formMethods.watch((_value, { name }) => {
      if (name) {
        void formMethods.trigger([name]);
      }
    });

    return () => subscription.unsubscribe(); // Cleanup the subscription on unmount.
  }, [formMethods.watch, formMethods.trigger]);

  if (quoteLoading || insuredLoading) {
    return (
      <span className="flex flex-row items-center gap-2">
        Loading <Spinner />
      </span>
    );
  }
  if (!opportunity || !submission || !quote || !agency || !agent || !insured) {
    return <>Something's not right! Please notify EPD.</>;
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      // Schema parse the values to trigger zod transforms
      const values = BindHqPolicyRequirementsSchema.safeParse(formMethods.getValues());
      if (values.success) {
        setSubmitting(true);
        void submitUpdates(values.data).then(() => setSubmitting(false));
      } else {
        console.log(values.error);
      }
    } else {
      console.log(formMethods.formState.errors);
    }
  };

  return (
    <div className="p-4">
      {/* <DevTool control={formMethods.control} /> */}
      <QuoteInfo quote={quote} />
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-row gap-1">
            <span className="w-full">
              <Label>BindHQ Agency*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.agency?.bindHqId} />
              <Field icon="person_search">
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqAgenciesLoading, "Select an Agency from BindHQ (Required)")}
                  options={bindHqAgencies}
                  selected={bindHqAgencies.find((a) => a.id === formMethods.watch("agency.bindHqId"))}
                  onSelect={async (bindHqAgency) => {
                    formMethods.setValue("agency.bindHqId", bindHqAgency.id);
                    setSelectedBindHqAgencyId(bindHqAgency.id);
                  }}
                  toValue={(a) => `${a.name} ${a.id}`}
                  toLabel={(a) => a.name}
                  buttonProps={{ variant: "plain", display: "flex" }}
                />
              </Field>
            </span>
            <span className="w-full">
              <Label>BindHQ Agent*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createApplication?.agentBindHqId} />
              <Field icon="person_search">
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqAgentsLoading, "Select an Agent from BindHQ (Required)")}
                  options={bindHqAgents}
                  selected={bindHqAgents.find(
                    (a) => a.id === formMethods.watch("bindHqInput.createApplication.agentBindHqId")
                  )}
                  onSelect={(a) => {
                    formMethods.setValue("bindHqInput.createApplication.agentBindHqId", a.id);
                  }}
                  toValue={(a) => `${a.firstName} ${a.lastName} ${a.id}`}
                  toLabel={(a) => `${a.firstName} ${a.lastName}`}
                  buttonProps={{ variant: "plain", display: "flex" }}
                />
              </Field>
            </span>
          </div>
          <Label>BindHQ Insured</Label>
          <FieldErrorMessage error={formMethods.formState.errors.insured?.bindHqId} />
          <Field icon="person_search">
            <Autocomplete
              placeholder={loadingPlaceholder(bindHqInsuredsLoading, "Select an Option")}
              options={bindHqInsuredOptions}
              selected={bindHqInsuredOptions.find((a) => a.id === formMethods.watch("insured.bindHqId"))}
              onSelect={(a) => {
                formMethods.setValue("insured.bindHqId", a.id);
              }}
              // ${a.id} added in case insured names are not unique; insureds with idential names would both show as selected
              toValue={(a) => `${a.name} ${a.id}`}
              toLabel={(a) => a.name}
              buttonProps={{ variant: "plain", display: "flex" }}
            />
          </Field>
          {createNewInsuredSelected && (
            <>
              <div className="flex flex-row gap-1">
                <span className="w-full">
                  <Label>Business Name*</Label>
                  <FieldInput
                    icon="business_center"
                    placeholder="Business Name (Required)"
                    required={true}
                    {...formMethods.register("insured.name")}
                  />
                </span>
                <span className="w-full">
                  <Label>DBA</Label>
                  <FieldInput icon="business_center" placeholder="DBA" {...formMethods.register("insured.dba")} />
                </span>
                <span className="w-full">
                  <Label>Legal Entity Type*</Label>
                  <FieldErrorMessage error={formMethods.formState.errors.insured?.legalEntityType} />
                  <EnumSelect
                    control={formMethods.control}
                    fieldName="insured.legalEntityType"
                    enumType={BindHqInsuredType}
                  />
                </span>
              </div>
              <Label>Client Exposure Address*</Label>
              <FieldInput
                icon="home"
                placeholder="Street Line 1*"
                required={true}
                {...formMethods.register(`clientData.${KnownClientData.MailingAddress.AddressLine1}`)}
              />
              <FieldInput
                icon="home"
                placeholder="Street Line 2"
                {...formMethods.register(`clientData.${KnownClientData.MailingAddress.AddressLine2}`)}
              />
              <FieldInput
                icon="location_city"
                placeholder="City*"
                required={true}
                {...formMethods.register(`clientData.${KnownClientData.MailingAddress.City}`)}
              />
              <UsStateSelect
                control={formMethods.control}
                fieldName={`clientData.${KnownClientData.MailingAddress.State}`}
                required={true}
              />
              <FieldInput
                icon="label"
                placeholder="Zip Code*"
                required={true}
                {...formMethods.register(`clientData.${KnownClientData.MailingAddress.Zip}`)}
              />
            </>
          )}
          <div className="flex flex-row gap-1">
            <span className="w-full">
              <Label>Effective Date*</Label>
              <DatePickerWithErrorMessage
                dateFormat="MMMM d, yyyy"
                formMethods={formMethods}
                fieldName="bindHqInput.createPolicy.effectiveDate"
                onSelect={(e) => {
                  if (e) {
                    // Set the expiration date to one year after the effective date
                    const oneYearLater = Dates.incrementDate({ date: e, addYears: 1 }).toISOString();
                    !formMethods.getValues("bindHqInput.createPolicy.expirationDate") &&
                      formMethods.setValue("bindHqInput.createPolicy.expirationDate", oneYearLater);
                    // Set policy invoice due date based on effective date
                    const tenDaysLater = Dates.incrementDate({ date: e, addDays: 10 }).toISOString();
                    const twentyDaysLater = Dates.incrementDate({ date: e, addDays: 20 }).toISOString();
                    const invoiceDueDate =
                      appetiteProduct?.marketType === MarketTypes.BindingAuthority ? twentyDaysLater : tenDaysLater;
                    !formMethods.getValues("bindHqInput.createPolicy.invoiceDueDate") &&
                      formMethods.setValue("bindHqInput.createPolicy.invoiceDueDate", invoiceDueDate);
                  }
                }}
              />
            </span>
            <span className="w-full">
              <Label>Expiration Date*</Label>
              <DatePickerWithErrorMessage
                dateFormat="MMMM d, yyyy"
                formMethods={formMethods}
                fieldName="bindHqInput.createPolicy.expirationDate"
              />
            </span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="w-full">
              <Label>Application Type*</Label>
              <FieldErrorMessage
                error={formMethods.formState.errors.bindHqInput?.createApplication?.type as FieldError}
              />
              <EnumSelect
                control={formMethods.control}
                fieldName="bindHqInput.createApplication.type"
                enumType={BindHqApplicationType}
              />
            </span>
            <span className="w-full">
              <Label>BindHQ Lines of Business*</Label>
              <Field icon="add">
                <Autocomplete
                  placeholder={
                    selectedBindHqLines?.length > 0 ? (
                      <LinesOfBusiness
                        lines={formMethods.watch("bindHqInput.createApplication.linesOfBusiness")}
                        onDelete={(line) =>
                          formMethods.setValue(
                            "bindHqInput.createApplication.linesOfBusiness",
                            selectedBindHqLines.filter((l) => l !== line)
                          )
                        }
                      />
                    ) : (
                      "Select Lines"
                    )
                  }
                  // Sort the submission's lines to the top of the options list (and Pkg because it's so common)
                  options={sortBy(Object.values(BindHqLineOfBusiness), (l) =>
                    bindHqLinesOnSubmission.includes(l) || l === BindHqLineOfBusiness.Pkg ? -1 : 0
                  )}
                  onSelect={(l) =>
                    formMethods.setValue(
                      "bindHqInput.createApplication.linesOfBusiness",
                      uniq([...selectedBindHqLines, l])
                    )
                  }
                  toValue={(l) => BindHqLineOfBusinessDisplayName[l]}
                  toLabel={(l) => BindHqLineOfBusinessDisplayName[l]}
                  buttonProps={{ variant: "plain", display: "flex" }}
                />
              </Field>
            </span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="w-full">
              <Label>BindHQ Marketing Company*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.marketingCompanyId} />
              <Field icon="content_paste_search">
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqMarketingCompaniesLoading, "Search BindHQ Marketing Companies")}
                  options={bindHqMarketingCompanies}
                  selected={bindHqMarketingCompanies.find(
                    (m) => m.id === formMethods.watch("bindHqInput.createQuote.marketingCompanyId")
                  )}
                  onSelect={(m) => formMethods.setValue("bindHqInput.createQuote.marketingCompanyId", m.id)}
                  toValue={(m) => m.name}
                  toLabel={(m) => m.name}
                  buttonProps={{ variant: "plain", display: "flex" }}
                />
              </Field>
            </span>
            <span className="w-full">
              <Label>BindHQ Carrier*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.carrierId} />
              <Field icon="content_paste_search">
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqCarriersLoading, "Search BindHQ Carriers")}
                  // Sort the carriers for this appetite product to the top of the options list
                  options={sortBy(bindHqCarriers, (c) => (appetiteProduct?.bindHqCarrierIds.includes(c.id) ? -1 : 0))}
                  selected={bindHqCarriers.find((c) => c.id === formMethods.watch("bindHqInput.createQuote.carrierId"))}
                  onSelect={(c) => formMethods.setValue("bindHqInput.createQuote.carrierId", c.id)}
                  toValue={(c) => c.name}
                  toLabel={(c) => c.name}
                  buttonProps={{ variant: "plain", display: "flex" }}
                />
              </Field>
            </span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="w-full">
              <Label>Business Type*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.businessType} />
              <EnumSelect
                control={formMethods.control}
                fieldName="bindHqInput.createQuote.businessType"
                enumType={BindHqQuoteBusinessType}
              />
            </span>
            <span className="w-full">
              <Label>Billing Type*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.billingType} />
              <EnumSelect
                control={formMethods.control}
                fieldName="bindHqInput.createQuote.billingType"
                enumType={BindHqQuoteBillingType}
                onChange={(value) => {
                  if (value === BindHqQuoteBillingType.AgencyBill) {
                    formMethods.setValue("bindHqInput.createQuote.taxesCollectedBy", BindHqQuoteTaxesCollectedBy.Mga);
                  }
                  if (value === BindHqQuoteBillingType.DirectBill) {
                    formMethods.setValue(
                      "bindHqInput.createQuote.taxesCollectedBy",
                      BindHqQuoteTaxesCollectedBy.MarketingCompany
                    );
                  }
                }}
              />
            </span>
            <span className="w-full">
              <Label>Taxes Collected By*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.taxesCollectedBy} />
              <EnumSelect
                control={formMethods.control}
                fieldName="bindHqInput.createQuote.taxesCollectedBy"
                enumType={BindHqQuoteTaxesCollectedBy}
              />
            </span>
            <span className="w-full">
              <Label>Taxation Home State*</Label>
              <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createQuote?.taxationHomeState} />
              <UsStateSelect control={formMethods.control} fieldName="bindHqInput.createQuote.taxationHomeState" />
            </span>
          </div>
          <div className="flex flex-row">
            <span className="w-full mr-1">
              <Label>Minimum Earned Premium %*</Label>
              <FieldErrorMessage
                error={formMethods.formState.errors.bindHqInput?.createQuote?.minimumEarnedPremiumPercentage}
              />
              <FieldInput
                icon="percent"
                placeholder="Enter a Percentage"
                {...formMethods.register("bindHqInput.createQuote.minimumEarnedPremiumPercentage")}
              />
            </span>
            <span className="w-full mr-1">
              <Label>Gross Commission %*</Label>
              <FieldErrorMessage
                error={formMethods.formState.errors.bindHqInput?.createQuote?.grossCommissionPercentage}
              />
              <FieldInput
                icon="percent"
                placeholder="Enter a Percentage"
                {...formMethods.register("bindHqInput.createQuote.grossCommissionPercentage")}
              />
            </span>
            <span className="w-full">
              <Label>Agent Commission %*</Label>
              <FieldErrorMessage
                error={formMethods.formState.errors.bindHqInput?.createQuote?.agentCommissionPercentage}
              />
              <FieldInput
                icon="percent"
                placeholder="Enter a Percentage"
                {...formMethods.register("bindHqInput.createQuote.agentCommissionPercentage")}
              />
            </span>
          </div>
          <Label>Quote Line Items / Fees</Label>
          <div className="flex flex-row">
            <Label className="w-1/4">Type</Label>
            <Label className="w-1/4">Amount</Label>
            <Label className="w-1/2">BindHQ Line of Business</Label>
            <Label className="w-1/2">Description</Label>
          </div>
          {(formMethods.watch("bindHqInput.createQuote.lineItems") ?? []).map((_lineItem, index) => (
            <div className="flex flex-row items-center mt-1 gap-1" key={index}>
              <span className="w-1/4">
                <EnumSelect
                  control={formMethods.control}
                  fieldName={`bindHqInput.createQuote.lineItems.${index}.type`}
                  enumType={BindHqQuoteLineItemType}
                  onChange={(value) => {
                    if (value === BindHqQuoteLineItemType.MgaFee) {
                      formMethods.setValue(
                        `bindHqInput.createQuote.lineItems.${index}.description`,
                        "Wholesale Broker Fee"
                      );
                    }
                  }}
                />
              </span>
              <span className="w-1/4">
                <Currency
                  icon="attach_money"
                  control={formMethods.control}
                  name={`bindHqInput.createQuote.lineItems.${index}.amount`}
                  required={true}
                  placeholder="Amount"
                  allowDecimal={true}
                />
              </span>
              <span className="w-1/2">
                <Field icon="list">
                  <FormField
                    control={formMethods.control}
                    name={`bindHqInput.createQuote.lineItems.${index}.lineOfBusiness`}
                    render={({ field }) => (
                      <FormItem className="flex-auto">
                        <Select
                          key={field.value}
                          onValueChange={(value) => field.onChange(value as unknown as BindHqLineOfBusiness)}
                          value={field.value}
                        >
                          <SelectTrigger className={fieldInputClassName}>
                            <SelectValue
                              placeholder={
                                <>
                                  Select a Line
                                  <FieldErrorMessage
                                    error={
                                      formMethods.formState.errors.bindHqInput?.createQuote?.lineItems?.[index]
                                        ?.lineOfBusiness
                                    }
                                  />
                                </>
                              }
                            />
                          </SelectTrigger>
                          <SelectContent>
                            {selectedBindHqLines?.map((line) => (
                              <SelectItem key={line} value={line}>
                                {BindHqLineOfBusinessDisplayName[line]}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />
                </Field>
              </span>
              <span className="w-1/2">
                <FieldInput
                  icon="text_fields"
                  placeholder="Description"
                  {...formMethods.register(`bindHqInput.createQuote.lineItems.${index}.description`)}
                />
              </span>
              <span className="">
                <Button
                  variant="ghost"
                  onClick={() => {
                    formMethods.setValue(
                      "bindHqInput.createQuote.lineItems",
                      formMethods.getValues("bindHqInput.createQuote.lineItems").filter((_, i) => i !== index)
                    );
                  }}
                >
                  <Icon icon="delete" />
                </Button>
              </span>
            </div>
          ))}
          <div className="flex flex-row items-center text-xs text-gray-500 mt-1">
            <Button
              variant="ghost"
              className="min-w-fit"
              onClick={() => {
                formMethods.setValue("bindHqInput.createQuote.lineItems", [
                  ...(formMethods.getValues("bindHqInput.createQuote.lineItems") ?? []),
                  {
                    type: BindHqQuoteLineItemType.Premium,
                    amount: "",
                    lineOfBusiness:
                      selectedBindHqLines.length === 1
                        ? selectedBindHqLines[0]
                        : (undefined as unknown as BindHqLineOfBusiness), // If there are multiple lines, don't pre-select a line
                  },
                ]);
              }}
            >
              <Icon icon="add" />
              Add an Additional Line Item
            </Button>
          </div>
          <Label>Policy Number*</Label>
          <FieldErrorMessage error={formMethods.formState.errors.bindHqInput?.createPolicy?.policyNumber} />
          <FieldInput
            icon="tag"
            placeholder="Policy Number"
            {...formMethods.register("bindHqInput.createPolicy.policyNumber")}
          />
          <Label>Invoice Due Date*</Label>
          <DatePickerWithErrorMessage formMethods={formMethods} fieldName="bindHqInput.createPolicy.invoiceDueDate" />
        </form>
      </FormProvider>
      <Button form={formId} className="mt-2 w-full">
        Submit to BindHQ {submitting && <Spinner />}
      </Button>
    </div>
  );
}

const useBindQuote = (insuredId: string | undefined, quoteId: string | undefined, onCompleted?: () => void) => {
  const navigate = useNavigate();
  const [updateAgency] = useUpdateAgencyMutation({
    onError: (error) =>
      toast({ title: "Error updating Agency", description: parseError(error), variant: "destructive" }),
  });
  const [updateInsured] = useUpdateInsuredMutation({
    onError: (error) =>
      toast({ title: "Error updating Insured", description: parseError(error), variant: "destructive" }),
  });
  const [createClientData] = useCreateClientDataMutation({
    onError: (error) =>
      toast({ title: "Error updating ClientData", description: parseError(error), variant: "destructive" }),
  });
  const [bindQuote] = useBindQuoteMutation({
    refetchQueries: ["Policies"],
    onError: (error) =>
      toast({ title: "Error creating Application in BindHQ", description: parseError(error), variant: "destructive" }),
    onCompleted: ({ bindQuote: quote }) => {
      const { policy } = quote;
      onCompleted?.();
      navigate(`/insured/${insuredId}/policies/${policy?.id}`);
    },
  });

  return async (data: z.infer<typeof BindHqPolicyRequirementsSchema>) => {
    const agencyResponse = await updateAgency({
      variables: {
        input: data.agency,
      },
    });
    const insuredResponse = await updateInsured({
      variables: {
        input: data.insured,
      },
    });
    let clientDataResponse = undefined;
    if (!data.insured.bindHqId && data.clientData) {
      clientDataResponse = await createClientData({
        variables: {
          input: {
            insuredId: data.insured.id,
            source: "BIND_HQ_POLICY_SUBMISSION", // ClientDataSources.BindHqPolicySubmission enum value
            clientDataInputs: Object.entries(data.clientData)
              .filter(([, value]) => Boolean(value))
              .map(([key, value]) => ({
                key: key,
                value: value!,
                index: null,
              })),
          },
        },
      });
    }

    if (agencyResponse.errors || insuredResponse.errors || clientDataResponse?.errors) {
      return;
    }

    await bindQuote({
      variables: {
        input: {
          quoteId,
          ...data.bindHqInput,
        },
      },
    });
  };
};

const loadingPlaceholder = (loading: boolean, placeholder: string) =>
  loading ? (
    <span className="flex flex-row items-center gap-2">
      Loading <Spinner />
    </span>
  ) : (
    placeholder
  );

interface EnumSelectProps<T> {
  control: Control<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  enumType: T;
  placeholder?: string;
  onChange?: (value: keyof T) => void;
  icon?: IconString;
}
const EnumSelect = <T extends Record<string, string>>({
  control,
  fieldName,
  enumType,
  placeholder,
  onChange,
  icon,
}: EnumSelectProps<T>) => {
  return (
    <Field icon={icon ?? "list"}>
      <FormField
        control={control}
        name={fieldName}
        render={({ field }) => (
          <FormItem className="flex-auto">
            <Select
              key={field.value}
              onValueChange={(value) => {
                field.onChange(value as unknown as typeof enumType);
                onChange && onChange(value as keyof T);
              }}
              value={field.value}
            >
              <SelectTrigger className={fieldInputClassName}>
                <SelectValue placeholder={placeholder ?? "Select an Option"} />
              </SelectTrigger>
              <SelectContent>
                {Object.values(enumType).map((type) => (
                  <SelectItem key={type} value={type}>
                    {startCase(type)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        )}
      />
    </Field>
  );
};

interface UsStateSelectProps {
  control: Control<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  placeholder?: string;
  required?: boolean;
}
const UsStateSelect: React.FC<UsStateSelectProps> = ({ control, fieldName, placeholder, required = false }) => {
  return (
    <Field icon="location_on">
      <FormField
        control={control}
        name={fieldName}
        render={({ field }) => (
          <FormItem className="flex-auto">
            <Select key={field.value} onValueChange={field.onChange} value={field.value} required={required}>
              <SelectTrigger className={fieldInputClassName}>
                <SelectValue placeholder={placeholder ?? "State*"} />
              </SelectTrigger>
              <SelectContent>
                {US_STATES.map((state) => (
                  <SelectItem key={state} value={state}>
                    {state}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        )}
      />
    </Field>
  );
};

interface DatePickerWithErrorMessageProps {
  formMethods: UseFormReturn<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  dateFormat?: string;
  onSelect?: (date: Date | undefined) => void;
}
const DatePickerWithErrorMessage: React.FC<DatePickerWithErrorMessageProps> = ({
  formMethods,
  fieldName,
  dateFormat,
  onSelect,
}) => {
  return (
    <Controller
      control={formMethods.control}
      name={fieldName}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          {error?.message && <span className="text-destructive text-xs ml-2">{error.message.toString()}</span>}
          <Field icon="event" className="w-full">
            <DatePicker
              dateFormat={dateFormat}
              placeholder="Select a Date"
              selected={formMethods.watch(fieldName)}
              onSelect={(e) => {
                onChange(e?.toISOString() ?? "");
                onSelect && onSelect(e);
              }}
              className="pl-3 pr-3.5"
            />
          </Field>
        </>
      )}
    />
  );
};

const LinesOfBusiness: React.FC<{ lines: BindHqLineOfBusiness[]; onDelete(line: BindHqLineOfBusiness): void }> = ({
  lines,
  onDelete,
}) => {
  if (lines && lines.length > 0) {
    return (
      <ol className="flex flex-row flex-wrap m-1 gap-2">
        {lines?.map((line) => (
          <li
            key={line}
            className="bg-primary cursor-pointer flex flex-row gap-1 items-center text-background rounded-full p-1"
          >
            <span className="ml-1.5 leading-none text-xs">{BindHqLineOfBusinessDisplayName[line]}</span>
            <div className="flex h-min w-min m-0 p-0" onClick={() => onDelete(line)}>
              <Icon icon="cancel" className="filled" />
            </div>
          </li>
        ))}
      </ol>
    );
  }

  return null;
};

/**
 * Using this component instead of setting fields as required via params in individual field inputs because:
 * 1) some inputs don't accept the required prop (ex: Autocomplete)
 * 2) the error popups sometimes don't render in the right location on the page, so they're not very useful. Not sure why.
 */
const FieldErrorMessage: React.FC<{ error?: FieldError }> = ({ error }) => {
  if (error?.message) {
    return <span className="text-destructive text-xs ml-2">{error.message}</span>;
  }

  return null;
};

// This map is necessary because the type of Client.legalEntityType in graphql type is a string
// This is because some Client legalEntityType values are not valid BindHQ legalEntityType values
// So we need to map the string values to the BindHQ legalEntityType values until we can specify
// the graphql type to be type BindHqInsuredType once all DB values are updated
const BindHqLegalEntityMap: Record<string, BindHqInsuredType> = {
  corporation: BindHqInsuredType.Corporation,
  individual: BindHqInsuredType.Individual,
  individuals: BindHqInsuredType.Individuals,
  joint_venture: BindHqInsuredType.JointVenture,
  limited_liability_company: BindHqInsuredType.LimitedLiabilityCompany,
  Limited_corporation: BindHqInsuredType.LimitedCorporation,
  limited_partnership: BindHqInsuredType.LimitedPartnership,
  non_profit: BindHqInsuredType.NonProfit,
  partnership: BindHqInsuredType.Partnership,
  proprietorship: BindHqInsuredType.Proprietorship,
  sole_proprietor: BindHqInsuredType.SoleProprietor,
  subchapter_s_corporation: BindHqInsuredType.SubchapterSCorporation,
  trust: BindHqInsuredType.Trust,
  other: BindHqInsuredType.Other,
};

const BindHqLineOfBusinessDisplayName: Record<BindHqLineOfBusiness, string> = {
  [BindHqLineOfBusiness.Acct]: "Accountants Professional",
  [BindHqLineOfBusiness.Ache]: "Accident and Health",
  [BindHqLineOfBusiness.ActiveShooter]: "Active Shooter",
  [BindHqLineOfBusiness.Agop]: "Agricultural Output Program (Used primarily for statistical reporting)",
  [BindHqLineOfBusiness.Aglia]: "Agriculture Liability",
  [BindHqLineOfBusiness.Apkge]: "Agriculture Package",
  [BindHqLineOfBusiness.Agpr]: "Agriculture Property",
  [BindHqLineOfBusiness.Agpp]: "Agriculture Scheduled and Unscheduled Personal Property",
  [BindHqLineOfBusiness.Airc]: "Aircraft",
  [BindHqLineOfBusiness.Aprod]: "Aircraft Products Liability",
  [BindHqLineOfBusiness.Airpfb]: "Airport & Fixed Base Operator",
  [BindHqLineOfBusiness.Arch]: "Architects Professional",
  [BindHqLineOfBusiness.Art]: "Artisans",
  [BindHqLineOfBusiness.Auto]: "Automobile",
  [BindHqLineOfBusiness.Autob]: "Automobile - Business",
  [BindHqLineOfBusiness.Autop]: "Automobile - Personal",
  [BindHqLineOfBusiness.Avpkg]: "Aviation Package",
  [BindHqLineOfBusiness.Bail]: "Bailees",
  [BindHqLineOfBusiness.Bandm]: "Boiler and Machinery (Equipment Breakdown)",
  [BindHqLineOfBusiness.BuildersRisk]: "Builders Risk",
  [BindHqLineOfBusiness.Bop]: "Business Owners Policy",
  [BindHqLineOfBusiness.Bopgl]: "Business Owners Policy Liability",
  [BindHqLineOfBusiness.Boppr]: "Business Owners Policy Property",
  [BindHqLineOfBusiness.Bpp]: "Business Personal Property",
  [BindHqLineOfBusiness.Cavn]: "Commercial Aviation",
  [BindHqLineOfBusiness.Cyber]: "Commercial Cyber and Privacy Liability",
  [BindHqLineOfBusiness.Cfire]: "Commercial Fire",
  [BindHqLineOfBusiness.Cpmp]: "Commercial Multi Peril",
  [BindHqLineOfBusiness.Cop]: "Commercial Output Program (Used primarily for statistical reporting)",
  [BindHqLineOfBusiness.Cpkge]: "Commercial Package",
  [BindHqLineOfBusiness.Cp]: "Commercial Property",
  [BindHqLineOfBusiness.ContingentAuto]: "Contingent Auto",
  [BindHqLineOfBusiness.ContingentCargo]: "Contingent Cargo",
  [BindHqLineOfBusiness.Contr]: "Contract",
  [BindHqLineOfBusiness.ContractorsEquipment]: "Contractors Equipment",
  [BindHqLineOfBusiness.Cpl]: "Contractors Pollution Liability",
  [BindHqLineOfBusiness.Crim]: "Crime",
  [BindHqLineOfBusiness.Crime]: "Crime (includes Burglary)",
  [BindHqLineOfBusiness.Do]: "Directors And Officers",
  [BindHqLineOfBusiness.Disab]: "Disability",
  [BindHqLineOfBusiness.Dfire]: "Dwelling Fire",
  [BindHqLineOfBusiness.Eq]: "Earthquake",
  [BindHqLineOfBusiness.El]: "Employers Liability",
  [BindHqLineOfBusiness.Epli]: "Employment Practices Liability Insurance",
  [BindHqLineOfBusiness.Epkg]: "Environmental Package",
  [BindHqLineOfBusiness.Eil]: "Environmental Impairment Liability/Site Specific Liability",
  [BindHqLineOfBusiness.Eqlia]: "Equine Liability",
  [BindHqLineOfBusiness.Eqfltr]: "Equipment Floater",
  [BindHqLineOfBusiness.Eo]: "Errors and Omissions",
  [BindHqLineOfBusiness.Expl]: "Excess Environmental",
  [BindHqLineOfBusiness.Exlia]: "Excess Liability",
  [BindHqLineOfBusiness.Cfrm]: "Farm Owners",
  [BindHqLineOfBusiness.Fidty]: "Fidelity",
  [BindHqLineOfBusiness.Fiduc]: "Fiduciary",
  [BindHqLineOfBusiness.Flood]: "Flood",
  [BindHqLineOfBusiness.Garag]: "Garage and Dealers",
  [BindHqLineOfBusiness.Gl]: "General Liability",
  [BindHqLineOfBusiness.Hlth]: "Health",
  [BindHqLineOfBusiness.Home]: "Homeowners",
  [BindHqLineOfBusiness.Hbb]: "Home Based Business",
  [BindHqLineOfBusiness.Inmar]: "Inland Marine",
  [BindHqLineOfBusiness.Inmrc]: "Inland Marine (commercial)",
  [BindHqLineOfBusiness.Inmrp]: "Inland Marine (personal lines)",
  [BindHqLineOfBusiness.InstallationFloater]: "Installation Floater",
  [BindHqLineOfBusiness.Agent]: "Insurance Agents",
  [BindHqLineOfBusiness.Il]: "Interline",
  [BindHqLineOfBusiness.Judcl]: "Judicial",
  [BindHqLineOfBusiness.Kidra]: "Kidnap and Ransom",
  [BindHqLineOfBusiness.Law]: "Lawyers Professional",
  [BindHqLineOfBusiness.Licpt]: "License and Permit",
  [BindHqLineOfBusiness.Ll]: "Liquor Liability",
  [BindHqLineOfBusiness.Lvstk]: "Livestock",
  [BindHqLineOfBusiness.Lmort]: "Livestock Mortality",
  [BindHqLineOfBusiness.Logpckg]: "Logistics Package",
  [BindHqLineOfBusiness.Lstin]: "Lost Instrument",
  [BindHqLineOfBusiness.Media]: "Media Professional",
  [BindHqLineOfBusiness.Mmal]: "Medical Malpractice",
  [BindHqLineOfBusiness.Mpl]: "Medical Professional Liability",
  [BindHqLineOfBusiness.Bmisc]: "Miscellaneous Bond",
  [BindHqLineOfBusiness.Plmsc]: "Miscellaneous Professional Liability",
  [BindHqLineOfBusiness.Mhome]: "Mobile Homeowners",
  [BindHqLineOfBusiness.Motorcycle]: "Motorcycle",
  [BindHqLineOfBusiness.Mtrcr]: "Motor Carrier",
  [BindHqLineOfBusiness.Mtc]: "Motor Truck Cargo",
  [BindHqLineOfBusiness.Nwfgr]: "New Financial Guarantee",
  [BindHqLineOfBusiness.Blank]: "Not Applicable (Blank)",
  [BindHqLineOfBusiness.Comr]: "Ocean Marine",
  [BindHqLineOfBusiness.Olib]: "Other Liability",
  [BindHqLineOfBusiness.Ocp]: "Owners & Contractors Protective",
  [BindHqLineOfBusiness.Pkg]: "Package",
  [BindHqLineOfBusiness.Ppl]: "Personal and Premises Liability",
  [BindHqLineOfBusiness.Prsl]: "Personal Liability",
  [BindHqLineOfBusiness.Ppkge]: "Personal Package",
  [BindHqLineOfBusiness.Phys]: "Physicians and Surgeons",
  [BindHqLineOfBusiness.Hang]: "Private Hangar",
  [BindHqLineOfBusiness.ProductRecall]: "Product Recall",
  [BindHqLineOfBusiness.Plib]: "Products Liability",
  [BindHqLineOfBusiness.Mpp]: "Products Pollution",
  [BindHqLineOfBusiness.Pl]: "Professional Liability",
  [BindHqLineOfBusiness.Pubof]: "Public Official",
  [BindHqLineOfBusiness.Rrprl]: "Railroad Protective Liability",
  [BindHqLineOfBusiness.Recv]: "Recreational Vehicles",
  [BindHqLineOfBusiness.Shipintr]: "Shippers Interest",
  [BindHqLineOfBusiness.Scap]: "Small Commercial Package",
  [BindHqLineOfBusiness.Sfrnc]: "Small Farm/Ranch",
  [BindHqLineOfBusiness.Smp]: "Special Multi-Peril",
  [BindHqLineOfBusiness.Stock]: "Stock",
  [BindHqLineOfBusiness.Stkth]: "Stock Throughput",
  [BindHqLineOfBusiness.Stpl]: "Storage Tank Liability",
  [BindHqLineOfBusiness.Sure]: "Surety",
  [BindHqLineOfBusiness.Tech]: "Technology",
  [BindHqLineOfBusiness.TripTransit]: "Trip Transit",
  [BindHqLineOfBusiness.Truck]: "Truckers",
  [BindHqLineOfBusiness.Umbrl]: "Umbrella",
  [BindHqLineOfBusiness.Umbrc]: "Umbrella - Commercial",
  [BindHqLineOfBusiness.Umbrp]: "Umbrella - Personal (excess indemnity)",
  [BindHqLineOfBusiness.Un]: "Unknown",
  [BindHqLineOfBusiness.Warel]: "Warehouse Legal",
  [BindHqLineOfBusiness.Boat]: "Watercraft (small boat)",
  [BindHqLineOfBusiness.Wind]: "Wind Policy",
  [BindHqLineOfBusiness.Work]: "Workers Compensation",
  [BindHqLineOfBusiness.Workp]: "Workers Compensation Participating",
  [BindHqLineOfBusiness.Wcma]: "Workers Comp Marine",
  [BindHqLineOfBusiness.Workv]: "Workplace Violence",
  [BindHqLineOfBusiness.Yacht]: "Yacht",
};

const QwLobToBindHqLob: Record<string, BindHqLineOfBusiness | undefined> = {
  "Stock Throughput": BindHqLineOfBusiness.Stkth,
  "Builder's Risk": BindHqLineOfBusiness.BuildersRisk,
  "Motor Truck Cargo": BindHqLineOfBusiness.Mtc,
  Flood: BindHqLineOfBusiness.Flood,
  "Tech E&O": BindHqLineOfBusiness.Eo,
  Property: BindHqLineOfBusiness.Cp,
  "Product Recall": BindHqLineOfBusiness.ProductRecall,
  "Contractors Pollution": BindHqLineOfBusiness.Cpl,
  "Non-Dealers Garage Liability": BindHqLineOfBusiness.Garag,
  Fiduciary: BindHqLineOfBusiness.Fiduc,
  "Excess Liability/Umbrella": BindHqLineOfBusiness.Exlia,
  "Workers Compensation": BindHqLineOfBusiness.Work,
  "General Liability": BindHqLineOfBusiness.Gl,
  "D&O": BindHqLineOfBusiness.Do,
  "Liquor Liability": BindHqLineOfBusiness.Ll,
  "Active Shooter": BindHqLineOfBusiness.ActiveShooter,
  Bailees: BindHqLineOfBusiness.Bail,
  "Storage Tank Liability": BindHqLineOfBusiness.Stpl,
  "Ocean Marine": BindHqLineOfBusiness.Comr,
  Cyber: BindHqLineOfBusiness.Cyber,
  "Inland Marine": BindHqLineOfBusiness.Inmar,
  "Contractors Equipment": BindHqLineOfBusiness.ContractorsEquipment,
  Crime: BindHqLineOfBusiness.Crime,
  "Equipment Breakdown": BindHqLineOfBusiness.Eqfltr,
  "Contingent Cargo": BindHqLineOfBusiness.ContingentCargo,
  "Garage Liability": BindHqLineOfBusiness.Garag,
  "Products Pollution": BindHqLineOfBusiness.Mpp,
  "Products Liability": BindHqLineOfBusiness.Plib,
  "E&O/Professional Liability": BindHqLineOfBusiness.Eo,
  "Installation Floaters": BindHqLineOfBusiness.InstallationFloater,
  "Hangarkeepers Liability": BindHqLineOfBusiness.Hang,
  "Commercial Auto": BindHqLineOfBusiness.Auto,
};
