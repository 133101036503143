import { Outlet } from "react-router";

import { cn } from "@/utils";
import { Sidebar } from "./sidebar";

export interface BaseProps {
  className?: string;
  children?: React.ReactNode;
  id?: string;
  onClick?: VoidFunction;
}

export const Page: React.FC<BaseProps> = ({ className, children }) => (
  <>
    <Sidebar />
    <div className={cn("flex min-h-dvh w-dvw", "landscape:pl-16", "portrait:pb-[--nav-height]", className)} id="Page">
      {children}
    </div>
  </>
);

export const Centered = () => (
  <div className="flex flex-col items-center justify-center min-h-dvh p-4">
    <Outlet />
  </div>
);

export const Container = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section
    className={cn("@container/opp flex flex-auto flex-col min-w-0 p-3 pt-0 landscape:pl-0 space-y-3", className)}
    {...rest}
  >
    {children}
  </section>
);

export const ContainerHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <header
    className={cn(
      "bg-background flex gap-2 items-center min-h-16 overflow-hidden px-4 rounded-b-lg shadow-sm",
      className
    )}
    {...rest}
  >
    {children}
  </header>
);

export const ScrollPane = ({ children, className, id }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "bg-accent flex flex-auto flex-col gap-px max-h-dvh max-w-full overflow-auto rounded-md shadow-lg",
      "landscape:m-2 landscape:ml-0 landscape:max-h-landscape landscape:max-w-landscape",
      "portrait:max-h-portrait",
      className
    )}
    id={id}
  >
    {children}
  </div>
);
