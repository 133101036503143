import { useEffect } from "react";
import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { HasInternalRole } from "@/components/has-role";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { GoogleDriveButton } from "@/files/google-drive-button";
import { Requirements } from "@/insured/requirements/requirements";
import {
  MarketingPlanState,
  OnOpportunityStateChangedDocument,
  OnOpportunityStateChangedSubscription,
  OnOpportunityStateChangedSubscriptionVariables,
  OpportunityKitchenSinkQueryResult,
  useOpportunityKitchenSinkQuery,
} from "src/generated/graphql";
import { Submissions } from "../submissions/submissions";
import { MarketingPlanActions } from "./actions/marketing-plan-actions";
import { Coverage } from "./coverage";
import { FollowUps } from "./follow-ups/follow-ups";

export type MarketingPlan = NonNullable<NonNullable<OpportunityKitchenSinkQueryResult["data"]>["opportunity"]>;

export const MarketingPlanDetails = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const {
    data: { opportunity } = {},
    subscribeToMore,
    refetch,
  } = useOpportunityKitchenSinkQuery({
    variables: {
      id: opportunityId ?? opportunityId!,
    },
    skip: !opportunityId,
  });

  useEffect(() => {
    if (!opportunityId) {
      return;
    }
    return subscribeToMore<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>({
      document: OnOpportunityStateChangedDocument,
      variables: { id: opportunityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        return { opportunity: subscriptionData.data.opportunityStateChanged };
      },
      onError: (error) => {
        console.error("Subscription error:", error);
      },
    });
  }, [opportunityId]);

  if (!opportunity) {
    return null;
  }

  return (
    <div className="space-y-3">
      <AgentMessage state={opportunity.state} />
      {opportunity.state === MarketingPlanState.WaitingForCoverages ? (
        <Coverage skipNavigation />
      ) : (
        <Card>
          <CardHeader className="bg-accent">
            <CardTitle>
              Markets
              <HasInternalRole>
                <MarketingPlanActions marketingPlan={opportunity} />
              </HasInternalRole>
            </CardTitle>
          </CardHeader>
          <Submissions submissions={opportunity.submissions} refetch={refetch} />
        </Card>
      )}
      <Requirements />
      {opportunity.state !== MarketingPlanState.WaitingForCoverages && (
        <div className="flex gap-3 items-start">
          <div className="flex-auto space-y-3">
            <Activity opportunityId={opportunity.id} />
          </div>
          <HasInternalRole>
            <div className="flex-none space-y-3 w-80">
              <FollowUps />
              <GoogleDriveButton
                opportunityId={opportunity.id}
                brokerageFolderId={opportunity.insured.brokerageFolderId}
              />
            </div>
          </HasInternalRole>
        </div>
      )}
    </div>
  );
};
