import { States, STATE_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { HasExternalRole } from "./has-role";
import { Card } from "./ui/card";
import { Icon } from "./ui/icon";

export const AgentMessage = ({ state }: { state: States }) => {
  const { className, cardClassName, icon, agentMessage } = STATE_METADATA[state];

  return (
    <HasExternalRole>
      {agentMessage && (
        <Card>
          <div className={cn("border flex items-center rounded-lg", cardClassName, className)}>
            <div className="aspect-auto flex flex-none h-24 w-24 items-center justify-center p-4 text-4xl">
              <Icon icon={icon} />
            </div>
            <div className="flex-auto p-4 pl-0 space-y-2">{agentMessage}</div>
          </div>
        </Card>
      )}
    </HasExternalRole>
  );
};
