import { Icon } from "@/components/ui/icon";
import { StateIndicator } from "@/metadata";
import { cn } from "@/utils";
import { InsuredOpportunitiesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { NavBlockLink, NavBlockLinkContainer, NavHeader, NavSection } from "./nav";

type MarketingPlans = InsuredOpportunitiesQuery["insuredOpportunities"][number];

export const NavMarketingPlans = ({ plans }: { plans: MarketingPlans[] }) => (
  <NavSection className={cn(plans.length === 0 && "bg-background border-primary/25")}>
    <NavHeader label="Active Marketing Plans" />
    <NavBlockLinkContainer>
      {plans.map((opp) => (
        <NavBlockLink key={opp.id} to={`plans/${opp.id}`}>
          <div className="flex items-start justify-between">
            <div className="font-semibold space-y-0.5 text-xs">
              {opp.selectedLinesOfBusiness[0]
                ? opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                : "No Coverage Selected"}
            </div>
            {opp.renewalOf && <Icon icon="cycle" />}
          </div>
          <div className="flex justify-between text-2xs">
            <StateIndicator state={opp.state} />
            <span>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, yyyy")}</span>
          </div>
        </NavBlockLink>
      ))}
    </NavBlockLinkContainer>
  </NavSection>
);
