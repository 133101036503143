import { Link } from "react-router-dom";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { MarketingPlanStateMarketingActions, useTransitionMarketingPlanMutation } from "src/generated/graphql";
import { MarketingPlan } from "../marketing-plan-details";

export const Marketing: React.FC<{ marketingPlan: MarketingPlan }> = ({ marketingPlan }) => {
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanStateMarketingActions) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm marketing complete",
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button asChild variant="outline" size="sm">
        <Link to="markets">Select Markets</Link>
      </Button>
      <Button
        onClick={() => handleTransition(MarketingPlanStateMarketingActions.CompleteMarketing)}
        variant="outline"
        size="sm"
      >
        Complete Marketing
      </Button>
    </>
  );
};
