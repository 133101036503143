import {
  AppetiteGuidesForOpportunityQuery,
  QuoteSubmissionFragment,
  SubmissionFragment,
  SubmissionState,
} from "src/generated/graphql";
import { MarketBlocked } from "./market-blocked";
import { Pending } from "./pending";
import { Quoted } from "./quoted";
import { Referred } from "./referred";
import { RuledOut } from "./ruled-out";
import { Submitted } from "./submitted";

export const SubmissionActions: React.FC<{ submission: SubmissionFragment; refetch: () => void }> = ({
  submission,
  refetch,
}) => {
  const { state } = submission;

  const renderSubmissionState = () => {
    switch (state) {
      case SubmissionState.Pending:
        return <Pending submission={submission} />;
      case SubmissionState.Submitted:
        return <Submitted submission={submission} refetch={refetch} />;
      case SubmissionState.RuledOut:
        return <RuledOut submission={submission} />;
      case SubmissionState.Referred:
        return <Referred submission={submission} />;
      case SubmissionState.MarketBlocked:
        return <MarketBlocked submission={submission} />;
      case SubmissionState.Quoted:
        return <Quoted submission={submission} refetch={refetch} />;
      default:
        return null;
    }
  };

  return <div className="col-span-3 flex gap-2 items-center justify-end">{renderSubmissionState()}</div>;
};

export type MarketingEffortSubmission = SubmissionFragment &
  QuoteSubmissionFragment & {
    appetiteGuide?: AppetiteGuidesForOpportunityQuery["appetiteGuidesForOpportunity"][number];
  };
