import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useFileJobsQuery } from "../../../generated/graphql";
import { Loading } from "../../components/ui/loading";

const FileJobs = ({ fileId }: { fileId: string | undefined }) => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const { loading, error, data } = useFileJobsQuery({
    variables: {
      input: {
        fileId: fileId!,
      },
    },
    pollInterval: 5000,
  });

  if (loading || error) {
    return null;
  }

  const isAnyJobRunning = data?.fileJobs.some((job) => job.status === "running");

  return (
    <Card>
      <CardHeader className="bg-accent relative">
        <CardTitle>
          File Processing
          <Button variant="ghost" size="icon-sm" onClick={() => setIsTableVisible(!isTableVisible)}>
            {isTableVisible ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </Button>
        </CardTitle>
        <CardDescription>
          {data?.fileJobs.filter((j) => j.status === "completed").length} completed,{" "}
          {data?.fileJobs.filter((j) => j.status === "error").length} failed,{" "}
          {data?.fileJobs.filter((j) => j.status === "running").length} running
        </CardDescription>
      </CardHeader>
      {isTableVisible && (
        <Table className="border-t text-2xs">
          <TableHeader>
            <TableRow>
              <TableHead>Status</TableHead>
              <TableHead>Time </TableHead>
              <TableHead>Pipeline / ID</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.fileJobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.status}</TableCell>
                <TableCell>{formatDistanceToNow(parseISO(job.createdAt), { addSuffix: true })}</TableCell>
                <TableCell>
                  {job.pipeline}
                  <br />
                  {job.id}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {isAnyJobRunning && (
        <CardFooter className="block italic p-4 space-y-4 text-muted-foreground text-xs">
          <Loading label="Some jobs are still running" />
          <p>
            If any processing jobs are pending you can wait a few minutes for them to complete or ignore and complete
            the form manually.
          </p>
        </CardFooter>
      )}
    </Card>
  );
};

export default FileJobs;
