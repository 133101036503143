import { STATE_METADATA } from "@/metadata";
import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";

type Insured = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const PoliciesCard = ({ insured }: { insured: Insured }) => {
  const { insuredId, insuredName, policies } = insured;
  return (
    <BankanCard header={insuredName}>
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, state, lastTransitionAt } = policy;
        const { cardClassName } = STATE_METADATA[state];
        return (
          <BankanLink key={id} to={`/insured/${insuredId}/policies/${id}`} className={cardClassName}>
            <BankanLinkText>
              <strong>{appetiteProduct.carrierName}: </strong>
              {appetiteProduct.carrierProductName}
            </BankanLinkText>
            <BankanLinkFooter>
              <div>#{policyNumber}</div>
              <DaysSince date={lastTransitionAt} />
            </BankanLinkFooter>
          </BankanLink>
        );
      })}
    </BankanCard>
  );
};
