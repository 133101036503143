import { HasInternalRole } from "@/components/has-role";
import { InsuredPin } from "@/components/insured-pin";
import { Card } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { InsuredWithDuplicateFlagQuery } from "src/generated/graphql";
import { formatDate } from "src/utils/date";
import { NavLink, navLinkClassName, NavSection } from "./nav";

type Insured = NonNullable<InsuredWithDuplicateFlagQuery["insured"]>;

export const NavInsured = ({ insured }: { insured: Insured }) => (
  <NavSection className="rounded-b-lg">
    <Card className="-m-px rounded-t-none">
      <div className="flex gap-4 h-16 items-center px-4">
        <div className="flex-auto space-y-1 truncate">
          <div className="font-semibold text-xs truncate">{insured.name}</div>
          <div className="text-2xs truncate">{insured.agency?.name}</div>
        </div>
        <InsuredPin id={insured.id} className="-mr-1" />
      </div>
      <nav className="border-t py-2.5 space-y-1">
        <HasInternalRole>
          {insured.possibleDuplicates.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className={cn(navLinkClassName, "filled font-semibold text-destructive")}>
                  Possible Duplicates
                  <Icon icon="warning" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-72">
                {insured.possibleDuplicates.map((dup) => (
                  <DropdownMenuItem
                    key={dup.id}
                    onClick={() => window.open(`/insured/${dup.id}`, "_blank", "noopener,noreferrer")}
                  >
                    <div className="truncate mr-auto">{dup.name}</div>
                    <div className="flex-none text-2xs tabular-nums text-muted-foreground">
                      {formatDate(new Date(dup.createdAt), "MM/dd/yy h:mm a")}
                    </div>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </HasInternalRole>
        <NavLink to="plans">
          Marketing Plans
          <Icon icon="campaign" />
        </NavLink>
        <NavLink to="quotes">
          Quotes
          <Icon icon="request_quote" />
        </NavLink>
        <NavLink to="policies">
          Policies
          <Icon icon="shield" />
        </NavLink>
      </nav>
    </Card>
    <nav className="py-2.5 space-y-1">
      <NavLink to={`/insured/${insured.id}`} end>
        Insured Details
        <Icon icon="info" />
      </NavLink>
      <NavLink to="files">
        Files
        <Icon icon="upload" />
      </NavLink>
      <NavLink to="email">
        Email Threads
        <Icon icon="mail" />
      </NavLink>
    </nav>
  </NavSection>
);
