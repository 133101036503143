import { useDocumentTitle } from "usehooks-ts";

import { Badge } from "@/components/ui/badge";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { parseError } from "@/utils";
import { useAgenciesWithOwnerQuery, useUpdateAgencyOwnerMutation } from "src/generated/graphql";
import { SelectBroker } from "./select-broker";

export function AgencyOwnership() {
  const { data: agenciesData, refetch } = useAgenciesWithOwnerQuery();
  const [updateAgencyOwner] = useUpdateAgencyOwnerMutation({
    onCompleted: async (update) => {
      await refetch();
      toast({ title: `Updated ${update.updateAgency.name} Owner` });
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });
  useDocumentTitle("Admin: Agency Ownership");

  return (
    <>
      <CardHeader className="bg-accent border-b">
        <CardTitle>Agency Ownership</CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Agency</TableHead>
            <TableHead>Owner</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {agenciesData?.agencies.map((agency) => (
            <TableRow key={agency.id}>
              <TableCell>
                <span className="flex gap-2">
                  {agency.name}
                  {agency.isTest && <Badge>Test</Badge>}
                </span>
              </TableCell>
              <TableCell>
                <SelectBroker
                  brokerId={agency.owner?.id}
                  onSelect={(ownerId) => {
                    void updateAgencyOwner({
                      variables: { input: { agencyId: agency.id, ownerId: ownerId == "-" ? null : ownerId } },
                    });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
