import { MarketingPlansCard } from "@/bankan/cards/marketing-plans-card";
import { QuotesCard } from "@/bankan/cards/quotes-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  MarketingPlanState,
  QuoteState,
  useGroupedMarketingPlansQuery,
  useGroupedQuotesQuery,
} from "src/generated/graphql";

export const InsuredsRequests = ({ id }: { id: string }) => {
  const { data: { groupedMarketingPlans: { groupedMarketingPlans = [] } = {} } = {} } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        agencyId: id,
        states: [MarketingPlanState.InformationGathering],
      },
    },
  });

  const { data: { groupedQuotes: { groupedQuotes = [] } = {} } = {} } = useGroupedQuotesQuery({
    variables: {
      input: {
        agencyId: id,
        states: [QuoteState.Delivered],
      },
    },
  });

  const quoteCount = groupedQuotes.flatMap((insured) => insured.quotes).length;
  const planCount = groupedMarketingPlans.flatMap((insured) => insured.marketingPlans).length;
  const display = quoteCount + planCount > 0;

  return (
    <>
      {display && (
        <>
          {groupedMarketingPlans[0] && (
            <Card>
              <CardHeader className="bg-accent border-b">
                <CardTitle>
                  {planCount} information request
                  {planCount > 1 && "s"}
                </CardTitle>
              </CardHeader>
              <CardContent className={gridClassName}>
                {groupedMarketingPlans.map((insured) => (
                  <MarketingPlansCard key={insured.insuredId} insured={insured} />
                ))}
              </CardContent>
            </Card>
          )}
          {groupedQuotes[0] && (
            <Card>
              <CardHeader className="bg-accent border-b">
                <CardTitle>
                  {quoteCount} quote{quoteCount > 1 && "s"} to review
                </CardTitle>
              </CardHeader>
              <CardContent className={gridClassName}>
                {groupedQuotes.map((insured) => (
                  <QuotesCard key={insured.insuredId} insured={insured} />
                ))}
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
};

const gridClassName = "gap-4 grid max-sm:grid-cols-2 max-lg:grid-cols-3 items-start p-4";
