import { Loading } from "@/components/ui/loading";
import { MarketingPlanBankanColumn, useBankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { MarketingPlansCard } from "./cards/marketing-plans-card";

export const MarketingPlanColumn = ({
  column,
  agencyId,
  brokerId,
}: {
  column: MarketingPlanBankanColumn;
  agencyId?: string | null;
  brokerId?: string | null;
}) => {
  const { data: { bankanMarketingPlanColumn } = {}, loading } = useBankanMarketingPlanColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        column: column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const insureds = bankanMarketingPlanColumn?.groupedMarketingPlans;

  return (
    <BankanColumn id={column} count={insureds?.length}>
      {loading ? (
        <Loading />
      ) : (
        insureds?.map((insured) => <MarketingPlansCard key={insured.insuredId} insured={insured} />)
      )}
    </BankanColumn>
  );
};
