import { Link, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { QuoteFragment, QuoteStateBindRequestReceivedActions, useTransitionQuoteMutation } from "src/generated/graphql";

export const BindRequestReceived = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();
  const { insuredId } = useParams<"insuredId">();

  const handleTransition = async (action: QuoteStateBindRequestReceivedActions) => {
    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button variant="outline" size="sm" asChild>
        <Link to={`/insured/${insuredId}/quotes/${quote.id}/bind`}>Bind Policy</Link>
      </Button>
      <Button
        onClick={() => handleTransition(QuoteStateBindRequestReceivedActions.Invalidate)}
        variant="outline"
        size="sm"
      >
        Invalidate
      </Button>
      <Button
        onClick={() => handleTransition(QuoteStateBindRequestReceivedActions.ReceiveNotTaken)}
        variant="outline"
        size="sm"
      >
        Quote Not Taken
      </Button>
    </>
  );
};
