import { useAgencyAffiliationQuery } from "src/generated/graphql";
import { Card } from "./ui/card";

export const AgencyAffiliations = ({ id }: { id: string }) => {
  const { data: { agency = {} } = {} } = useAgencyAffiliationQuery({
    variables: {
      id: id ?? "",
    },
  });

  if (!agency.affiliations || agency.affiliations.length === 0) {
    return null;
  }

  return (
    <Card className="bg-card bg-gradient-to-br from-amber-100/25 to-yellow-400/25 border border-current text-amber-700 dark:text-amber-300 space-y-px">
      {agency.affiliations.map((affiliation) => (
        <div key={affiliation.name} className="flex gap-2 h-12 items-center px-4 first:rounded-t-lg last:rounded-b-lg">
          <div className="flex flex-auto gap-3 items-center text-xs truncate">
            {affiliation.logoUrl && <img src={affiliation?.logoUrl} className="max-h-8 max-w-24 mix-blend-multiply" />}
            <span className="font-semibold truncate">{affiliation?.name}</span>
          </div>
          {(affiliation.brokerageCommission || affiliation.bindingCommission) && (
            <div className="flex-none gap grid grid-cols-2 items-center leading-none text-2xs w-24">
              {affiliation.brokerageCommission && (
                <>
                  <span>Brokerage:</span>
                  <span className="text-right">{`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
                </>
              )}
              {affiliation.bindingCommission && (
                <>
                  <span>Binding:</span>
                  <span className="text-right">{`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </Card>
  );
};
