import { join } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router";

import { Checkbox } from "@/components/ui/checkbox";
import { Loading } from "@/components/ui/loading";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useFilesByLabelQuery } from "src/generated/graphql";

interface Props {
  opportunityId: string;
  fileIds: string[];
  setFileIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SubmissionTemplateAttachments: React.FC<Props> = ({ setFileIds, fileIds }) => {
  const { insuredId } = useParams<"insuredId">();
  const { data: { filesByLabel: linkedFiles = [] } = {}, loading } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: false,
        labels: [],
        deletedAt: false,
      },
    },
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {} } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: true,
        labels: [],
        deletedAt: false,
      },
    },
  });

  const files = allFiles.length > 0 ? allFiles : linkedFiles;

  useEffect(() => {
    if (fileIds) {
      setFileIds(fileIds);
    }
  }, [fileIds]);

  if (loading) {
    return <Loading label="Loading..... Retrieving related files" />;
  }

  if (files.length === 0) {
    return (
      <>
        <h3>No files found for this opportunity</h3>
      </>
    );
  }

  return (
    <>
      <h3>Select files to add as attachments</h3>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>File Name</TableHead>
            <TableHead>Labels</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {files.map(({ id, labels, filename }) => (
            <TableRow key={id}>
              <TableCell>
                <Checkbox
                  checked={fileIds.includes(id ?? "")}
                  onCheckedChange={(checked) => {
                    checked
                      ? setFileIds((prev: string[]) => [...prev, id ?? ""])
                      : setFileIds((prev: string[]) => prev.filter((id) => id !== id));
                  }}
                />
              </TableCell>
              <TableCell>{filename}</TableCell>
              <TableCell className="text-xs">{join(labels, ", ")}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
