import { useMatches } from "react-router";
import { Fragment } from "react/jsx-runtime";

import { cn } from "@/utils";
import { Icon } from "./ui/icon";

interface Handle {
  crumb: (param?: string) => React.ReactNode;
}

export const Breadcrumbs = ({ className }: { className?: string }) => {
  const matches = useMatches();

  const crumbs = matches
    .filter((match) => Boolean(match.handle && (match.handle as Handle).crumb))
    .map((match) => {
      const crumb = (match.handle as Handle).crumb(match.data as string | undefined);
      return crumb as React.ReactNode;
    });

  return (
    <div className={cn("flex gap-1.5 items-center text-xs truncate", className)}>
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <Icon icon="chevron_right" className="first:hidden opacity-25" />
          <span className="truncate">{crumb}</span>
        </Fragment>
      ))}
    </div>
  );
};
