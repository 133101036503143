import { getAuthRequestHeaders } from "@cp/auth";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { FileUploadFragment } from "src/generated/graphql";

export const FileButton = ({ file, badge }: { file: FileUploadFragment; badge?: string }) => (
  <Button variant="outline" size="sm" asChild>
    <a
      key={file?.id}
      href={`/api/files/${file?.id}`}
      download={file?.filename}
      media={file?.mimeType}
      className="flex gap-2 items-center justify-between text-2xs truncate"
      onClick={async (e) => {
        e.preventDefault();

        const blob = await fetch(`/api/files/${file?.id}`, {
          headers: getAuthRequestHeaders(),
        })
          .then((r) => r.blob())
          .then((b) => b.slice(0, b.size, file?.mimeType));

        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = file?.filename;

        document.body.append(anchor);
        anchor.click();

        URL.revokeObjectURL(url);
        anchor.remove();
      }}
    >
      <span className="truncate">{file.filename}</span>
      {badge && <Badge>{badge}</Badge>}
    </a>
  </Button>
);
