import { STATE_METADATA } from "@/metadata";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";
import { BankanScore } from "./bankan-score";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({ insured }: { insured: Insured }) => {
  const { insuredId, insuredName, marketingPlans } = insured;
  return (
    <BankanCard header={insuredName}>
      {marketingPlans.map((plan) => {
        const { id, desiredEffectiveDate, selectedLinesOfBusiness, state, lastTransitionAt, score } = plan;
        const { cardClassName } = STATE_METADATA[state];
        return (
          <BankanLink key={id} to={`/insured/${insuredId}/${id}`} className={cardClassName}>
            <BankanLinkText className="font-semibold space-y-0.5">
              {selectedLinesOfBusiness[0]
                ? selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                : "No Coverage Selected"}
            </BankanLinkText>
            <BankanLinkFooter>
              <div className="mr-auto">{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</div>
              <BankanScore bankanScore={score} />
              <DaysSince date={lastTransitionAt} />
            </BankanLinkFooter>
          </BankanLink>
        );
      })}
    </BankanCard>
  );
};
