import { Loading } from "@/components/ui/loading";
import { BankanQuoteColumn, useBankanQuoteColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { QuotesCard } from "./cards/quotes-card";

export const QuoteColumn = ({
  column,
  agencyId,
  brokerId,
}: {
  column: BankanQuoteColumn;
  agencyId?: string | null;
  brokerId?: string | null;
}) => {
  const { data: { bankanQuoteColumn } = {}, loading } = useBankanQuoteColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const insureds = bankanQuoteColumn?.groupedQuotes;

  return (
    <BankanColumn id={column} count={insureds?.length}>
      {loading ? <Loading /> : insureds?.map((insured) => <QuotesCard key={insured.insuredId} insured={insured} />)}
    </BankanColumn>
  );
};
