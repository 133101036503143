import { groupBy, orderBy } from "lodash";
import { Outlet, useParams } from "react-router";

import { useMyAccount } from "@/auth/useMyAccount";
import { Breadcrumbs } from "@/components/breadcrumbs";
import { Cell, RowHeader, RowLink } from "@/components/grid-table";
import { hasInternalRole } from "@/components/has-role";
import { Container, ContainerHeader } from "@/components/layout";
import { Avatar } from "@/components/ui/avatar";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { StateIndicator } from "@/metadata";
import { QuoteState, useOpportunityQuery, useQuoteQuery, useQuotesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";

export const Quotes = () => {
  const { quoteId } = useParams<"quoteId">();

  const { data: { quote } = {} } = useQuoteQuery({
    variables: {
      id: quoteId ?? quoteId!,
    },
    skip: !quoteId,
    fetchPolicy: "cache-first",
  });

  const { data: { opportunity } = {} } = useOpportunityQuery({
    variables: {
      id: quote?.submission.opportunityId ?? "",
    },
    skip: !quoteId || !quote?.submission.opportunityId,
    fetchPolicy: "cache-first",
  });

  return (
    <Container id="Quotes">
      <ContainerHeader>
        <Breadcrumbs />
        {opportunity && <Avatar user={opportunity.broker} className="ml-auto" />}
      </ContainerHeader>
      <Outlet />
    </Container>
  );
};

export const QuotesIndex = () => {
  const { insuredId } = useParams();
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const { data: { quotes } = { quotes: [] } } = useQuotesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const filteredQuotes = hasInternalRole(user)
    ? quotes
    : quotes.filter(
        (quote) =>
          quote.state === QuoteState.Delivered ||
          quote.state === QuoteState.BindRequestReceived ||
          quote.state === QuoteState.NotTaken ||
          quote.state === QuoteState.Bound
      );

  const sortedQuotes = orderBy(filteredQuotes, (quote) => Date.parse(quote.effectiveDate));
  const groupedQuotes = groupBy(sortedQuotes, (quote) => formatTimezoneDateString(quote.effectiveDate, "yyyy"));

  return (
    <div className="space-y-3">
      {Object.keys(groupedQuotes).map((date) => (
        <Card key={date} className="flex-auto">
          <CardHeader className="bg-accent">
            <CardTitle>{date}</CardTitle>
          </CardHeader>
          <RowHeader>
            <Cell>Carrier</Cell>
            <Cell>Product</Cell>
            <Cell>Premium</Cell>
            <Cell>Status</Cell>
            <Cell>Eff. Date</Cell>
          </RowHeader>
          {groupedQuotes[date].map((quote) => {
            const {
              id,
              effectiveDate,
              premium,
              state,
              submission: { appetiteProduct },
            } = quote;

            return (
              <RowLink key={id} to={id}>
                <Cell>{appetiteProduct.carrierName}</Cell>
                <Cell>{appetiteProduct.carrierProductName}</Cell>
                <Cell>{formatDollars(premium ?? 0)}</Cell>
                <Cell>
                  <StateIndicator state={state} />
                </Cell>
                <Cell>{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</Cell>
              </RowLink>
            );
          })}
        </Card>
      ))}
    </div>
  );
};
