import { useSearchParams } from "react-router-dom";

import { NavigateBroker } from "@/components/navigate-broker";
import { SelectAgency } from "@/components/select/select-agency";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/utils";

export const BankanFilter = () => {
  const [, setSearchParams] = useSearchParams();
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={cn("aspect-square bg-muted hover:bg-muted rounded-full text-foreground hover:text-foreground z-1")}
        >
          <Icon icon="filter_list" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-96">
        <Section className="flex-auto">
          <RowLabel>Agency</RowLabel>
          <SelectAgency />
          <RowLabel>Broker</RowLabel>
          <NavigateBroker path="/bankan" />
        </Section>
        <Section className="border-t flex items-center py-3">
          <Button
            variant="outline"
            size="sm"
            className="ml-auto"
            onClick={() =>
              setSearchParams(
                {},
                {
                  replace: true,
                }
              )
            }
          >
            Reset
          </Button>
        </Section>
      </PopoverContent>
    </Popover>
  );
};

const Section = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <section className={cn("gap-2 grid grid-cols-[6rem_1fr] p-4", className)}>{children}</section>
);

const RowLabel = ({ children }: { children: React.ReactNode }) => (
  <div className="self-center text-xs text-muted-foreground">{children}</div>
);
