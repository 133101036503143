import { useAtom } from "jotai";
import { Link } from "react-router-dom";

import { pinnedInsuredsAtom } from "@/atoms";
import { Row } from "@/components/grid-table";
import { InsuredPin } from "@/components/insured-pin";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { InsuredFragment, usePaginatedInsuredsQuery } from "src/generated/graphql";

export const PinnedInsureds: React.FC = () => {
  const [pinnedInsuredIds] = useAtom(pinnedInsuredsAtom);

  const {
    data: { paginatedInsureds: { insureds: pinnedInsureds } } = { paginatedInsureds: { insureds: [] } },
    loading,
  } = usePaginatedInsuredsQuery({
    variables: {
      args: {
        ids: Object.keys(pinnedInsuredIds),
      },
      pagination: {},
    },
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    pinnedInsureds[0] && (
      <div className="flex-none space-y-3 lg:w-80">
        <Card>
          <CardHeader className="bg-accent">
            <CardTitle>Pinned Insureds</CardTitle>
          </CardHeader>
          <div>
            {pinnedInsureds.map((insured) => (
              <PinnedInsuredRow key={insured.id} insured={insured} />
            ))}
          </div>
        </Card>
      </div>
    )
  );
};

const PinnedInsuredRow = ({ insured }: { insured: InsuredFragment }) => {
  const { id, name } = insured;
  return (
    <Row key={id} className="flex gap-4 items-center justify-between">
      <Link to={`/insured/${id}`} className="flex-auto truncate">
        {name}
      </Link>
      <InsuredPin id={id} className="-mr-1" />
    </Row>
  );
};
