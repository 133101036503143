import { Loading } from "@/components/ui/loading";
import { BankanPolicyColumn, useBankanPolicyColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { ExpiringPoliciesCard } from "./cards/expiring-policies-card";
import { PoliciesCard } from "./cards/policies-card";

export const PolicyColumn = ({
  column,
  agencyId,
  brokerId,
}: {
  column: BankanPolicyColumn;
  agencyId?: string | null;
  brokerId?: string | null;
}) => {
  const { data: { bankanPolicyColumn } = {}, loading } = useBankanPolicyColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const insureds = bankanPolicyColumn?.groupedPolicies;

  return (
    <BankanColumn id={column} count={insureds?.length}>
      {loading ? (
        <Loading />
      ) : (
        insureds?.map((insured) =>
          column === BankanPolicyColumn.ExpiringSoon ? (
            <ExpiringPoliciesCard key={insured.insuredId} insured={insured} />
          ) : (
            <PoliciesCard key={insured.insuredId} insured={insured} />
          )
        )
      )}
    </BankanColumn>
  );
};
