import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { StateIndicator } from "@/metadata";
import { QuoteFragment } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollarsAndCents } from "src/utils/strings";

export const QuoteInfo = ({ quote }: { quote: QuoteFragment }) => {
  const {
    submission,
    premium,
    mep,
    commission,
    carrierFee,
    brokerFee,
    inspectionFee,
    effectiveDate,
    expirationDate,
    state,
    stateTransitions,
    createdAt,
  } = quote;

  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <Card>
      <CardHeader className="bg-accent border-b">
        <CardTitle>
          {submission.appetiteProduct.carrierName}
          <StateIndicator state={state} className="font-normal text-xs tracking-normal" />
        </CardTitle>
        <CardDescription>{submission.appetiteProduct.carrierProductName}</CardDescription>
      </CardHeader>
      <CardContent className="gap-4 grid grid-cols-3 p-4">
        <dl>
          <dt>Premium</dt>
          <dd>{premium && formatDollarsAndCents(premium)}</dd>
        </dl>
        <dl>
          <dt>MEP</dt>
          <dd>{mep ?? "0"}%</dd>
        </dl>
        <dl>
          <dt>Commission</dt>
          <dd>{commission}%</dd>
        </dl>
        <dl>
          <dt>Carrier Fee</dt>
          <dd>{carrierFee && formatDollarsAndCents(carrierFee)}</dd>
        </dl>
        <dl>
          <dt>Broker Fee</dt>
          <dd>{brokerFee && formatDollarsAndCents(brokerFee)}</dd>
        </dl>
        <dl>
          <dt>Inspection Fee</dt>
          <dd>{inspectionFee && formatDollarsAndCents(inspectionFee)}</dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{effectiveDate ? formatTimezoneDateString(effectiveDate, "MMMM d, yyyy") : "Effective date not set"}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          <dd>
            {expirationDate ? formatTimezoneDateString(expirationDate, "MMMM d, yyyy") : "Expiration date not set"}
          </dd>
        </dl>
        <dl>
          <dt>Date Created</dt>
          <dd>{formatTimezoneDateString(createdAt, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Date Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};
