import { UUID } from "@cp/toolkit";
import * as Popover from "@radix-ui/react-popover";
import * as Select from "@radix-ui/react-select";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import { ContainerHeader } from "@/components/layout";
import { Bar, BarLink } from "@/components/ui/bar";
import { Calendar } from "@/components/ui/calendar";
import { Icon } from "@/components/ui/icon";
import { PopoverContent } from "@/components/ui/popover";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useOpportunity, useUpdateOpportunity } from "@/hooks/useOpportunity";
import { StateIndicator } from "@/metadata";
import { cn } from "@/utils";
import { useBrokerAccountsQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { MarketingPlanDropdown } from "./marketing-plan-dropdown";
import RulesForm from "./rules-form";

export const MarketingPlanHeader = () => {
  const { toast } = useToast();
  const { opportunity } = useOpportunity();

  const [updateOpportunity] = useUpdateOpportunity({
    onCompleted: () => {
      toast({ title: "Success", description: "Opportunity updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const { data: user } = useMyAccount();

  const { data: brokers } = useBrokerAccountsQuery({
    skip: !user?.internal,
    fetchPolicy: "cache-first",
  });

  if (!opportunity) {
    return null;
  }

  const now = new Date();
  const { id, broker, insured, desiredEffectiveDate, selectedLinesOfBusiness, state, verticalMarketingPlanTemplate } =
    opportunity;

  const SelectEffectiveDate = ({ children }: { children: React.ReactNode }) => (
    <Popover.Root>
      <Trigger popover={true}>{children}</Trigger>
      <PopoverContent className="p-0 w-auto">
        <Calendar
          mode="single"
          selected={desiredEffectiveDate}
          onSelect={(e) =>
            updateOpportunity({
              variables: {
                input: { id, desiredEffectiveDate: e },
              },
            })
          }
          initialFocus
        />
      </PopoverContent>
    </Popover.Root>
  );

  const SelectBroker = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={broker ? broker.id : "-"}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: { id: id, brokerId: e === "-" ? null : (e as UUID) },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          <SelectItem value="-">Unassigned</SelectItem>
          {brokers?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  return (
    <ContainerHeader className="block px-0">
      <Bar className="gap-2 shadow-none">
        <div className="flex-auto gap-2 grid grid-flow-col auto-cols-fr h-16 items-center">
          <HeaderCell
            className="p-0"
            title={selectedLinesOfBusiness?.join(", ") || "No Coverage Selected"}
            subtitle={<StateIndicator state={state} />}
          />
          <SelectEffectiveDate>
            <HeaderCell
              title={formatTimezoneDateString(desiredEffectiveDate, "MMMM d, yyyy")}
              subtitle="Eff. Date"
              className={cn(
                !desiredEffectiveDate && "text-muted-foreground",
                now.toISOString() > desiredEffectiveDate && "text-destructive"
              )}
            />
          </SelectEffectiveDate>
          <HeaderCell title={`${insured.agent.firstName} ${insured.agent.lastName}`} subtitle={insured.agency.name} />
          <SelectBroker>
            <HeaderCell
              title={broker ? `${broker.firstName} ${broker.lastName}` : "Unassigned"}
              subtitle="QuoteWell"
              className={cn(broker === null && "text-destructive")}
            />
          </SelectBroker>
        </div>
        <MarketingPlanDropdown />
      </Bar>
      <HasInternalRole>
        <Bar className="border-t flex gap-2 items-center justify-start shadow-none">
          <BarLink to=".">Details</BarLink>
          <BarLink to="coverage">Coverage</BarLink>
          <BarLink to="files">Files</BarLink>
          <BarLink to="history">History</BarLink>
          <BarLink to="data">Data</BarLink>
          <BarLink to="notes">Notes</BarLink>
        </Bar>
      </HasInternalRole>
      <HasInternalRole>
        {verticalMarketingPlanTemplate ? <RulesForm opportunity={opportunity} /> : null}
      </HasInternalRole>
    </ContainerHeader>
  );
};

export const Trigger = ({ children, popover = false }: { children: React.ReactNode; popover?: boolean }) => {
  const Component = popover ? Popover.Trigger : Select.Trigger;

  return (
    <Component className="group bg-accent flex items-center justify-between outline-none rounded shadow-contrast">
      {children}
      <Icon
        icon="keyboard_arrow_down"
        className="hidden @2xl/opp:inline mr-3 opacity-50 group-hover:opacity-100 transition-opacity"
      />
    </Component>
  );
};

export const HeaderCell = ({
  className,
  title,
  subtitle,
}: {
  className?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => (
  <div className={cn("flex flex-col gap-0.5 overflow-hidden px-3 py-1.5 relative rounded text-left", className)}>
    <h5 className="text-2xs @lg/opp:text-xs truncate">
      <span className="truncate">{title}</span>
    </h5>
    <h6 className="text-muted-foreground truncate">{subtitle}</h6>
  </div>
);
