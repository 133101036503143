import { LoaderFunctionArgs, Outlet, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Loading } from "@/components/ui/loading";
import { useTypedLoaderData } from "@/utils/router.utils";
import { InsuredDocument, InsuredQuery, InsuredQueryVariables } from "src/generated/graphql";
import { apolloClient } from "src/graphql/client";
import { Nav } from "./nav/nav";

export const InsuredRoot: React.FC = () => {
  const navigate = useNavigate();
  const { insured, loading } = useTypedLoaderData<typeof insuredLoader>();

  if (loading) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    );
  }

  if (!insured) {
    return null;
  }

  if (insured.mergedIntoId) {
    navigate(`/insured/${insured.mergedIntoId}`);
  }

  useDocumentTitle(`${insured?.name}: QuoteWell`);

  return (
    <div className="flex flex-auto gap-3">
      <Nav insuredId={insured.id} />
      <Outlet />
    </div>
  );
};

export const insuredLoader = async ({ params }: LoaderFunctionArgs) =>
  apolloClient
    .query<InsuredQuery, InsuredQueryVariables>({
      query: InsuredDocument,
      variables: {
        id: params.insuredId ?? "",
      },
    })
    .then((v) => ({ ...v.data, loading: v.loading }));
