import { HasInternalRole } from "@/components/has-role";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { QuoteFragment, QuoteState } from "src/generated/graphql";
import { NoActions } from "../../../components/no-actions";
import { BindRequestReceived } from "./bind-request-received";
import { Bound } from "./bound";
import { Delivered } from "./delivered";
import { NotTaken } from "./not-taken";
import { Processed } from "./processed";

export const QuoteActions: React.FC<{ quote: QuoteFragment }> = ({ quote }) => {
  const renderQuoteState = () => {
    switch (quote.state) {
      case QuoteState.Processed:
        return <Processed quote={quote} />;
      case QuoteState.Delivered:
        return <Delivered quote={quote} />;
      case QuoteState.BindRequestReceived:
        return <BindRequestReceived quote={quote} />;
      case QuoteState.NotTaken:
        return <NotTaken quote={quote} />;
      case QuoteState.Bound:
        return <Bound quote={quote} />;
      default:
        return <NoActions />;
    }
  };

  return (
    <HasInternalRole>
      <Card>
        <CardHeader>
          <CardTitle>Actions</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-2">{renderQuoteState()}</CardContent>
      </Card>
    </HasInternalRole>
  );
};
