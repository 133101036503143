import React, { useState } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { HasInternalRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { Input as FormInput } from "@/forms/default";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import {
  File_Audience,
  SubmissionFragment,
  SubmissionStatePendingActions,
  useSendSubmissionToCarrierMutation,
  useTransitionSubmissionMutation,
} from "src/generated/graphql";
import { uploadFiles } from "src/utils/file.utils";
import { CarrierContacts } from "./carrier-contacts/carrier-contacts";
import { EmailTemplatesButton } from "./email-draft";

export const Pending = ({ submission }: { submission: SubmissionFragment }) => {
  const [transition] = useTransitionSubmissionMutation();
  const { toast } = useToast();
  const { openConfirmation, openForm } = useModal();
  const [sendSubmission, { loading, called }] = useSendSubmissionToCarrierMutation();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  // const handleEmailClick = () => {
  //   setIsEmailModalOpen(true);
  // };

  const handleSendEmail = (emailComment: string, fileIds: string[]) => {
    void sendSubmission({
      variables: {
        input: {
          submissionId: submission.id,
          emailComment: emailComment,
          fileIds,
        },
      },
      onCompleted: () => {
        toast({ title: "Email sent" });
      },
      onError: () => {
        toast({ title: "Error sending email" });
      },
      refetchQueries: ["OpportunityKitchenSink"],
    });
  };

  const handleTransition = async (action: SubmissionStatePendingActions, includeDetails: boolean = false) => {
    const isConfirmed = await (includeDetails
      ? openForm(
          z.object({ details: z.string() }),
          <>
            <h3>
              Confirm{" "}
              {SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[SubmissionStatePendingActions.RuleOut]}
            </h3>
            <p>
              {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
            </p>
            <div className="my-2">
              <FormInput name="details" placeholder="Reason" />
            </div>
            <div className=" mt-2">
              <Button size="sm">Rule Out</Button>
            </div>
          </>
        )
      : openConfirmation({
          title: `Confirm ${SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[action] ?? action}`,
          description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
        }));
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          submissionId: submission.id,
          action,
          details: typeof isConfirmed === "object" ? isConfirmed.details : undefined,
        },
      },
      refetchQueries: ["OpportunityKitchenSink"],
    });
  };

  const portalLink = submission.appetiteProduct.portalLink ?? undefined;

  return (
    <HasInternalRole>
      {/* <Button onClick={handleEmailClick} variant="outline" size="sm" disabled={loading || called}>
            Submit to Carrier
            {loading && <Spinner />}
          </Button> */}
      <Button
        onClick={() => handleTransition(SubmissionStatePendingActions.Submit)}
        variant="outline"
        size="sm"
        disabled={loading || called}
      >
        Mark Submitted
        {loading && <Spinner />}
      </Button>
      <EmailTemplatesButton submission={submission} />
      <CarrierContacts submission={submission} />
      {portalLink && (
        <a href={portalLink} target="_blank" rel="noopener noreferrer">
          <Button variant="outline" size="sm">
            Carrier Portal
          </Button>
        </a>
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => handleTransition(SubmissionStatePendingActions.RuleOut, true)}
            variant="ghost"
            size="icon"
          >
            <Icon icon="close" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Rule Out</TooltipContent>
      </Tooltip>
      <EmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSend={handleSendEmail}
        initialBody={""}
      />
    </HasInternalRole>
  );
};

interface FileInfo {
  id: string;
  name: string;
}

const EmailModal = ({
  isOpen,
  onClose,
  onSend,
  initialBody,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSend: (emailComment: string, files: string[]) => void;
  initialBody: string;
}) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [emailComment, setEmailComment] = useState(initialBody);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.type !== "application/pdf") {
        toast({ title: "We only accept PDF files" });
        return;
      }
      if (!opportunityId) {
        toast({ title: "Error" });
        return;
      }
      try {
        setUploadingFile(true);
        const id = await uploadFiles([file], insuredId || "", File_Audience.Internal, "MANUAL_UPLOAD");
        if (id) {
          setFiles([...files, { id, name: file.name }]);
          setUploadingFile(false);
        }
      } catch {
        toast({ title: "Error uploading file", description: "Please try again" });
      }
    }
  };

  const handleRemoveFile = (idToRemove: string) => {
    setFiles(files.filter((file) => file.id !== idToRemove));
  };

  const handleSend = () => {
    onSend(
      emailComment,
      files.map((file) => file.id)
    );
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Carrier Submission - Email Details</DialogTitle>
        </DialogHeader>
        <div className="gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email-body" className="col-span-4">
              Email Body
            </Label>
            <Textarea
              id="email-body"
              value={emailComment}
              onChange={(e) => setEmailComment(e.target.value)}
              className="col-span-4"
              rows={10}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            {uploadingFile ? (
              <Spinner />
            ) : (
              <>
                <div className="col-span-4">Attach Files (PDF only)</div>
                <Input id="" type="file" onChange={handleFileChange} className="col-span-4" accept=".pdf" />{" "}
              </>
            )}
          </div>
          <div className="col-span-4">
            <Label>Attached Files:</Label>
            <ul className="mt-2 space-y-2">
              {files.map((file) => (
                <li key={file.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                  <span>{file.name}</span>
                  <Button variant="ghost" size="icon-sm" onClick={() => handleRemoveFile(file.id)}>
                    <Icon icon="close" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSend}>Send Email</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
