import { ActionFunctionArgs, Link, useNavigate } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import { reloadOpportunity, useOpportunity } from "@/hooks/useOpportunity";
import {
  DeleteOpportunityDocument,
  DeleteOpportunityMutation,
  DeleteOpportunityMutationVariables,
  Roles,
  useDeleteOpportunityMutation,
  useReassignOpportunityMutation,
} from "src/generated/graphql";
import { apolloClient } from "src/graphql/client";
import { CreateEmail } from "./create-email";

export const opportunityActions = async ({ request, params }: ActionFunctionArgs) => {
  switch (request.method) {
    case "delete":
    case "DELETE":
      return deleteOpportunityAction({ request, params });
    default:
      return null;
  }
};

export const deleteOpportunityAction = async ({ params }: ActionFunctionArgs) => {
  const { opportunityId } = params;

  if (!opportunityId) {
    return;
  }

  const comment = await apolloClient.mutate<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>({
    mutation: DeleteOpportunityDocument,
    variables: {
      id: opportunityId,
    },
  });

  return comment;
};

export const MarketingPlanDropdown: React.FC = () => {
  const { data: user } = useMyAccount();
  const { opportunity } = useOpportunity();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [deleteOpportunity] = useDeleteOpportunityMutation();

  if (!opportunity) {
    return null;
  }

  const allowReassignment =
    user?.roles.includes(Roles.Dj) || user?.roles.includes(Roles.Admin) || opportunity.broker === null;

  const [reassignOpportunity] = useReassignOpportunityMutation({
    onCompleted: () => {
      toast({ title: "Success" });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const handleDelete = async () => {
    await deleteOpportunity({
      variables: {
        id: opportunity.id,
      },
      onCompleted: () => {
        toast({ title: "Marketing Plan Deleted" });
        navigate(`/insured/${opportunity.insuredId}`);
      },
      onError: () => {
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
      refetchQueries: [...reloadOpportunity.refetchQueries, "InsuredOpportunities"],
    });
  };

  const hasQuotes = opportunity.submissions.some((sub) => sub.quotes.length > 0);

  return (
    <AlertDialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="text-base">
            <Icon icon="more_vert" className="font-bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={async () => {
              const link = `${window.location.origin}/insured/${opportunity.insuredId}/${opportunity.id}`;
              await navigator.clipboard.writeText(link);
              toast({ title: "Copied Link to Clipboard", description: link });
            }}
          >
            <Icon icon="link" /> Copy Link to Clipboard
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.open(window.location.href, "_blank")}>
            <Icon icon="tab_duplicate" /> Open in New Tab
          </DropdownMenuItem>
          <HasInternalRole>
            <DropdownMenuSeparator />
            <CreateEmail opportunityId={opportunity.id} />
            <DropdownMenuSeparator />
            <Link to="escape-hatch">
              <DropdownMenuItem>
                <Icon icon="eject" />
                Binding Authority Escape Hatch
              </DropdownMenuItem>
            </Link>
            <DropdownMenuItem
              disabled={!allowReassignment}
              onClick={() => {
                void reassignOpportunity({
                  variables: {
                    id: opportunity.id,
                  },
                });
              }}
            >
              <Icon icon="account_circle" /> Reassign Marketing Plan
            </DropdownMenuItem>
            {!hasQuotes && (
              <AlertDialogTrigger asChild>
                <DropdownMenuItem className="text-destructive">
                  <Icon icon="delete" /> Delete Marketing Plan
                </DropdownMenuItem>
              </AlertDialogTrigger>
            )}
          </HasInternalRole>
        </DropdownMenuContent>
      </DropdownMenu>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Marketing Plan</AlertDialogTitle>
          <AlertDialogDescription>Are you sure?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} variant="destructive">
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
