import { first } from "lodash";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/forms/default";
import { useCreateTagMutation, useFileTagsQuery } from "src/generated/graphql";
import { TagMenu } from "../files/tag-menu";

export const Tags = () => {
  const { openForm } = useModal();
  const { toast } = useToast();

  const { data: tags, refetch } = useFileTagsQuery({
    variables: { input: {} },
  });

  const [createTag] = useCreateTagMutation({
    onError: (e) => {
      toast({ title: e.message });
    },
    onCompleted: () => {
      toast({ title: `label created` });
      void refetch();
    },
    refetchQueries: ["FileTags"],
  });

  return (
    <>
      <CardHeader className="bg-accent border-b">
        <CardTitle>
          Tags
          <Button
            variant="outline"
            size="sm"
            onClick={async () => {
              const formData = await openForm(
                z.object({
                  label: z.string(),
                }),
                <div>
                  <div className="grid  gap-2">
                    <div className="flex flex-col">
                      <h4>Label</h4>
                      <Input name="label" />
                    </div>
                  </div>
                  <div className="flex justify-end m-4">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>,
                {
                  defaultValues: {
                    label: "",
                  },
                }
              );

              if (formData) {
                void createTag({
                  variables: {
                    input: {
                      label: formData.label,
                    },
                  },
                });
              }
            }}
          >
            Add
          </Button>
        </CardTitle>
      </CardHeader>
      <div className="p-4">
        {tags?.fileTags
          .filter((t) => t?.parentTags?.length === 0)
          .map((t) => (
            <TagsRow key={t.label} label={t.label} depth={0} tags={tags.fileTags} />
          ))}
      </div>
    </>
  );
};

const TagsRow = ({
  label,
  depth,
  tags,
}: {
  label: string;
  depth: number;
  tags: NonNullable<ReturnType<typeof useFileTagsQuery>["data"]>["fileTags"];
}) => {
  const { data: tagData, refetch } = useFileTagsQuery({
    variables: {
      input: {
        label,
      },
    },
  });
  const tag = first(tagData?.fileTags);
  const paddingLeft = `${depth * 1}rem`;

  if (!tag) {
    return null;
  }

  return (
    <div style={{ paddingLeft }} className="my-2 text-sm">
      <div className="flex gap-3 items-center">
        <Icon icon="folder" />
        <span>{tag?.label}</span>
        <TagMenu allTags={tags} tag={tagData?.fileTags[0]} refetch={refetch} />
      </div>
      {tag.label !== "Acords" &&
        tag?.childTags?.map((t) => <TagsRow key={tag.label} label={t?.label} depth={depth + 1} tags={tags} />)}
    </div>
  );
};
