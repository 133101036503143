import { useState } from "react";

import { StateIndicator, States } from "@/metadata";
import { cn } from "@/utils";

export const BankanColumn = ({
  children,
  id,
  count,
}: {
  children: React.ReactNode;
  id: States;
  count: number | undefined;
}) => {
  const [expanded, setExpanded] = useState(true);

  if (count === 0) {
    return null;
  }

  return (
    <div className={cn("flex flex-col md:flex-none md:w-80 z-0", !expanded && "md:w-12")}>
      {/* desktop button */}
      <button
        className={cn(
          buttonClassName,
          "max-md:hidden",
          !expanded &&
            "md:bg-transparent md:bg-gradient-to-b md:from-background md:to-transparent md:flex-col md:justify-start md:py-[1.125rem] md:h-80 md:w-12"
        )}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <StateIndicator state={id} displayLabel={expanded} />
        <span className={badgeClassName}>{count}</span>
      </button>
      {/* mobile button */}
      <button className={cn(buttonClassName, "md:hidden")} onClick={() => setExpanded((expanded) => !expanded)}>
        <StateIndicator state={id} />
        <span className={badgeClassName}>{count}</span>
      </button>
      {expanded && (
        <div className="bg-gradient-to-b from-background to-transparent flex-auto overflow-auto p-4 pt-0 rounded-b space-y-4">
          {children}
        </div>
      )}
    </div>
  );
};

const buttonClassName = "bg-background flex flex-none gap-4 h-12 items-center justify-between px-4 rounded-t text-xs";
const badgeClassName = "bg-foreground/[0.03] flex flex-none gap items-center px-[0.5em] py-[0.125em] rounded text-2xs";
