import { useDocumentTitle } from "usehooks-ts";

import { useMyAccount } from "@/auth/useMyAccount";
import { Cell, RowHeader } from "@/components/grid-table";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { MarketingPlanState, useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredRow } from "./insured-row";
import { PinnedInsureds } from "./pinned-insureds";

export const InsuredsBroker: React.FC = () => {
  const { data: user } = useMyAccount();

  const { data: { groupedMarketingPlans: { groupedMarketingPlans = [] } = {} } = {}, loading } =
    useGroupedMarketingPlansQuery({
      variables: {
        input: {
          brokerId: user?.id,
          states: [MarketingPlanState.InformationReview, MarketingPlanState.Marketing],
        },
      },
      fetchPolicy: "cache-and-network",
    });

  useDocumentTitle("Your Insureds: QuoteWell");

  if (!user || !groupedMarketingPlans) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-auto flex-col gap-3 lg:flex-row-reverse">
      <PinnedInsureds />
      <div className="flex-auto">
        {groupedMarketingPlans[0] && (
          <Card>
            <CardHeader className="bg-accent">
              <CardTitle>Your Insureds</CardTitle>
            </CardHeader>
            <TableHeader />
            {groupedMarketingPlans.map((insured) => (
              <InsuredRow key={insured.insuredId} insured={insured} />
            ))}
          </Card>
        )}
      </div>
    </div>
  );
};

const TableHeader = () => (
  <RowHeader className="grid-cols-[1rem_repeat(auto-fit,_minmax(1px,_1fr))]">
    <Cell />
    <Cell>Insured</Cell>
    <Cell>Lines</Cell>
    <Cell>Status / Eff. Date</Cell>
    <Cell>Broker</Cell>
  </RowHeader>
);
