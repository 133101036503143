import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { usePolicyQuery } from "src/generated/graphql";
import { PolicyActions } from "./actions/policy-actions";
import { PolicyFiles } from "./policy-files";
import { PolicyInfo } from "./policy-info";

export const Policy = () => {
  const { policyId } = useParams<"policyId">();

  const { data: { policy } = {} } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
    fetchPolicy: "cache-and-network",
  });

  if (!policy) {
    return null;
  }

  return (
    <div className="flex gap-3">
      <div className="flex-auto space-y-3">
        <AgentMessage state={policy.state} />
        <PolicyInfo policy={policy} />
        <Activity opportunityId={policy.opportunityId} />
      </div>
      <div className="flex-none space-y-3 w-80">
        <PolicyFiles policy={policy} />
        <PolicyActions policy={policy} />
      </div>
    </div>
  );
};
