import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { QuoteFragment } from "src/generated/graphql";

export const Bound = ({ quote }: { quote: QuoteFragment }) => (
  <>
    {quote.policy && (
      <Button variant="outline" size="sm" asChild>
        <Link to={`../../policies/${quote.policy.id}`}>Go to Policy</Link>
      </Button>
    )}
  </>
);
