import { Navigate } from "react-router";

import { useOpportunity } from "@/hooks/useOpportunity";

export const InsuredRedirect = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return <Navigate to="/" />;
  }

  const { id, insuredId } = opportunity;

  return <Navigate to={`/insured/${insuredId}/${id}`} />;
};
