import { groupBy, mapValues, sortBy } from "lodash";
import { useParams } from "react-router";

import { Card } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { ActionExecutionOperation, useActionsQuery } from "src/generated/graphql";
import { FollowUpsTable } from "./follow-ups-table";

export const FollowUps = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: { actions = [] } = {}, loading } = useActionsQuery({
    variables: {
      input: {
        opportunityId: opportunityId!,
        autoExecute: true,
      },
    },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Loading className="p-4" />;
  }

  const groups = mapValues(
    groupBy(actions, (action) => action.actionType),
    (group) =>
      sortBy(group, [
        (action) =>
          // If not executed, sort by scheduled date; if executed, sort by executed date
          action.executedOperation === ActionExecutionOperation.None ? action.autoExecuteAt : action.executedAt,
      ])
  );

  const tables = Object.entries(groups);

  if (tables.length === 0) {
    return null;
  }

  return (
    <Card className="overflow-hidden">
      {tables.map(([actionType, actions]) => (
        <FollowUpsTable key={actionType} actions={actions} opportunityId={opportunityId!} />
      ))}
    </Card>
  );
};
