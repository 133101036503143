import { Breadcrumbs } from "@/components/breadcrumbs";
import { Container, ContainerHeader } from "@/components/layout";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Loading } from "@/components/ui/loading";
import { useParams } from "react-router-dom";
import { useGmailThreadsQuery } from "src/generated/graphql";

export const InsuredEmail: React.FC = () => {
  const { insuredId } = useParams();
  const { data, loading } = useGmailThreadsQuery({
    variables: {
      input: {
        insuredId,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !insuredId,
  });

  return (
    <Container>
      <ContainerHeader>
        <Breadcrumbs />
      </ContainerHeader>
      <Card className="overflow-hidden">
        {loading ? (
          <Loading className="p-4" />
        ) : (
          data?.gmailThreads.map((thread) => (
            <CollapsibleList key={thread.id}>
              <CollapsibleListTrigger>{thread.gmailMessages[0].subject}</CollapsibleListTrigger>
              <CollapsibleListContent className="bg-muted p-4 space-y-4">
                {thread.gmailMessages.map((message) => (
                  <Card key={message.id}>
                    <CardHeader className="gap-1 text-xs">
                      <div className="flex gap-4">
                        <span className="flex-none font-semibold w-14">From</span>
                        <span>{message.from}</span>
                      </div>
                      <div className="flex gap-4">
                        <span className="flex-none font-semibold w-14">To</span>
                        <span>{message.to}</span>
                      </div>
                      <div className="flex gap-4">
                        <span className="flex-none font-semibold w-14">Subject</span>
                        <span className="truncate">{message.subject}</span>
                      </div>
                    </CardHeader>
                    <CardContent dangerouslySetInnerHTML={{ __html: message.html }} className="border-t pt-4 text-sm" />
                  </Card>
                ))}
              </CollapsibleListContent>
            </CollapsibleList>
          ))
        )}
      </Card>
    </Container>
  );
};
