import {
  BankanPolicyColumn,
  BankanQuoteColumn,
  MarketingPlanBankanColumn,
  MarketingPlanState,
  PolicyState,
  QuoteState,
  Roles,
  SubmissionState,
} from "src/generated/graphql";
import { CardDescription, CardTitle } from "./components/ui/card";
import { Icon, IconString } from "./components/ui/icon";
import { cn } from "./utils";

export enum InsuredState {
  NoMarketingPlans = "NoMarketingPlans",
  NoAssignedMarketingPlans = "NoAssignedMarketingPlans",
}

export enum ExpiredPolicyState {
  Expired = "Expired",
}

export const ROLE_METADATA: Record<Roles, { label: string }> = {
  [Roles.Admin]: {
    label: "Admin",
  },
  [Roles.Agent]: {
    label: "Agent",
  },
  [Roles.BindingAuthority]: {
    label: "Binding Authority",
  },
  [Roles.Brokerage]: {
    label: "Brokerage",
  },
  [Roles.Broker]: {
    label: "Broker",
  },
  [Roles.Dj]: {
    label: "DJ",
  },
  [Roles.PreferredBroker]: {
    label: "Preferred Broker",
  },
  [Roles.UnderwritingAssistant]: {
    label: "STS",
  },
};

export enum DisplaySubmissionStateMarketBlockedActions {
  Revert = "Revert to Previous State",
}

export enum DisplaySubmissionStatePendingActions {
  RuleOut = "Rule Out Submission",
  Submit = "Submit for Review",
}

export enum DisplaySubmissionStateReferredActions {
  BlockMarket = "Block Market",
  Decline = "Decline Submission",
  Quote = "Provide Quote",
  RuleOut = "Rule Out Submission",
}

export enum DisplaySubmissionStateRuledOutActions {
  Revert = "Revert to Previous State",
}

export enum DisplaySubmissionStateSubmittedActions {
  BlockMarket = "Block Market",
  Decline = "Decline Submission",
  Quote = "Provide Quote",
  Refer = "Refer to Another Party",
  RuleOut = "Rule Out Submission",
}

export enum DisplayQuoteStateBindRequestReceivedActions {
  Bind = "Bind Quote",
  Invalidate = "Invalidate Quote",
}

export enum DisplayQuoteStateDeliveredActions {
  Invalidate = "Invalidate Quote",
  ReceiveBindRequest = "Received Bind Request",
  ReceiveNotTaken = "Receive Not Taken Notice",
}

export enum DisplayQuoteStateNotTakenActions {
  Invalidate = "Invalidate Quote",
  ReceiveBindRequest = "Receive Bind Request",
}

export enum DisplayQuoteStatePendingActions {
  Invalidate = "Invalidate Quote",
  Process = "Process Quote",
}

export enum DisplayQuoteStateProcessedActions {
  Deliver = "Deliver Quote",
  Invalidate = "Invalidate Quote",
}

export enum DisplayPolicyStateAwaitingFromCarrierActions {
  Receive = "Receive Policy from Carrier",
}

export enum DisplayPolicyStateIssuedActions {
  Cancel = "Cancel Policy",
}

export enum DisplayPolicyStateReceivedFromCarrierActions {
  Issue = "Issue Policy to Client",
}

interface StateMetadata {
  label: string;
  className: string;
  cardClassName: string;
  icon: IconString;
  agentMessage?: React.ReactNode;
  actionDisplayNames?: Record<string, string>;
}

export type States =
  | InsuredState
  | MarketingPlanState
  | MarketingPlanBankanColumn
  | SubmissionState
  | QuoteState
  | BankanQuoteColumn
  | PolicyState
  | ExpiredPolicyState
  | BankanPolicyColumn;

export const INSURED_STATE_METADATA: Record<InsuredState, StateMetadata> = {
  [InsuredState.NoMarketingPlans]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "shield",
    label: "No Marketing Plans",
  },
  [InsuredState.NoAssignedMarketingPlans]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "error",
    label: "No Assigned Marketing Plans",
  },
};

export const MARKETING_PLAN_COLUMN_METADATA: Record<MarketingPlanBankanColumn, StateMetadata> = {
  [MarketingPlanBankanColumn.WaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "shield",
    label: "Waiting for Coverages",
  },
  [MarketingPlanBankanColumn.InformationGathering]: {
    className: "text-orange-600",
    cardClassName: "border-orange-500",
    icon: "info",
    label: "Information Gathering",
  },
  [MarketingPlanBankanColumn.InformationReview]: {
    className: "text-orange-600",
    cardClassName: "border-orange-500",
    icon: "info",
    label: "Broker Review",
  },
  [MarketingPlanBankanColumn.MarketingInfoRequired]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    label: "Marketing: Information Required",
  },
  [MarketingPlanBankanColumn.MarketingReady]: {
    className: "filled text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "campaign",
    label: "Marketing: Ready",
  },
  [MarketingPlanBankanColumn.UnassignedInformationGathering]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "psychology_alt",
    label: "Unassigned: Information Gathering",
  },
  [MarketingPlanBankanColumn.UnassignedWaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "psychology_alt",
    label: "Unassigned: Waiting For Coverages",
  },
  [MarketingPlanBankanColumn.UnassignedOther]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "psychology_alt",
    label: "Unassigned: Other",
  },
};

export const MARKETING_PLAN_STATE_METADATA: Record<MarketingPlanState, StateMetadata> = {
  [MarketingPlanState.WaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "shield",
    label: "Waiting for Coverage",
    agentMessage: (
      <>
        <CardTitle>No coverage selected.</CardTitle>
        <CardDescription>Please select the coverage required by the Insured.</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.InformationGathering]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    label: "Information Required",
    agentMessage: (
      <>
        <CardTitle>We need some more information.</CardTitle>
        <CardDescription>Please upload the required information we need to go to market.</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.InformationReview]: {
    className: "text-orange-600",
    cardClassName: "border-orange-500",
    icon: "info",
    label: "Broker Review",
    agentMessage: (
      <>
        <CardTitle>Your broker is reviewing your submission now.</CardTitle>
        <CardDescription>We&apos;ll let you know if we need anything else to get started on quotes!</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.Marketing]: {
    className: "filled text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "campaign",
    label: "Marketing",
    agentMessage: (
      <>
        <CardTitle>We&apos;re out to market!</CardTitle>
        <CardDescription>
          Your broker is working on getting quotes. Check back here for updates as we get results.
        </CardDescription>
      </>
    ),
  },
  [MarketingPlanState.Complete]: {
    className: "filled text-purple-600",
    cardClassName: "border-purple-500",
    icon: "verified",
    label: "Marketing Complete",
    agentMessage: (
      <>
        <CardTitle>Thank you for giving QuoteWell a chance to win your business!</CardTitle>
        <CardDescription>
          Your broker has completed marketing for this plan. Check the <strong>Quotes</strong> and{" "}
          <strong>Marketing Plans</strong> sections for applicable quotes and alternatives.
        </CardDescription>
      </>
    ),
  },
};

export const SUBMISSION_STATE_METADATA: Record<SubmissionState, StateMetadata> = {
  [SubmissionState.Pending]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "shield",
    label: "Pending",
    actionDisplayNames: DisplaySubmissionStatePendingActions,
  },
  [SubmissionState.Submitted]: {
    className: "filled text-orange-600",
    cardClassName: "border-orange-500",
    icon: "campaign",
    label: "Submitted",
    actionDisplayNames: DisplaySubmissionStateSubmittedActions,
  },
  [SubmissionState.MarketBlocked]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "front_hand",
    label: "Blocked",
    actionDisplayNames: DisplaySubmissionStateMarketBlockedActions,
  },
  [SubmissionState.Declined]: {
    className: "text-red-600",
    cardClassName: "border-red-500",
    icon: "thumb_down",
    label: "Declined",
    actionDisplayNames: {}, // No actions for Declined state
  },
  [SubmissionState.Referred]: {
    className: "filled text-orange-600",
    cardClassName: "border-orange-500",
    icon: "send",
    label: "Referred",
    actionDisplayNames: DisplaySubmissionStateReferredActions,
  },
  [SubmissionState.RuledOut]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "close",
    label: "Ruled Out",
    actionDisplayNames: DisplaySubmissionStateRuledOutActions,
  },
  [SubmissionState.Quoted]: {
    className: "filled text-purple-600",
    cardClassName: "border-purple-500",
    icon: "verified",
    label: "Quoted",
    actionDisplayNames: {}, // No specific actions for Quoted state
  },
  [SubmissionState.Revert]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "thumb_up",
    label: "Reverted",
    actionDisplayNames: {}, // No specific actions for Revert state
  },
};

export const QUOTE_STATE_METADATA: Record<QuoteState, StateMetadata> = {
  [QuoteState.Invalid]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "close",
    label: "Invalid",
    actionDisplayNames: {}, // No specific actions for Invalid state
  },
  [QuoteState.Pending]: {
    className: "filled text-orange-600",
    cardClassName: "border-orange-500",
    icon: "warning",
    label: "Pending",
    actionDisplayNames: DisplayQuoteStatePendingActions,
  },
  [QuoteState.Processed]: {
    className: "filled text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "send",
    label: "Ready to Send",
    actionDisplayNames: DisplayQuoteStateProcessedActions,
  },
  [QuoteState.Delivered]: {
    className: "filled text-blue-600",
    cardClassName: "border-blue-500",
    icon: "mail",
    label: "Delivered",
    agentMessage: (
      <>
        <CardTitle>Thank you for the chance to earn your business!</CardTitle>
        <CardDescription>
          Please email your broker directly with bind orders when you are ready to proceed.
        </CardDescription>
      </>
    ),
    actionDisplayNames: DisplayQuoteStateDeliveredActions,
  },
  [QuoteState.BindRequestReceived]: {
    className: "text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "thumb_up",
    label: "Bind Request Received",
    agentMessage: (
      <>
        <CardTitle>Thank you for the bind order!</CardTitle>
        <CardDescription>We are currently processing it and will follow up with next steps shortly.</CardDescription>
      </>
    ),
    actionDisplayNames: DisplayQuoteStateBindRequestReceivedActions,
  },
  [QuoteState.Bound]: {
    className: "filled text-purple-600",
    cardClassName: "border-purple-500",
    icon: "verified",
    label: "Bound",
    agentMessage: (
      <>
        <CardTitle>Thank you for binding with QuoteWell!</CardTitle>
        <CardDescription>Please check the policies section for policy details.</CardDescription>
      </>
    ),
    actionDisplayNames: {}, // No specific actions for Bound state
  },
  [QuoteState.NotTaken]: {
    className: "text-red-600",
    cardClassName: "border-red-500",
    icon: "thumb_down",
    label: "Not Taken",
    actionDisplayNames: DisplayQuoteStateNotTakenActions,
  },
};

export const QUOTE_COLUMN_METADATA: Record<BankanQuoteColumn, StateMetadata> = {
  [BankanQuoteColumn.ReadyToSend]: {
    className: "filled text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "send",
    label: "Ready to Send",
    actionDisplayNames: DisplayQuoteStateProcessedActions,
  },
  [BankanQuoteColumn.Delivered]: {
    className: "filled text-blue-600",
    cardClassName: "border-blue-500",
    icon: "mail",
    label: "Delivered",
    actionDisplayNames: DisplayQuoteStateDeliveredActions,
  },
  [BankanQuoteColumn.BindRequestReceived]: {
    className: "text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "thumb_up",
    label: "Bind Request Received",
    actionDisplayNames: DisplayQuoteStateBindRequestReceivedActions,
  },
};

export const POLICY_STATE_METADATA: Record<PolicyState, StateMetadata> = {
  [PolicyState.AwaitingFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "border-orange-500",
    icon: "hourglass_empty",
    label: "Awaiting Carrier",
    agentMessage: (
      <>
        <CardTitle>Policy Coming Soon!</CardTitle>
        <CardDescription>
          Your bind order has been sent to the carrier, we&apos;ll update you as soon as we have updates.
        </CardDescription>
      </>
    ),
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [PolicyState.ReceivedFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "receipt_long",
    label: "Received from Carrier",
    agentMessage: (
      <>
        <CardTitle>We&apos;re working on the final touches!</CardTitle>
        <CardDescription>Your broker is finalizing the policy and will have email it shortly.</CardDescription>
      </>
    ),
    actionDisplayNames: DisplayPolicyStateReceivedFromCarrierActions,
  },
  [PolicyState.Issued]: {
    className: "filled text-purple-600",
    cardClassName: "border-purple-500",
    icon: "verified",
    label: "Issued",
    agentMessage: (
      <>
        <CardTitle>Thank you for binding with QuoteWell!</CardTitle>
        <CardDescription>
          Your policy is available below. Please email your broker directly for endorsements, cancellations, or other
          needs.
        </CardDescription>
      </>
    ),
    actionDisplayNames: DisplayPolicyStateIssuedActions,
  },
  [PolicyState.Canceled]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "cancel",
    label: "Cancelled",
    actionDisplayNames: {}, // No specific actions for Canceled state
  },
};

export const EXPIRED_POLICY_STATE_METADATA: Record<ExpiredPolicyState, StateMetadata> = {
  [ExpiredPolicyState.Expired]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    label: "Expired",
    agentMessage: (
      <>
        <CardTitle>This policy has expired.</CardTitle>
        <CardDescription>Please email your broker directly for a renewal.</CardDescription>
      </>
    ),
    actionDisplayNames: {},
  },
};

export const POLICY_COLUMN_METADATA: Record<BankanPolicyColumn, StateMetadata> = {
  [BankanPolicyColumn.AwaitingCarrier]: {
    className: "text-orange-600",
    cardClassName: "border-orange-500",
    icon: "hourglass_empty",
    label: "Awaiting Carrier",
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [BankanPolicyColumn.ReceivedFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "border-emerald-500",
    icon: "receipt_long",
    label: "Received from Carrier",
    actionDisplayNames: DisplayPolicyStateReceivedFromCarrierActions,
  },
  [BankanPolicyColumn.IssuedEndorsementsRequested]: {
    className: "filled text-purple-600",
    cardClassName: "border-purple-500",
    icon: "verified",
    label: "Issued - Endorsements Requested",
    actionDisplayNames: DisplayPolicyStateIssuedActions,
  },
  [BankanPolicyColumn.ExpiringSoon]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    label: "Expiring Soon",
    actionDisplayNames: {}, // No specific actions for Canceled state
  },
};

export const STATE_METADATA: Record<States, StateMetadata> = {
  ...INSURED_STATE_METADATA,
  ...MARKETING_PLAN_STATE_METADATA,
  ...MARKETING_PLAN_COLUMN_METADATA,
  ...SUBMISSION_STATE_METADATA,
  ...QUOTE_STATE_METADATA,
  ...QUOTE_COLUMN_METADATA,
  ...POLICY_STATE_METADATA,
  ...EXPIRED_POLICY_STATE_METADATA,
  ...POLICY_COLUMN_METADATA,
};

export const StateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: States;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];

  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};

export const StateLabel = ({ state, className }: { state: States; className?: string }) => {
  const { className: stateClassName, label } = STATE_METADATA[state];
  return <span className={cn(stateClassName, className)}>{label}</span>;
};
