import { formatMoney } from "@cp/toolkit";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Input } from "@/forms/default";
import { useFieldError } from "@/forms/use-field-error";
import { RequirementElementProps } from "@/insured/requirements/requirement-elements/requirement-element-props";
import { cn } from "@/utils";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  InsuredHazardFragment,
  PremiumBasis,
  useCreateInsuredHazardMutation,
  useDeleteInsuredHazardMutation,
  useEditInsuredHazardMutation,
  useInsuredHazardsQuery,
  useInsuredPremiseLocationsQuery,
  useSearchBusinessClassesLazyQuery,
} from "src/generated/graphql";
import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "./editor";
import { HasInternalRole } from "./has-role";
import { useModal } from "./modal-provider";
import { Autocomplete } from "./ui/autocomplete";
import { Button } from "./ui/button";
import { Icon } from "./ui/icon";
import { Loading } from "./ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { useToast } from "./ui/use-toast";

const HazardWriteSchema = z.object({
  hazardNumber: z
    .string()
    .or(z.number())
    .transform((a) => Number.parseInt(a.toString())),
  locationId: z.string(),
  cgl: z.string().min(5),
  premiumBasis: z.enum(Object.values(PremiumBasis) as [string, ...string[]]),
  exposure: z.string().min(1),
  description: z.string().optional(),
});

export function ScheduleOfHazards({
  insuredId,
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: { insuredId: string } & RequirementElementProps) {
  const { openModal, openForm } = useModal();
  const { toast } = useToast();

  const { data, loading, refetch } = useInsuredHazardsQuery({ variables: { id: insuredId } });

  useEffect(() => {
    if (data?.hazards && data.hazards.length > 0) {
      onSubmissionRequirementsMet();
    } else {
      onSubmissionRequirementsNotMet("Add at least 1 hazard");
    }
  }, [data?.hazards.length]);

  const [createHazardTrigger] = useCreateInsuredHazardMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Hazard created" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error creating hazard: duplicate hazard number" });
      } else {
        toast({ title: "Error creating hazard" });
      }
    },
  });

  const [editHazardTrigger] = useEditInsuredHazardMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Hazard edited" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error editing hazard: duplicate hazard number" });
      } else {
        toast({ title: "Error editing hazard" });
      }
    },
  });

  const [deleteHazardTrigger] = useDeleteInsuredHazardMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Hazard deleted" });
    },
    onError() {
      toast({ title: "Error deleting hazard" });
    },
  });

  if (loading || !data || !data.hazards) {
    return <Loading />;
  }

  const hazards = data.hazards;

  const createHazard = async () => {
    const fd = await openForm(HazardWriteSchema, <WriteHazardForm title="Add Hazard" insuredId={insuredId} />);
    if (!fd) {
      return;
    }

    await createHazardTrigger({
      variables: {
        input: {
          ...fd,
          premiumBasis: fd.premiumBasis as PremiumBasis,
          insuredId,
        },
      },
    });
  };

  const editHazard = async (hazard: InsuredHazardFragment) => {
    const fd = await openForm(HazardWriteSchema, <WriteHazardForm title="Edit Hazard" insuredId={insuredId} />, {
      defaultValues: {
        hazardNumber: hazard.hazardNumber,
        locationId: hazard.locationId ?? undefined,
        cgl: hazard.cgl ?? undefined,
        premiumBasis: hazard.premiumBasis ?? undefined,
        exposure: hazard.exposure ?? undefined,
        description: hazard.description ?? undefined,
      },
    });
    if (!fd) {
      return;
    }

    await editHazardTrigger({
      variables: {
        input: {
          ...fd,
          premiumBasis: fd.premiumBasis as PremiumBasis,
          hazardId: hazard.id,
        },
      },
    });
  };
  return (
    <Editor>
      <EditorHeader
        title="Schedule of Hazards"
        columns={["Haz. #", "CGL", "Premium Basis", "Exposure", "Description", "", ""]}
      >
        <Button type="button" size="sm" variant="outline" onClick={createHazard}>
          <Icon icon="add" />
          <span>Hazard</span>
        </Button>
      </EditorHeader>
      {hazards.map((hazard) => (
        <EditorItem key={hazard.id}>
          <EditorRow>
            <div>
              <EditorCount>
                L{hazard.locationNumber} - H{hazard.hazardNumber}
              </EditorCount>
            </div>
            <div>{hazard.cgl}</div>
            <div>{hazard.premiumBasis}</div>
            <div>{hazard.exposure}</div>
            <div className="col-span-2 truncate">{hazard.description}</div>
            <div className="flex gap justify-end">
              <HasInternalRole>
                {hazard.indications.length > 0 ? (
                  <Button
                    type="button"
                    size="icon-sm"
                    variant="ghost"
                    onClick={() =>
                      openModal(() => (
                        <div className="grid grid-cols-2 gap-2">
                          {hazard.indications.map((indication) => (
                            <dl key={indication.carrierName}>
                              <div className="grid grid-cols-2 gap border-b">
                                <dt className="font-semibold">Carrier</dt>
                                <dd>{indication.carrierName}</dd>
                              </div>

                              <div className="grid grid-cols-2 gap  border-b">
                                <dt className="font-semibold">Estimated Rate</dt>
                                <dd>
                                  {indication.minRate} - {indication.maxRate}
                                </dd>
                              </div>

                              <div className="grid grid-cols-2 gap border-b">
                                <dt className="font-semibold">Estimated Premium</dt>
                                <dd>
                                  {formatMoney(indication.minPremium)} - {formatMoney(indication.maxPremium)}
                                </dd>
                              </div>
                            </dl>
                          ))}
                        </div>
                      ))
                    }
                  >
                    View Rates
                  </Button>
                ) : null}
              </HasInternalRole>
              <Button type="button" size="icon-sm" variant="ghost" onClick={() => editHazard(hazard)}>
                <Icon icon="edit" />
              </Button>
              <Button
                type="button"
                size="icon-sm"
                variant="ghost"
                onClick={() => deleteHazardTrigger({ variables: { id: hazard.id } })}
              >
                <Icon icon="delete" />
              </Button>
            </div>
          </EditorRow>
        </EditorItem>
      ))}
    </Editor>
  );
}

function WriteHazardForm({ insuredId, title }: { insuredId: string; title: string }) {
  const { data, loading } = useInsuredPremiseLocationsQuery({ variables: { id: insuredId } });
  const error = useFieldError("locationId");

  const { getValues, setValue } = useFormContext<z.infer<typeof HazardWriteSchema>>();
  const [cgl, setCgl] = useState<BusinessClassFragment>();
  const [loadingDefaultCgl, setLoadingDefaultCgl] = useState(true);
  const [load] = useSearchBusinessClassesLazyQuery();

  useEffect(() => {
    void (async () => {
      setLoadingDefaultCgl(true);
      const defaultCgl = getValues().cgl;
      if (!defaultCgl) {
        setLoadingDefaultCgl(false);
        return;
      }

      const res = load({
        variables: { input: { term: getValues().cgl, classSystems: [BusinessClassSystem.IsoGl] } },
      });
      setCgl((await res).data?.searchBusinessClasses[0]);
      setLoadingDefaultCgl(false);
    })();
  }, []);

  if (loading || !data?.locations) {
    return <Loading />;
  }

  const locations = data.locations;

  return (
    <div className="flex flex-col gap-4">
      <h3>{title}</h3>
      {locations.length === 0 && <h4 className="font-semibold text-yellow-600">WARNING: No locations</h4>}
      <div className="grid grid-cols-2 gap-2">
        <div>
          <h4 className={cn({ "text-red-500": !!error })}>Location{error ? `- ${error.message?.toString()}` : null}</h4>
          <Select defaultValue={getValues().locationId} onValueChange={(v) => setValue("locationId", v)} required>
            <SelectTrigger
              className={cn({
                "border-red-500": !!error,
              })}
            >
              <SelectValue placeholder="Set hazard location" />
            </SelectTrigger>
            <SelectContent>
              {locations.map((location) => (
                <SelectItem key={location.id} value={location.id}>
                  {location.locationNumber}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <h4>Hazard Number</h4>
          <Input type="number" name="hazardNumber" required />
        </div>

        <div className="flex flex-col">
          <h4>CGL</h4>
          {loadingDefaultCgl ? (
            <Loading />
          ) : (
            <Autocomplete
              options={(text) => {
                return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then(
                  (res) => [
                    { id: null, description: "None", system: BusinessClassSystem.IsoGl, code: "" },
                    ...(res.data?.searchBusinessClasses ?? []),
                  ]
                );
              }}
              side="right"
              selected={cgl}
              onSelect={(option) => {
                setCgl(option);
                setValue("cgl", option.code);
              }}
              toValue={(option) => businessClassToLabel(option)}
              toLabel={(option) => businessClassToLabel(option)}
              placeholder="Search CGL Classifications"
            />
          )}
        </div>

        <div className="flex flex-col">
          <h4>Premium Basis</h4>
          <Select defaultValue={getValues().premiumBasis} onValueChange={(v) => setValue("premiumBasis", v)} required>
            <SelectTrigger>
              <SelectValue placeholder="Set premium basis" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(PremiumBasis).map((pb) => (
                <SelectItem key={pb} value={pb}>
                  {pb}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <h4>Exposure</h4>
          <Input name="exposure" required />
        </div>

        <div className="flex flex-col">
          <h4>Description</h4>
          <Input name="description" required />
        </div>
      </div>

      <div className="flex justify-between">
        <Button type="reset" variant="destructive">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </div>
  );
}

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;
