import { groupBy, orderBy } from "lodash";
import { Outlet, useParams } from "react-router";

import { Breadcrumbs } from "@/components/breadcrumbs";
import { Cell, RowHeader, RowLink } from "@/components/grid-table";
import { Container, ContainerHeader } from "@/components/layout";
import { Avatar } from "@/components/ui/avatar";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { ExpiredPolicyState, StateIndicator } from "@/metadata";
import { PolicyState, useOpportunityQuery, usePoliciesQuery, usePolicyQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { PolicyDropdown } from "./policy-dropdown";

const now = Date.now();

export const Policies = () => {
  const { policyId } = useParams();

  const { data: { policy } = {} } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
    fetchPolicy: "cache-first",
  });

  const { data: { opportunity } = {} } = useOpportunityQuery({
    variables: {
      id: policy?.opportunityId ?? "",
    },
    skip: !policyId || !policy?.opportunityId,
    fetchPolicy: "cache-first",
  });

  return (
    <Container id="Policies">
      <ContainerHeader>
        <Breadcrumbs />
        {policyId && (
          <div className="flex gap-3 items-center ml-auto">
            {opportunity && <Avatar user={opportunity.broker} />}
            <PolicyDropdown />
          </div>
        )}
      </ContainerHeader>
      <Outlet />
    </Container>
  );
};

export const PoliciesIndex = () => {
  const { insuredId } = useParams();

  const { data: { policies } = { quotes: [] } } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const sortedPolicies = orderBy(policies, (policy) => Date.parse(policy.effectiveDate));
  const groupedPolicies = groupBy(sortedPolicies, (policy) => formatTimezoneDateString(policy.effectiveDate, "yyyy"));

  return (
    <div className="space-y-3">
      {Object.keys(groupedPolicies).map((date) => (
        <Card key={date} className="flex-auto">
          <CardHeader className="bg-accent">
            <CardTitle>{date}</CardTitle>
          </CardHeader>
          <RowHeader>
            <Cell>Carrier</Cell>
            <Cell>Product</Cell>
            <Cell>Status</Cell>
            <Cell>Eff. Date</Cell>
            <Cell>Exp. Date</Cell>
          </RowHeader>
          {groupedPolicies[date].map((policy) => {
            const { id, effectiveDate, expirationDate, state, appetiteProduct } = policy;
            const expired = Date.parse(expirationDate) < now;

            return (
              <RowLink key={id} to={id}>
                <Cell>{appetiteProduct.carrierName}</Cell>
                <Cell>{appetiteProduct.carrierProductName}</Cell>
                <Cell>
                  <StateIndicator state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state} />
                </Cell>
                <Cell>{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</Cell>
                <Cell>{formatTimezoneDateString(expirationDate, "MMM d, yyyy")}</Cell>
              </RowLink>
            );
          })}
        </Card>
      ))}
    </div>
  );
};
