import { FileButton } from "@/components/file-button";
import { HasInternalRole } from "@/components/has-role";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PolicyFragment, PolicyState } from "src/generated/graphql";

export const PolicyFiles = ({ policy }: { policy: PolicyFragment }) => {
  const { policyFile, binderFile, state } = policy;

  if (state !== PolicyState.Issued) {
    return null;
  }

  return (
    <HasInternalRole>
      <Card>
        <CardHeader>
          <CardTitle>Files</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          {policyFile && <FileButton file={policyFile} />}
          {binderFile && <FileButton file={binderFile} />}
        </CardContent>
      </Card>
    </HasInternalRole>
  );
};
