import { UUID } from "@cp/toolkit";
import { isEqual, parseISO } from "date-fns";
import { fromMarkdown } from "mdast-util-from-markdown";
import { ListItem } from "mdast-util-from-markdown/lib";
import { gfmFromMarkdown, gfmToMarkdown } from "mdast-util-gfm";
import { toMarkdown } from "mdast-util-to-markdown";
import { gfm } from "micromark-extension-gfm";
import React from "react";
import ReactMarkdown from "react-markdown";
import { LiProps } from "react-markdown/lib/ast-to-react";
import remarkGfm from "remark-gfm";
import { find } from "unist-util-find";

import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { CollapsibleCard } from "@/components/ui/collapsible-card";
import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { reloadOpportunity } from "@/hooks/useOpportunity";
import { cn, parseError } from "@/utils";
import {
  useDeleteCommentMutation,
  UserAccountFragment,
  UserCommentFragment,
  useUpdateCommentMutation,
} from "src/generated/graphql";
import { CommentForm } from "./comment-form";

interface UserCommentProps extends UserCommentFragment {
  user: UserAccountFragment;
}

export const UserComment: React.FC<UserCommentProps> = ({
  text,
  createdAt,
  updatedAt,
  userAccount,
  id,
  isPublic,
  user,
}) => {
  const { toast } = useToast();
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [editMode, setEditMode] = React.useState(false);

  const handleVisibilityToggle = () => {
    void updateComment({
      variables: {
        input: {
          id,
          isPublic: Boolean(!isPublic),
        },
      },
      ...reloadOpportunity,
    });
  };

  const handleDelete = () => {
    void deleteComment({
      variables: {
        id,
      },
      ...reloadOpportunity,
      onCompleted: () => {
        toast({ title: "Success" });
      },
      onError: () => {
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
    });
  };

  const ToggleEdit = () => {
    setEditMode(!editMode);
  };

  const createdDate = parseISO(createdAt);
  const editedDate = parseISO(updatedAt);

  const hasBeenEdited = !isEqual(createdDate, editedDate);

  return (
    <CollapsibleCard>
      <header className="flex gap-4 items-center p-4 text-xs">
        <Avatar user={userAccount} />
        <div className="flex-auto">
          <strong>
            {userAccount.firstName} {userAccount.lastName}
          </strong>
          {" commented "}
          <RelativeDate date={createdAt} className="text-muted-foreground" />
          {hasBeenEdited && <span className="text-foreground/20"> (edited)</span>}
        </div>
        {user.internal && (
          <div className="flex items-center gap-3 ml-auto">
            <div className="flex gap-3">
              <Button variant="ghost" size="icon-sm" onClick={ToggleEdit}>
                <Icon icon="edit" />
              </Button>
              <Button variant="ghost" size="icon-sm" onClick={handleDelete}>
                <Icon icon="delete" />
              </Button>
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon-sm" onClick={handleVisibilityToggle}>
                  <Icon icon={isPublic ? "visibility" : "visibility_off"} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{isPublic ? "Currently visible" : "Not visible"}</TooltipContent>
            </Tooltip>
          </div>
        )}
      </header>
      {editMode ? (
        <div className="mb-3 mx-3">
          <CommentForm opportunityId={id} commentId={id} text={text} setEditMode={setEditMode} />
        </div>
      ) : (
        <div className="ml-10 p-4 pt-0 space-y-2 text-xs" style={{ overflowWrap: "anywhere" }}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              li: (props) => {
                const { className, children } = props;

                // special className applied to task list items
                if (className === "task-list-item") {
                  return <Task {...props} text={text} id={id} />;
                }

                return <li {...props}>{children}</li>;
              },
              ul: (props) => {
                const { className, children } = props;

                // special className applied to task list
                if (className === "contains-task-list") {
                  return <ul style={{ all: "revert", listStyle: "none", paddingLeft: "0px" }} />;
                }

                return <ul className={cn("list-disc list-inside", className)}>{children}</ul>;
              },
              ol: (props) => {
                return <ul className="list-decimal list-inside" {...props} />;
              },
            }}
          >
            {text}
          </ReactMarkdown>
        </div>
      )}
    </CollapsibleCard>
  );
};

interface TaskProps extends LiProps {
  text: string;
  id: string;
}

const Task: React.FC<TaskProps> = ({ text, id, children, checked, node, ...props }) => {
  const [updateComment] = useUpdateCommentMutation();
  const { toast } = useToast();

  const handleChange = async () => {
    // parse the original markdown
    const parsed = fromMarkdown(text, { extensions: [gfm()], mdastExtensions: [gfmFromMarkdown()] });

    // find the current node in the mdast
    const found = find(parsed, { type: "listItem", position: { ...node.position } }) as ListItem;

    // update the node
    found.checked = checked ? false : true;

    // convert the mdast back to markdown
    const updatedMarkdown = toMarkdown(parsed, { extensions: [gfmToMarkdown()], bullet: "-" });

    void updateComment({
      variables: {
        input: {
          id: id as UUID,
          text: updatedMarkdown,
        },
      },
      ...reloadOpportunity,
      onCompleted: () => {
        toast({ title: "Comment updated" });
      },
      onError: (e) => {
        toast({ title: "Error", description: parseError(e.message), variant: "destructive" });
      },
    });
  };

  return (
    <li {...props}>
      <input type="checkbox" onChange={handleChange} checked={checked ?? false} />
      {children.splice(1)}
    </li>
  );
};
