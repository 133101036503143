import { Loading } from "@/components/ui/loading";
import { InsuredState } from "@/metadata";
import { useInsuredsQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { InsuredCard } from "./cards/insured-card";

export const InsuredColumn = ({ agencyId }: { agencyId?: string | null }) => {
  const { data: { insureds: orphanedInsureds } = {}, loading } = useInsuredsQuery({
    variables: {
      input: {
        agencyId,
        orphaned: true,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <BankanColumn id={InsuredState.NoMarketingPlans} count={orphanedInsureds?.length}>
      {loading ? <Loading /> : orphanedInsureds?.map((insured) => <InsuredCard key={insured.id} insured={insured} />)}
    </BankanColumn>
  );
};
