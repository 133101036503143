import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useParams } from "react-router";

import { Breadcrumbs } from "@/components/breadcrumbs";
import { HasInternalRole } from "@/components/has-role";
import { Container, ContainerHeader } from "@/components/layout";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import {
  InsuredFragment,
  Roles,
  useInsuredQuery,
  useUpdateInsuredMutation,
  useUserAccountsQuery,
} from "src/generated/graphql";
import { HeaderCell, Trigger } from "../marketing-plans/marketing-plan-header";
import { Classification } from "./classification";
import { InsuredDropdown } from "./insured-dropdown";
import { Summary } from "./summary";

export const InsuredDetails = () => {
  const { insuredId } = useParams();
  const { data: { insured } = {} } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  if (!insured) {
    return null;
  }

  const { agent } = insured;

  return (
    <Container id="InsuredDetails">
      <ContainerHeader>
        <Breadcrumbs className="mr-auto" />
        <SelectAgent insured={insured}>
          <HeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
        </SelectAgent>
        <div className="pl-2">
          <InsuredDropdown />
        </div>
      </ContainerHeader>
      <div className="space-y-3">
        <div className="gap-3 grid @2xl:grid-cols-2 items-start">
          <Summary insured={insured} />
          <Classification insured={insured} />
        </div>
      </div>
    </Container>
  );
};

const SelectAgent = ({ insured, children }: { insured: InsuredFragment; children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const { data: agents } = useUserAccountsQuery({
    variables: { input: { agencyId: insured?.agency.id, roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });
  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });
  const { id, agent } = insured;

  return (
    <HasInternalRole elseShow={children}>
      <Dialog open={open} onOpenChange={() => setOpen(!open)}>
        <Select
          value={agent.id}
          onValueChange={async (e) => {
            if (e === "agentAtAnotherAgency") {
              setOpen(true);
            } else {
              await updateInsured({
                variables: {
                  input: {
                    id,
                    agentId: e as UUID,
                  },
                },
              });
            }
          }}
        >
          <Trigger>{children}</Trigger>
          <SelectContent>
            {agents?.userAccounts.map((u) => (
              <SelectItem key={u.id} value={u.id}>
                {u.firstName} {u.lastName}
              </SelectItem>
            ))}
            <DialogTrigger asChild>
              <SelectItem key={"agentAtAnotherAgency"} value={"agentAtAnotherAgency"} className="font-bold">
                Agent at another agency
              </SelectItem>
            </DialogTrigger>
          </SelectContent>
        </Select>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Re-assign to Agent</DialogTitle>
          </DialogHeader>
          <AllAgentsAutocomplete onSelect={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    </HasInternalRole>
  );
};

export const AllAgentsAutocomplete = ({ onSelect }: { onSelect: () => void }) => {
  const { insuredId } = useParams();
  const { data: agents } = useUserAccountsQuery({
    variables: { input: { roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });
  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });

  if (!agents) {
    return null;
  }

  return (
    <Autocomplete
      options={agents.userAccounts}
      onSelect={async (agent) => {
        await updateInsured({
          variables: {
            input: {
              id: insuredId || "",
              agentId: agent.id as UUID,
            },
          },
        });

        onSelect();
      }}
      toValue={(agent) => `${agent.firstName} ${agent.lastName}`}
      toLabel={(agent) => `${agent.firstName} ${agent.lastName}`}
      placeholder="Search for an Agent"
    />
  );
};
