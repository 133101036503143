import { orderBy } from "lodash";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { parseError } from "@/utils";
import { ClassCodeFragment, useDeleteVerticalMutation, useVerticalsQuery } from "src/generated/graphql";

type CglDisplayType = "short" | "long";

export function Verticals() {
  useDocumentTitle("Admin: Verticals");
  const [cglDisplayType, setCglDisplayType] = useState<CglDisplayType>("short");
  const { data: { verticals = [] } = {} } = useVerticalsQuery();
  const navigate = useNavigate();

  const displayCgl = (cgl: ClassCodeFragment) => {
    switch (cglDisplayType) {
      case "long":
        return `${cgl.classCode} ${cgl.description}`;
      default:
        return cgl.classCode;
    }
  };

  return (
    <>
      <CardHeader className="bg-accent border-b">
        <CardTitle>
          Verticals
          <Button type="button" variant="outline" size="sm" asChild>
            <Link to="create">Create Vertical</Link>
          </Button>
        </CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>Vertical</TableHead>
            <TableHead>Description</TableHead>
            <TableHead className="text-center">CGL(s)</TableHead>
            <TableHead>{/* Column for edit/delete buttons */}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {orderBy(verticals, [(vertical) => !vertical.enabled, (vertical) => vertical.name.toLowerCase()]).map(
            (vertical) => (
              <TableRow
                key={vertical.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${vertical.id}`);
                }}
              >
                <TableCell>
                  <Badge variant={vertical.enabled ? "green" : "outline"}>{vertical.enabled ? "on" : "off"}</Badge>
                </TableCell>
                <TableCell>{vertical.name}</TableCell>
                <TableCell>{vertical.description}</TableCell>

                <TableCell className="flex justify-center items-center h-auto">
                  <Pills
                    items={vertical.isoCglCodes.map((cgl) => displayCgl(cgl))}
                    onClick={(e) => {
                      e.stopPropagation();
                      cglDisplayType === "short" ? setCglDisplayType("long") : setCglDisplayType("short");
                    }}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex flex-row justify-end">
                    <Button // Update Button
                      variant="ghost"
                      size="icon"
                      className="flex justify-start"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`${vertical.id}/edit`);
                      }}
                    >
                      <Icon icon="edit" />
                    </Button>
                    <DeleteVertical id={vertical.id} /> {/* Delete Button */}
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
}

export const DeleteVertical: React.FC<{ id: string }> = ({ id }) => {
  const [deleteVertical] = useDeleteVerticalMutation({
    onError: (error) =>
      toast({ title: "Error deleting vertical", description: parseError(error), variant: "destructive" }),
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Icon icon="delete" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
        </DialogHeader>
        <Button
          variant="destructive"
          onClick={() => deleteVertical({ variables: { id }, refetchQueries: ["Verticals"] })}
        >
          Delete
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const Pill: React.FC<{
  text: string;
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}> = ({ text, onClick, onDelete }) => {
  return (
    <Badge variant="outline" className="bg-slate-300" onClick={onClick}>
      <div className="flex flex-row justify-between gap-1">
        {text}
        {onDelete && (
          <div
            className="flex"
            onClick={(e) => {
              e.preventDefault();
              onDelete(text);
            }}
          >
            <Icon icon="close" />
          </div>
        )}
      </div>
    </Badge>
  );
};

export const Pills: React.FC<{
  items: string[];
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}> = ({ items, onClick, onDelete }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1 w-full h-auto">
      {items.map((item) => (
        <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
      ))}
    </div>
  );
};
