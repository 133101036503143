import { useState } from "react";

import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Loading, Spinner } from "@/components/ui/loading";
import { RelativeDate } from "@/components/ui/relative-date";
import { toast } from "@/components/ui/use-toast";
import { useOpportunity } from "@/hooks/useOpportunity";
import { useCreateOpportunityNoteMutation } from "src/generated/graphql";

export const Notes = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const { opportunity, refetch } = useOpportunity();
  const [createNote] = useCreateOpportunityNoteMutation();

  if (!opportunity) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader className="bg-accent border-b">
        <CardTitle>Notes</CardTitle>
      </CardHeader>
      <ul>
        {opportunity.notes.map((note) => (
          <li key={note.id} className="border-b">
            <header className="flex gap-4 items-center p-4 text-xs">
              <Avatar user={note.creator} />
              <div className="flex-auto">
                <strong>
                  {note.creator.firstName} {note.creator.lastName}
                </strong>
                {" created a note "}
                <RelativeDate date={note.createdAt} className="text-muted-foreground" />
              </div>
            </header>
            <div className="ml-10 p-4 pt-0 text-sm">{note.text}</div>
          </li>
        ))}
      </ul>
      <CardFooter className="flex gap-3 items-center p-4">
        <Input name="note" value={text} placeholder="Add a note" onChange={({ target }) => setText(target.value)} />
        <Button
          type="submit"
          variant="secondary"
          size="icon"
          className="h-8 text-primary w-8"
          onClick={async () => {
            setLoading(true);
            await createNote({
              variables: {
                input: {
                  opportunityId: opportunity.id,
                  text,
                },
              },
              onCompleted() {
                setLoading(false);
                return refetch();
              },
              onError: () => {
                setLoading(false);
                toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
              },
            });
          }}
        >
          {loading ? <Spinner /> : <Icon icon="send" />}
        </Button>
      </CardFooter>
    </Card>
  );
};
