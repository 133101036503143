import { Link, LinkProps } from "react-router-dom";

import { cn } from "@/utils";
import { Icon } from "./ui/icon";

const gridClassName = "auto-cols-fr gap-3 grid grid-flow-col items-center px-4 relative last:rounded-b-lg";
const rowClassName = "even:bg-accent/60 border-border/60 even:border-border/45 border-t box-content h-12 text-xs";

export const Row = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn(gridClassName, rowClassName, className)} {...rest}>
    {children}
  </div>
);

export const RowHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <header
    className={cn(
      gridClassName,
      "bg-accent font-semibold h-6 first:h-8 items-start first:items-center text-2xs text-muted-foreground",
      className
    )}
    {...rest}
  >
    {children}
  </header>
);

export const RowLink = ({ children, className, ...rest }: LinkProps) => (
  <Link className={cn(gridClassName, rowClassName, "group", className)} {...rest}>
    {children}
    <Cell
      className={cn(
        "flex items-center justify-center pointer-events-none rounded-full",
        "opacity-25 group-hover:opacity-100 transition-all",
        "absolute top bottom right-4 group-hover:right-3 m-auto h-4 w-4"
      )}
    >
      <Icon icon="arrow_forward" />
    </Cell>
  </Link>
);

export const Cell = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("truncate", className)} {...rest}>
    {children}
  </div>
);
