import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { AppetiteNoteFragment, useAppetiteNotesQuery } from "src/generated/graphql";

export function AppetiteNotes() {
  useDocumentTitle("Admin: Appetite Notes");
  const { data: { appetiteNotes = [] } = {} } = useAppetiteNotesQuery();

  return <AppetiteNotesTable appetiteNotes={appetiteNotes} />;
}

const AppetiteNotesTable: React.FC<{ appetiteNotes: AppetiteNoteFragment[] }> = ({ appetiteNotes }) => {
  const navigate = useNavigate();

  return (
    <>
      <CardHeader className="bg-accent border-b">
        <CardTitle>
          Appetite Notes
          <Button variant="outline" size="sm" asChild>
            <Link to="create">Create New Note</Link>
          </Button>
        </CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>CGL(s)</TableHead>
            <TableHead>State(s)</TableHead>
            <TableHead>Line(s) Of Business</TableHead>
            <TableHead>Note</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {appetiteNotes.map((note) => (
            <TableRow key={note.id}>
              <TableCell>
                <Pills items={note.isoCglCodes} />
              </TableCell>
              <TableCell>
                <Pills items={note.states} />
              </TableCell>
              <TableCell>
                <Pills items={note.lobs} />
              </TableCell>
              <TableCell>
                <span className="text-xs">{note.note}</span>
              </TableCell>
              <TableCell>
                <Button variant="ghost" size="icon" onClick={() => navigate(`${note.id}/edit`)}>
                  <Icon icon="edit" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export const Pills: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Badge variant="outline" className="bg-slate-300" key={item}>
          {item}
        </Badge>
      ))}
    </div>
  );
};
