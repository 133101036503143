import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { SelectAgency } from "../components/select/select-agency";

export const InsuredsListFilter = () => {
  const navigate = useNavigate();
  const { agencyId } = useParams();

  return (
    <div className="flex gap-3 items-center">
      {agencyId && (
        <Button variant="ghost" size="icon-sm" onClick={() => navigate("/insureds")}>
          <Icon icon="close" />
        </Button>
      )}
      <SelectAgency path="/insureds" />
    </div>
  );
};
