import { HasRole } from "@/components/has-role";
import { useTheme } from "@/components/theme-provider";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import { BankanMarketingPlanColumnQuery, Roles } from "src/generated/graphql";

type BankanScore =
  BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["marketingPlans"][number]["score"];

export const BankanScore = ({ bankanScore }: { bankanScore: BankanScore }) => {
  const { theme } = useTheme();

  if (!bankanScore) {
    return null;
  }

  const { affiliationScore, bindRatioScore, bindScore, effectiveDateScore, score, verticalScore } = bankanScore;

  const Score = () => (
    <div className="flex gap-0.5 text-[8px]">
      {Array.from({ length: score }).map((_, index) => (
        <span key={index}>{theme === "beef" ? "🍤" : "🌶️"}</span>
      ))}
    </div>
  );

  return (
    <HasRole roles={[Roles.Admin]} elseShow={<Score />}>
      <Tooltip>
        <TooltipTrigger>
          <Score />
        </TooltipTrigger>
        <TooltipContent align="end" side="bottom" sideOffset={16} asChild>
          <Card className="gap-x-4 gap-y-2 grid grid-cols-[1fr_1rem] p-4 text-xs w-64 z-30">
            <div>Effective Date +/- 7 days</div>
            <div className="text-right">
              <Check value={effectiveDateScore} />
            </div>
            <div>In a vertical</div>
            <div className="text-right">
              <Check value={verticalScore} />
            </div>
            <div>Affiliated Agency</div>
            <div className="text-right">
              <Check value={affiliationScore} />
            </div>
            <div>Less than 5 Agency Binds</div>
            <div className="text-right">
              <Check value={bindScore} />
            </div>
            <div>Agency Bind Ratio less than 25%</div>
            <div className="text-right">
              <Check value={bindRatioScore} />
            </div>
          </Card>
        </TooltipContent>
      </Tooltip>
    </HasRole>
  );
};

const Check = ({ value }: { value: number }) => (
  <Icon
    className={cn("filled", value ? "text-green-500" : "text-muted-foreground")}
    icon={value ? "circle" : "close"}
  />
);
