import { HasInternalRole } from "@/components/has-role";
import { StateIndicator } from "@/metadata";
import { QuoteFragment, QuoteState } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";
import { NavBlockLink, NavBlockLinkContainer, NavHeader, NavSection } from "./nav";

export const sentToAgent = (quote: QuoteFragment) =>
  quote.state === QuoteState.Delivered || quote.state === QuoteState.BindRequestReceived;

export const NavQuotes = ({ quotes }: { quotes: QuoteFragment[] }) => {
  const drafts = quotes.filter((quote) => quote.state === QuoteState.Pending || quote.state === QuoteState.Processed);
  const proposals = quotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      <HasInternalRole>
        {drafts[0] && (
          <NavSection>
            <NavHeader label="Quote Drafts" />
            <NavBlockLinkContainer>
              {drafts.map((quote) => {
                const { id: quoteId, submission, premium, state } = quote;
                const { appetiteProduct } = submission;
                const { carrierName, carrierProductName } = appetiteProduct;
                return (
                  <NavBlockLink key={quoteId} to={`quotes/${quoteId}`} className="block space-y-1">
                    <div className="font-semibold text-xs truncate">
                      {carrierName}: {carrierProductName}
                    </div>
                    <div className="flex justify-between text-2xs">
                      <StateIndicator state={state} />
                      <span>{premium && formatDollars(premium)}</span>
                    </div>
                  </NavBlockLink>
                );
              })}
            </NavBlockLinkContainer>
          </NavSection>
        )}
      </HasInternalRole>
      {proposals[0] && (
        <NavSection>
          <NavHeader label="Quotes" />
          <NavBlockLinkContainer>
            {proposals.map((quote) => {
              const { id: quoteId, submission, premium, state } = quote;
              const { appetiteProduct } = submission;
              const { carrierName, carrierProductName } = appetiteProduct;
              return (
                <NavBlockLink key={quoteId} to={`quotes/${quoteId}`} className="block space-y-2">
                  <div className="font-semibold text-xs truncate">
                    {carrierName}: {carrierProductName}
                  </div>
                  <div className="flex justify-between text-2xs">
                    <StateIndicator state={state} />
                    <span>{premium && formatDollars(premium)}</span>
                  </div>
                </NavBlockLink>
              );
            })}
          </NavBlockLinkContainer>
        </NavSection>
      )}
    </>
  );
};
