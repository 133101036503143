import { isDefined } from "@cp/toolkit";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

import { pinnedInsuredsAtom } from "@/atoms";
import { cn } from "@/utils";
import { InsuredFragment, usePaginatedInsuredsQuery } from "src/generated/graphql";
import { InsuredPin } from "./insured-pin";
import { buttonClassName } from "./sidebar";
import { Button } from "./ui/button";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Icon } from "./ui/icon";

export function Search() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(!open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <>
      <Button variant="outline" size="sm" className={cn(buttonClassName)} onClick={() => setOpen(true)}>
        <Icon icon="search" />
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogHeader className="hidden">
          <DialogTitle>Search</DialogTitle>
        </DialogHeader>
        <DialogContent className="bg-transparent border-0 p-0 shadow-none">
          <CommandK setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function CommandK({ setOpen }: { setOpen: (open: boolean) => void }) {
  const [pinnedInsuredIds] = useAtom(pinnedInsuredsAtom);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const term = useDebounce(searchTerm ?? "", 222);

  const { data: pinnedData } = usePaginatedInsuredsQuery({
    variables: {
      args: {
        ids: Object.keys(pinnedInsuredIds),
      },
      pagination: {},
    },
    fetchPolicy: "cache-first",
  });
  const { paginatedInsureds } = pinnedData ?? {};
  const pinnedInsureds = paginatedInsureds?.insureds ?? [];

  const { data, loading } = usePaginatedInsuredsQuery({
    variables: {
      args: {
        term,
      },
      pagination: { page },
    },
    fetchPolicy: "cache-and-network",
  });

  const { numPages = 1, numRecords, insureds = [] } = data?.paginatedInsureds ?? {};

  const selectInsured = (v: string) => {
    setOpen(false);
    navigate(`/insured/${v}`);
  };

  return (
    <Command shouldFilter={false} className="bg-transparent gap-1 overflow-visible">
      <CommandCard>
        <CommandInput
          placeholder="Search Insureds"
          value={searchTerm}
          onValueChange={(term) => {
            setSearchTerm(term);
            setPage(1);
          }}
        />
      </CommandCard>
      {(data || loading) && (
        <CommandCard>
          {loading ? (
            <CommandCardHeader>Loading...</CommandCardHeader>
          ) : (
            <CommandCardHeader>
              {isDefined(numRecords) && (
                <>
                  {numRecords} {numRecords === 1 ? `insured` : `insureds`}
                  {term && ` matched "${term}"`}
                </>
              )}
              {numPages > 1 && (
                <div className="flex items-center ml-auto">
                  <Button
                    className="text-base"
                    variant="ghost"
                    size="icon-xs"
                    disabled={page === 1 || page === 0}
                    onClick={() => setPage((page) => page - 1)}
                  >
                    <Icon icon="chevron_left" />
                  </Button>
                  <div className="flex-auto font-mono mx-2 text-center text-2xs">
                    {page ?? 1} <span className="text-foreground/20">/</span> {numPages}
                  </div>
                  <Button
                    className="text-base"
                    variant="ghost"
                    disabled={page === numPages}
                    size="icon-xs"
                    onClick={() => setPage((page) => (page === 0 ? 1 : page) + 1)}
                  >
                    <Icon icon="chevron_right" />
                  </Button>
                </div>
              )}
            </CommandCardHeader>
          )}
          {insureds.length > 0 && (
            <CommandList>
              <CommandGroup>
                {insureds.map((insured) => (
                  <CommandListItem key={insured.id} insured={insured} handleSelect={selectInsured} />
                ))}
              </CommandGroup>
            </CommandList>
          )}
        </CommandCard>
      )}
      <CommandCard>
        <CommandList>
          <CommandGroup>
            <CommandItem
              className="gap-4 h-12 pl-3 pr-10 py-2"
              onSelect={() => {
                setOpen(false);
                navigate("/new");
              }}
            >
              <div className="bg-primary flex h-6 items-center justify-center rounded-full text-background text-lg w-6">
                <Icon icon="add" />
              </div>
              <div className="text-sm">Create New Insured</div>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandCard>
      {pinnedInsureds.length > 0 && (
        <CommandCard>
          <CommandList>
            <CommandCardHeader>Pinned Insureds</CommandCardHeader>
            <CommandGroup>
              {pinnedInsureds.map((ins) => (
                <CommandListItem key={ins.id} insured={ins} pin handleSelect={selectInsured} />
              ))}
            </CommandGroup>
          </CommandList>
        </CommandCard>
      )}
    </Command>
  );
}

const CommandCard = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-background rounded-md shadow-xl transition-all">{children}</div>
);

const CommandCardHeader = ({ children }: { children: React.ReactNode }) => (
  <header className="border-b flex font-medium gap-4 mb-px py-2 px-4 text-muted-foreground text-2xs">{children}</header>
);

const CommandListItem = ({
  insured,
  handleSelect,
  pin = false,
}: {
  insured: InsuredFragment;
  handleSelect: (v: string) => void;
  pin?: boolean;
}) => {
  return (
    <div className="relative">
      <CommandItem
        value={pin ? `${insured.id}-pin` : insured.id}
        className="gap-4 h-8 pl-3 pr-10 text-xs"
        onSelect={(v) => handleSelect(v.replace("-pin", ""))}
      >
        <div className="flex flex-auto flex-col">
          <div className="text-xs">{insured.name}</div>
          <div className="flex items-center gap-1.5 text-muted-foreground text-2xs"></div>
        </div>
      </CommandItem>
      <InsuredPin id={insured.id} className="absolute top-0 bottom-0 right-1 my-auto" />
    </div>
  );
};
