import { Link } from "react-router-dom";

import { Cell, Row } from "@/components/grid-table";
import { InsuredPin } from "@/components/insured-pin";
import { StateIndicator } from "@/metadata";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

type BankanInsured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const InsuredRow = ({ insured: { insuredId, insuredName, marketingPlans } }: { insured: BankanInsured }) => (
  <Row key={insuredId} className="grid-cols-[1rem_repeat(auto-fit,_minmax(1px,_1fr))] h-auto items-start py-4">
    <InsuredPin id={insuredId} className="p-0 h-4 w-4" />
    <Cell className="flex gap-1 items-start truncate">
      <Link to={`/insured/${insuredId}`} key={insuredId} className="flex-auto font-semibold truncate">
        {insuredName}
      </Link>
    </Cell>
    <div className="col-span-3 space-y-4">
      {marketingPlans[0] ? (
        marketingPlans.map((opp) => (
          <Link to={`/insured/${insuredId}/${opp.id}`} key={opp.id} className="gap-3 grid grid-cols-3">
            <Cell className="space-y-1">
              {opp.selectedLinesOfBusiness[0] ? (
                opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
              ) : (
                <div>No lines of business</div>
              )}
            </Cell>
            <Cell className="space-y-1">
              <StateIndicator state={opp.state} />
              <div className="text-2xs">{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, YYY")}</div>
            </Cell>
            <Cell>{opp.broker ? `${opp.broker?.firstName} ${opp.broker?.lastName}` : "Unassigned"}</Cell>
          </Link>
        ))
      ) : (
        <div className="font-semibold">No Marketing Plans</div>
      )}
    </div>
  </Row>
);
