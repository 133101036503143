import { useLoginEmailPassword } from "@cp/auth";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";

import { OrDivider, OrEmailDisplay } from "@/components/or-email";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { Checkbox } from "@/forms/controlled-checkbox";
import { Input } from "@/forms/default";
import { useTracking } from "@/hooks/useTracking";
import { AuthCard } from "./AuthCard";

interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const Login: React.FC = () => {
  const navigate = useNavigate();
  // const { mutate: loginIdentityProvider } = useLoginIdentityProvider();
  const { mutate: loginEmailPassword, error, isLoading } = useLoginEmailPassword();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") ?? "/";

  const methods = useForm<LoginFormData>({
    defaultValues: { rememberMe: true },
  });
  const analytics = useTracking();
  const { handleSubmit } = methods;

  const onSubmitEmailPassword = (data: LoginFormData) => {
    loginEmailPassword(data, {
      onSuccess: (resp) => {
        const { user } = resp;
        void analytics.identify(user?.id, {
          // camel case for segment's known traits
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          // snake case for custom traits
          agency_id: user?.agency?.id,
          agency_name: user?.agency?.name,
        });
        navigate(redirect);
      },
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitEmailPassword)}>
          <AuthCard
            header="Sign In"
            subHeader="Welcome back! Please enter your credentials to access your account."
            error={error}
          >
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Input name="email" type="email" placeholder="Email" required={true} disabled={isLoading} />
                <Input name="password" type="password" placeholder="Password" required={true} disabled={isLoading} />
              </div>
              <div className="flex items-center justify-between tracking-tight">
                <Checkbox id="rememberMe" name="rememberMe" disabled={isLoading} labelClassName="text-xs">
                  Remember Me
                </Checkbox>
                <Link to="../forgot-password" className="font-semibold text-xs underline">
                  Forgot your password?
                </Link>
              </div>
            </CardContent>
            <CardFooter className="flex-col gap-2 items-stretch">
              <Button type="submit" disabled={isLoading}>
                Sign In
              </Button>
            </CardFooter>
          </AuthCard>
        </form>
      </FormProvider>
      <OrDivider />
      <OrEmailDisplay className="max-w-[320px]" />
    </>
  );
};
