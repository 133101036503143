import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { SubmissionFragment, useQuotesLazyQuery } from "src/generated/graphql";
import { uploadRedactedQuote } from "src/utils/file.utils";

export const UploadQuote = ({ submission, refetch }: { submission: SubmissionFragment; refetch: () => void }) => {
  const { insuredId } = useParams();
  const { toast } = useToast();
  const { id } = submission;
  const [submitting, setSubmitting] = useState(false);
  const [fetch] = useQuotesLazyQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button asChild variant="ghost" size="icon">
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  await uploadRedactedQuote(file, id).then((res) => {
                    if (res.success) {
                      toast({ title: "Redacted Quote Uploaded" });
                      refetch();
                      void fetch();
                    } else {
                      toast({ title: "Error" });
                    }
                  });
                }
                setSubmitting(false);
              }}
            />
            {submitting ? <Spinner /> : <Icon icon="upload" />}
          </label>
        </Button>
      </TooltipTrigger>
      <TooltipContent>Upload Quote</TooltipContent>
    </Tooltip>
  );
};
