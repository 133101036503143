import { Clippy } from "@/components/clippy";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Toggle } from "@/components/ui/toggle";
import { StateIndicator } from "@/metadata";
import { PolicyFragment, useUpdatePolicyMutation } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const PolicyInfo = ({ policy }: { policy: PolicyFragment }) => {
  const {
    id: policyId,
    policyNumber,
    awaitingSubjectivities,
    endorsementsRequested,
    state,
    stateTransitions,
    createdAt,
    effectiveDate,
    expirationDate,
    appetiteProduct,
  } = policy;

  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  const [mutation, { loading }] = useUpdatePolicyMutation();

  const handleAwaitingSubjectivitiesChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          awaitingSubjectivities: checked,
        },
      },
    });
  };

  const handleEndorsementsRequestedChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          endorsementsRequested: checked,
        },
      },
    });
  };

  return (
    <Card>
      <CardHeader className="bg-accent border-b">
        <CardTitle>
          {appetiteProduct.carrierName}
          <StateIndicator state={state} className="font-normal text-xs tracking-normal" />
        </CardTitle>
        <CardDescription>{appetiteProduct.carrierProductName}</CardDescription>
      </CardHeader>
      <CardContent className="gap-4 grid grid-cols-3 p-4">
        <dl>
          <dt>Policy Number</dt>
          <Clippy content={policyNumber} label={`#${policyNumber}`} />
        </dl>
        <dl>
          <dt>Awaiting Subjectivities</dt>
          <Toggle onPressedChange={handleAwaitingSubjectivitiesChange} disabled={loading}>
            {awaitingSubjectivities ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Endorsements Requested</dt>
          <Toggle onPressedChange={handleEndorsementsRequestedChange} disabled={loading}>
            {endorsementsRequested ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{formatTimezoneDateString(effectiveDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          <dd>{formatTimezoneDateString(expirationDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Last Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};
