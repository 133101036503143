import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { QuoteFragment, QuoteStateDeliveredActions, useTransitionQuoteMutation } from "src/generated/graphql";
import { QUOTE_STATE_METADATA } from "../../../metadata";

export const Delivered = ({ quote }: { quote: QuoteFragment }) => {
  const { openConfirmation } = useModal();
  const [transition] = useTransitionQuoteMutation();

  const handleTransition = async (action: QuoteStateDeliveredActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${QUOTE_STATE_METADATA[quote?.state].actionDisplayNames?.[action] ?? action}`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => handleTransition(QuoteStateDeliveredActions.ReceiveBindRequest)}
        variant="outline"
        size="sm"
      >
        Bind Request Received
      </Button>
      <Button onClick={() => handleTransition(QuoteStateDeliveredActions.ReceiveNotTaken)} variant="outline" size="sm">
        Quote Not Taken
      </Button>
      <Button onClick={() => handleTransition(QuoteStateDeliveredActions.Invalidate)} variant="outline" size="sm">
        Invalidate
      </Button>
    </>
  );
};
