import { useParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { useMyAccount } from "@/auth/useMyAccount";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { useAgencyQuery } from "src/generated/graphql";
import { InsuredsList } from "./insureds-list";
import { InsuredsRequests } from "./insureds-requests";
import { PinnedInsureds } from "./pinned-insureds";

export const InsuredsAgency: React.FC = () => {
  const { data: user } = useMyAccount();
  const { agencyId } = useParams();
  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId ?? "",
    },
    skip: !agencyId,
  });

  if (!user || !user.agency) {
    return null;
  }

  const agencyName = agency?.name ?? user.agency.name;

  useDocumentTitle(`${agencyName}: QuoteWell`);

  return (
    <div className="flex flex-auto flex-col gap-3 lg:flex-row-reverse">
      <div className="flex-none space-y-3 lg:w-80">
        <HasInternalRole>
          <AgencyAffiliations id={agencyId ?? ""} />
        </HasInternalRole>
        <InsuredsRequests id={agencyId ?? user.agencyId} />
        <PinnedInsureds />
      </div>
      <InsuredsList id={agencyId ?? user.agencyId} />
    </div>
  );
};
