import { groupBy, orderBy } from "lodash";
import { useParams } from "react-router";

import { Cell, RowHeader, RowLink } from "@/components/grid-table";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { StateIndicator } from "@/metadata";
import { useInsuredOpportunitiesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const MarketingPlansIndex = () => {
  const { insuredId } = useParams();

  const { data: { insuredOpportunities } = { insuredOpportunities: [] } } = useInsuredOpportunitiesQuery({
    variables: {
      id: insuredId ?? insuredId!,
    },
  });

  const sortedPlans = orderBy(insuredOpportunities, (plan) => Date.parse(plan.desiredEffectiveDate));
  const groupedPlans = groupBy(sortedPlans, (plan) => formatTimezoneDateString(plan.desiredEffectiveDate, "yyyy"));

  return (
    <div className="space-y-3">
      {Object.keys(groupedPlans).map((date) => (
        <Card key={date} className="flex-auto">
          <CardHeader className="bg-accent">
            <CardTitle>{date}</CardTitle>
          </CardHeader>
          <RowHeader>
            <Cell>Coverage</Cell>
            <Cell>Status</Cell>
            <Cell>Desired Eff. Date</Cell>
            <Cell>Broker</Cell>
          </RowHeader>
          {groupedPlans[date].map((plan) => {
            const { id, broker, desiredEffectiveDate, selectedLinesOfBusiness, state } = plan;
            return (
              <RowLink key={id} to={`../${id}`}>
                <Cell>{selectedLinesOfBusiness.join(", ")}</Cell>
                <Cell>
                  <StateIndicator state={state} />
                </Cell>
                <Cell>{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</Cell>
                <Cell>
                  {broker?.firstName} {broker?.lastName}
                </Cell>
              </RowLink>
            );
          })}
        </Card>
      ))}
    </div>
  );
};
