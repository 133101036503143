import { Link, LinkProps } from "react-router-dom";

import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";

export const BankanCard = ({ children, header }: { children: React.ReactNode; header: string }) => (
  <div className="truncate">
    <BankanCardHeader header={header} />
    <div className="bg-background -mt-2 rounded-lg">{children}</div>
  </div>
);

const BankanCardHeader = ({ header }: { header: string }) => (
  <header className="bg-accent font-semibold px-4 pt-3 pb-5 rounded-t-lg shadow-inner text-muted-foreground text-xs truncate">
    {header}
  </header>
);

export const BankanLink = ({ children, className, to, ...rest }: LinkProps & { cardClassName?: string }) => (
  <Link
    to={to}
    className={cn(
      "group bg-background border flex flex-col gap-2 -mt-px px-4 py-3 relative first:rounded-t-lg last:rounded-b-lg",
      className
    )}
    {...rest}
  >
    {children}
  </Link>
);

export const BankanLinkText = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("text-xs truncate", className)} {...rest}>
    {children}
  </div>
);

export const BankanLinkFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("flex gap-3 items-center justify-between text-2xs", className)} {...rest}>
    {children}
  </footer>
);

const now = Date.now();

export const DaysSince = ({ date }: { date: string }) => (
  <div className="bg-muted filled flex font-semibold gap-1 items-center -mr-1.5 -my-0.5 px-1.5 py-0.5 rounded-sm text-center text-2xs tracking-tight">
    <Icon icon="event" />
    {Math.round((now - Date.parse(date)) / 86_400_400)}
  </div>
);
