import { UUID } from "@cp/toolkit";
import { useNavigate, useParams } from "react-router";
import { PolicyState, useCreateRenewalMutation, usePolicyQuery } from "../../../generated/graphql";
import { HasInternalRole } from "../../components/has-role";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Icon } from "../../components/ui/icon";

export const PolicyDropdown: React.FC = () => {
  const { insuredId, policyId } = useParams();
  const navigate = useNavigate();
  const { data: { policy } = {} } = usePolicyQuery({ variables: { id: policyId as UUID }, skip: !policyId });
  const [createRenewal] = useCreateRenewalMutation();
  const allowRenewal = policy?.state === PolicyState.Issued;

  const handleCreateRenewal = async () => {
    void createRenewal({
      variables: { input: { policyId: policyId as UUID } },
      onCompleted: (data) => navigate(`/insured/${insuredId}/${data.createRenewal.id}`),
    });
  };

  return (
    <HasInternalRole>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="text-base">
            <Icon icon="more_vert" className="font-bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem disabled={!allowRenewal} onClick={handleCreateRenewal}>
            <Icon icon="cycle" className="mr-3" /> Create Renewal
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </HasInternalRole>
  );
};
