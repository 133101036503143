import { useMyAccount } from "@/auth/useMyAccount";
import { Cell, Row, RowHeader } from "@/components/grid-table";
import { SubmissionFragment, SubmissionState } from "src/generated/graphql";
import { Submission } from "./submission";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

export function Submissions({ submissions, refetch }: { submissions: SubmissionFragment[]; refetch: () => void }) {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const visibleSubmissions = submissions.filter(
    (submission) => submission.state !== SubmissionState.Pending || user.internal
  );

  if (visibleSubmissions.length === 0) {
    return <Row className="text-sm">Marketing Plan pending.</Row>;
  }

  return (
    <>
      <RowHeader className="grid-cols-6">
        <Cell className="col-span-2">Carrier / Product</Cell>
        <Cell>Status / Updated</Cell>
        <Cell className="col-span-3" />
      </RowHeader>
      {visibleSubmissions
        .sort((a, b) => {
          const aIndex = SUBMISSION_ORDER.indexOf(a.state);
          const bIndex = SUBMISSION_ORDER.indexOf(b.state);
          return aIndex - bIndex;
        })
        .map((submission) => (
          <Submission key={submission.id} submission={submission} refetch={refetch} />
        ))}
    </>
  );
}
