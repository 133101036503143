import React, { useCallback, useContext, useRef, useState } from "react";
import { DefaultValues } from "react-hook-form";
import { z, ZodRawShape } from "zod";

import { Reform } from "@/forms/reform";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

type RenderFn<T> = (close: (data?: T) => void) => React.ReactNode;
interface ModalContextData {
  open<T>(render: RenderFn<T>): Promise<T | undefined>;
  close<T>(data?: T): void;
}
const ModalContext = React.createContext<ModalContextData>({ open: () => Promise.resolve(undefined), close: () => {} });

const Modal: React.FC<{ onClose: () => void; isOpen: boolean; content?: React.ReactNode }> = ({
  onClose,
  content,
  isOpen,
}) => {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(shouldBeOpen) => {
        if (!shouldBeOpen) {
          onClose();
        }
      }}
    >
      <DialogContent className="max-h-[75dvh] overflow-y-auto">
        <DialogHeader className="hidden">
          <DialogTitle>Dialog</DialogTitle>
        </DialogHeader>
        {content}
      </DialogContent>
    </Dialog>
  );
};

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [{ isOpen, content }, setData] = useState<{
    isOpen: boolean;
    content?: React.ReactNode;
  }>({
    isOpen: false,
  });

  const resolver = useRef<(v?: unknown) => void>();

  const close = useCallback(function <T>(data?: T) {
    resolver.current?.(data);
    setData((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const open = useCallback(
    function <T>(fn: RenderFn<T>) {
      // Resolve any previous promises
      resolver.current?.();

      return new Promise<T | undefined>((resolve, reject) => {
        try {
          resolver.current = resolve as any;
          const content = fn(close);
          setData({ isOpen: true, content });
        } catch (error) {
          reject(error);
        }
      });
    },
    [close]
  );

  return (
    <ModalContext.Provider value={{ open, close }}>
      <Modal onClose={close} content={content} isOpen={isOpen} />
      {children}
    </ModalContext.Provider>
  );
};

interface OpenFormOptions<TSchema extends z.ZodObject<ZodRawShape>> {
  defaultValues?: DefaultValues<z.infer<TSchema>>;
}
export const useModal = () => {
  const { open, close } = useContext(ModalContext);

  const openForm = useCallback(
    function <TSchema extends z.ZodObject<ZodRawShape>>(
      schema: TSchema,
      formFields: React.ReactNode,
      { defaultValues }: OpenFormOptions<TSchema> = {}
    ) {
      return open<z.infer<TSchema>>((close) => (
        <Reform schema={schema} onSubmit={(_, v) => close(v)} onReset={() => close()} defaultValues={defaultValues}>
          {formFields}
        </Reform>
      ));
    },
    [open]
  );

  const openConfirmation = useCallback(
    (options?: {
      title?: string;
      variant?: Parameters<typeof Button>["0"]["variant"];
      description?: React.ReactNode;
    }) => {
      return open<boolean>((close) => (
        <>
          <h3>{options?.title ?? "Confirm"}</h3>
          <p>{options?.description ?? ""}</p>
          <div className="flex justify-between gap-2">
            <Button variant="outline" size="sm" onClick={() => close(false)}>
              Cancel
            </Button>
            <Button variant={options?.variant} size="sm" onClick={() => close(true)}>
              Confirm
            </Button>
          </div>
        </>
      ));
    },
    [open]
  );

  return {
    openModal: open,
    closeModal: close,
    openConfirmation,
    openForm,
  };
};
