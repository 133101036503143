import { KnownClientData } from "@qw/qw-common";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { Input as FieldInput } from "@/forms/default";
import {
  useAddVerticalMarketingPlanTemplateProductRequirementMutation,
  useDeleteVerticalMarketingPlanTemplateProductRequirementMutation,
  useUpdateVerticalMarketingPlanTemplateProductRequirementMutation,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";

export function EditVerticalRequirements({
  products,
  refetchVertical,
}: {
  products: VerticalMarketingPlanTemplateProductFragment[];
  refetchVertical: () => void;
}) {
  const { openForm, openConfirmation } = useModal();
  const { toast } = useToast();

  const [addVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useAddVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted() {
        void refetchVertical();
      },
      onError(e) {
        toast({
          title: e.message.includes("Unique")
            ? "Requirement with the same title already exists"
            : "Unable to create requirement",
        });
      },
    });
  const [updateVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted() {
        void refetchVertical();
      },
    });
  const [deleteVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useDeleteVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted() {
        void refetchVertical();
      },
    });

  const requirements = uniqBy(
    products.flatMap((product) => product.requirements),
    (requirement) => requirement.title
  );

  const addRequirement = async () => {
    const fd = await openForm(
      z.object({
        title: z.string(),
        description: z.string(),
        groups: z.string(),
      }),
      <RequirementEditor />
    );
    if (!fd) {
      return;
    }

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: fd.title,
          description: fd.description,
          groups: fd.groups,
        },
      },
    });
  };

  const addAcord125 = async () => {
    const groups = [
      {
        title: "Complete Basic Information",
        description: "",
        elements: [
          {
            element: "date",
            props: JSON.stringify({ label: "Effective Date" }),
            clientDataKey: KnownClientData.ProposedEffectiveDate,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing Address" }),
            clientDataKey: KnownClientData.MailingAddress.AddressLine1,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing City" }),
            clientDataKey: KnownClientData.MailingAddress.City,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing State" }),
            clientDataKey: KnownClientData.MailingAddress.State,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing Zip" }),
            clientDataKey: KnownClientData.MailingAddress.Zip,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Year Business Started" }),
            clientDataKey: KnownClientData.YearEstablished,
            array: false,
          },
          {
            element: "textarea",
            props: JSON.stringify({ label: "Description Of Operations" }),
            clientDataKey: KnownClientData.DescriptionOfOperations,
            array: false,
          },
          {
            element: "textarea",
            props: JSON.stringify({ label: "Prior Coverage Details" }),
            clientDataKey: KnownClientData.PriorCoverages,
            array: false,
          },
          {
            element: "checkbox",
            props: JSON.stringify({ label: "No prior losses in the last 5 years" }),
            clientDataKey: KnownClientData.LossHistory.NoLosses5y,
            array: false,
          },
        ],
      },
      {
        title: "Add Premises",
        description: "",
        elements: [
          {
            element: "building",
            props: JSON.stringify({ label: "Premises Information" }),
            clientDataKey: "Premises_Information",
            array: false,
          },
        ],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.BasicInfo,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 125",
          description: "",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const addAcord126 = async () => {
    const groups = [
      {
        title: "Select GL Coverage/Deductibles",
        description: "",
        elements: [
          {
            element: "select-with-money",
            props: JSON.stringify({
              label: "General Aggregate Limit",
              selectClientDataKey: KnownClientData.CoverageLimits.GeneralAggregateAppliesTo,
              items: [
                { value: "Policy Limit" },
                { value: "Project Limit" },
                { value: "Location Limit" },
                { value: "Other Limit" },
              ],
            }),
            clientDataKey: KnownClientData.CoverageLimits.GeneralAggregateValue,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Product and Completed Operations Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.ProductsAndCompletedOperationsAggregate,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Each Occurrence Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.EachOccurrence,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Personal & Advertising Injury Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.PersonalAndAdvertisingInjury,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Damage to Rented Premises Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.DamageToRentedPremises,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Property Damage Deductible" }),
            clientDataKey: KnownClientData.CoverageDeductibles.PropertyDamage,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Bodily Injury Deductible" }),
            clientDataKey: KnownClientData.CoverageDeductibles.BodilyInjury,
            array: false,
          },
          {
            element: "free-fill-with-money",
            props: JSON.stringify({
              label: "Other Deductible",
              selectClientDataKey: KnownClientData.CoverageDeductibles.OtherKey,
            }),
            clientDataKey: KnownClientData.CoverageDeductibles.OtherValue,
            array: false,
          },
        ],
      },

      {
        title: "Schedule of Hazards",
        description: "",
        elements: [{ element: "schedule-of-hazards", props: "{}", clientDataKey: "schedule-of-hazards", array: false }],
      },
      {
        title: "Additional Information",
        description: "",
        elements: [
          {
            element: "textarea",
            props: JSON.stringify({ label: "" }),
            clientDataKey: KnownClientData.GLAdditionalInformation,
            array: false,
          },
        ],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.GL,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 126",
          description: "",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const addAcord140 = async () => {
    const groups = [
      {
        title: "Basic Details",
        description: "",
        elements: [{ element: "property-details", props: "{}", clientDataKey: "", array: false }],
      },
      {
        title: "Subject of Insurance",
        description: "",
        elements: [{ element: "subject-of-insurance", props: "{}", clientDataKey: "", array: true }],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.Property,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 140",
          description: "",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const updateRequirement = async (originalTitle: string) => {
    const requirements = products
      .flatMap((product) => product.requirements)
      .filter((requirement) => requirement.title === originalTitle);

    const fd = await openForm(
      z.object({
        title: z.string(),
        description: z.string(),
        groups: z.string(),
      }),
      <RequirementEditor defaultGroups={requirements[0].groups} />,
      {
        defaultValues: {
          title: requirements[0].title,
          description: requirements[0].description ?? undefined,
        },
      }
    );
    if (!fd) {
      return;
    }

    const ids = requirements.map((requirement) => requirement.id);

    await updateVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          ids,
          title: fd.title,
          description: fd.description,
          groups: fd.groups,
        },
      },
    });
  };

  const deleteRequirement = async (originalTitle: string) => {
    const requirements = products
      .flatMap((product) => product.requirements)
      .filter((requirement) => requirement.title === originalTitle);

    const isConfirmed = await openConfirmation({ title: "Delete Requirement", variant: "destructive" });
    if (!isConfirmed) {
      return;
    }

    const ids = requirements.map((requirement) => requirement.id);

    await deleteVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          ids,
        },
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <span>Requirements</span>
          <div className="flex gap">
            <Button type="button" variant="outline" size="sm" onClick={addAcord125}>
              <Icon icon="add" />
              Acord 125
            </Button>
            <Button type="button" variant="outline" size="sm" onClick={addAcord126}>
              <Icon icon="add" />
              Acord 126
            </Button>
            <Button type="button" variant="outline" size="sm" onClick={addAcord140}>
              <Icon icon="add" />
              Acord 140
            </Button>
            <Button type="button" variant="outline" size="sm" onClick={addRequirement}>
              <Icon icon="add" />
              Custom
            </Button>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap">
        <Table>
          <TableHeader>
            <TableHead>Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead></TableHead>
          </TableHeader>
          <TableBody>
            {requirements.map((requirement) => (
              <TableRow key={requirement.id}>
                <TableCell>{requirement.title}</TableCell>
                <TableCell>{requirement.description}</TableCell>
                <TableCell>
                  <div className="flex gap">
                    <Button variant="ghost" size="icon-sm" onClick={() => updateRequirement(requirement.title)}>
                      <Icon icon="edit" />
                    </Button>
                    <Button variant="ghost" size="icon-sm" onClick={() => deleteRequirement(requirement.title)}>
                      <Icon icon="delete" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function RequirementEditor({ defaultGroups }: { defaultGroups?: string }) {
  const { setValue } = useFormContext();
  const [groups, setGroups] = useState<
    Array<{
      title: string;
      description: string;
      elements: Array<{ element: string; props: string; clientDataKey: string; array: boolean }>;
    }>
  >(defaultGroups ? JSON.parse(defaultGroups) : []);

  useEffect(() => {
    setValue("groups", JSON.stringify(groups));
  }, [groups]);

  return (
    <div className="flex flex-col gap">
      <Label>Requirement Title</Label>
      <FieldInput name="title" required />
      <Label>Requirement Description</Label>
      <FieldInput name="description" />
      {groups.map((group, index) => (
        <Card key={index}>
          <CardContent>
            <Label>Group Title</Label>
            <Input
              value={group.title}
              onChange={({ target }) =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      title: target.value,
                    };
                  })
                )
              }
            />
            <Label>Group Description</Label>
            <Input
              value={group.description}
              onChange={({ target }) =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      description: target.value,
                    };
                  })
                )
              }
            />

            {group.elements.map((element, elementIndex) => (
              <>
                <Label>Element Type</Label>
                <Select
                  key={elementIndex}
                  value={element.element}
                  onValueChange={(v) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              element: v,
                            };
                          }),
                        };
                      })
                    )
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="input">Input</SelectItem>
                    <SelectItem value="textarea">Text Area</SelectItem>
                    <SelectItem value="checkbox">Checkbox</SelectItem>
                  </SelectContent>
                </Select>

                <Label>Label</Label>
                <Input
                  value={JSON.parse(element.props).label}
                  onChange={({ target }) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              props: JSON.stringify({ label: target.value }),
                            };
                          }),
                        };
                      })
                    )
                  }
                />

                <Label>Client Data Key</Label>
                <Input
                  value={element.clientDataKey}
                  onChange={({ target }) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              clientDataKey: target.value,
                            };
                          }),
                        };
                      })
                    )
                  }
                />
              </>
            ))}
            <Button
              type="button"
              size="sm"
              className="mt-2"
              onClick={() =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      elements: [...g.elements, { element: "", props: "{}", clientDataKey: "", array: false }],
                    };
                  })
                )
              }
            >
              <Icon icon="add" />
              Add Element
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button
        type="button"
        size="sm"
        onClick={() => setGroups((groups) => [...groups, { title: "", description: "", elements: [] }])}
      >
        <Icon icon="add" />
        Add Group
      </Button>

      <Button>Save</Button>
    </div>
  );
}
