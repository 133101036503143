import { getAuthRequestHeaders } from "@cp/auth";
import { KnownClientData } from "@qw/qw-common";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { SetValuesFn } from "./create-insured-page";

export const useLoadFromDoc = (setValues?: SetValuesFn, setFile?: (file: File) => void) => {
  const [fileName, setFileName] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const onFileDropped = async (_acceptedFiles: File[]) => {
    setLoading(true);

    const rawAcord125 = new Uint8Array(await _acceptedFiles[0].arrayBuffer()).reduce(
      (acc, char) => acc + String.fromCharCode(char),
      ""
    );

    setFileName(_acceptedFiles[0].name);
    setFileUrl(`data:application/pdf;base64,${btoa(rawAcord125)}`);

    const fd = new FormData();
    fd.set("file", _acceptedFiles[0]);
    const res = await fetch("/api/file-processing/process-acord-125", {
      method: "POST",
      headers: getAuthRequestHeaders(),
      body: fd,
    });

    const json = await res.json();

    const map = new Map<string, string>(
      json.fields.map(({ key, value }: { key: string; value: string }) => [key, value])
    );

    if (setFile) {
      setFile(_acceptedFiles[0]);
    }

    setValues &&
      setValues([
        ["clientName", map.get(KnownClientData.FirstNamedInsured)],
        ["desiredEffectiveDate", map.get(KnownClientData.ProposedEffectiveDate)],
        ["state", map.get("Premises_Information:State")],
        ["revenue", map.get("Premises_Information:Annual_Revenues")],
        ["description", map.get(KnownClientData.DescriptionOfOperations)],
      ]);

    setLoading(false);
    setCompleted(true);
  };

  const { getRootProps, isDragActive } = useDropzone({ onDrop: onFileDropped });
  const { onClick, ...parentProps } = getRootProps();

  const reset = () => {
    setCompleted(false);
    setValues &&
      setValues([
        ["clientName", undefined],
        ["desiredEffectiveDate", undefined],
        ["state", undefined],
        ["description", undefined],
      ]);
  };

  return {
    dragging: isDragActive,
    loading,
    completed,
    fileName,
    fileUrl,
    reset,
    onClick,
    parentProps,
  };
};
