import { Outlet } from "react-router";

import { Container } from "@/components/layout";
import { MarketingPlanHeader } from "./marketing-plan-header";

export const MarketingPlan = () => (
  <Container id="MarketingPlan">
    <MarketingPlanHeader />
    <Outlet />
  </Container>
);
