import { Button } from "@/components/ui/button";
import { QuoteFragment, QuoteStatePassedActions, useTransitionQuoteMutation } from "src/generated/graphql";

export const NotTaken = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();

  const handleTransition = async (action: QuoteStatePassedActions) => {
    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button onClick={() => handleTransition(QuoteStatePassedActions.ReceiveBindRequest)} variant="outline" size="sm">
        Bind Request Received
      </Button>
      <Button onClick={() => handleTransition(QuoteStatePassedActions.Invalidate)} variant="outline" size="sm">
        Invalidate
      </Button>
    </>
  );
};
