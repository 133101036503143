import { NavLink as UnstyledNavLink, NavLinkProps } from "react-router-dom";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { Loading } from "@/components/ui/loading";
import { cn } from "@/utils";
import {
  MarketingPlanState,
  PolicyState,
  useInsuredOpportunitiesQuery,
  useInsuredWithDuplicateFlagQuery,
  usePoliciesQuery,
  useQuotesQuery,
} from "src/generated/graphql";
import { NavCreateMarketingPlan } from "./nav-create-marketing-plan";
import { NavInsured } from "./nav-insured";
import { NavMarketingPlans } from "./nav-marketing-plans";
import { NavPolicies } from "./nav-policies";
import { NavQuotes } from "./nav-quotes";

export const Nav = ({ insuredId }: { insuredId: string }) => {
  const { data: { insured } = {}, loading: insuredLoading } = useInsuredWithDuplicateFlagQuery({
    variables: {
      id: insuredId,
    },
  });

  const {
    data: { insuredOpportunities: opportunities } = { insuredOpportunities: [] },
    loading: opportunitiesLoading,
  } = useInsuredOpportunitiesQuery({
    variables: {
      id: insuredId,
    },
  });

  const { data: { quotes } = { quotes: [] }, loading: quotesLoading } = useQuotesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const { data: { policies } = { policies: [] }, loading: policiesLoading } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
        active: true,
      },
    },
  });

  if (!insured) {
    return null;
  }

  const activePlans = opportunities.filter((opp) => opp.state !== MarketingPlanState.Complete);
  const activePolicies = policies.filter((policy) => policy.state !== PolicyState.Canceled);

  return (
    <div className="flex-none space-y-3 w-72">
      {insuredLoading ? <Loading /> : <NavInsured insured={insured} />}
      {opportunitiesLoading ? (
        <Loading label="Loading Marketing Plans..." />
      ) : (
        <>
          <NavCreateMarketingPlan cgl={insured.isoCglCodes[0]} opportunities={opportunities} />
          {activePlans[0] && <NavMarketingPlans plans={activePlans} />}
        </>
      )}
      {quotesLoading ? <Loading label="Loading Quotes..." /> : <NavQuotes quotes={quotes} />}
      {policiesLoading ? (
        <Loading label="Loading Policies..." />
      ) : (
        activePolicies[0] && <NavPolicies policies={activePolicies} />
      )}
      <HasInternalRole>{insured.agency?.id && <AgencyAffiliations id={insured.agency?.id} />}</HasInternalRole>
    </div>
  );
};

export const NavSection = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("bg-border/30 border rounded-lg", className)} {...rest}>
    {children}
  </section>
);

export const NavHeader = ({ label }: { label: string }) => (
  <header className="flex h-8 items-center justify-between px-4 text-2xs text-muted-foreground">{label}</header>
);

export const navLinkClassName =
  "cursor-pointer flex h-6 items-center justify-between px-4 text-muted-foreground text-xs";

export const NavLink = ({ to, children, className, ...rest }: NavLinkProps) => (
  <UnstyledNavLink
    to={to}
    className={({ isActive, isPending }) =>
      cn(navLinkClassName, isPending && "opacity-75", isActive && "filled font-semibold text-foreground", className)
    }
    {...rest}
  >
    {children}
  </UnstyledNavLink>
);

export const NavBlockLinkContainer = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <nav
    className={cn(
      "bg-border -m-px rounded-lg shadow-sm space-y-px",
      "first:*:rounded-t-lg last:*:rounded-b-lg",
      className
    )}
    {...rest}
  >
    {children}
  </nav>
);

export const NavBlockLink = ({ to, children, className, ...rest }: NavLinkProps) => (
  <UnstyledNavLink
    to={to}
    className={({ isActive, isPending }) =>
      cn(
        "bg-accent block px-4 py-3 space-y-2 text-foreground/70 text-xs transition-colors",
        isPending && "text-foreground",
        isActive && "bg-background text-foreground",
        className
      )
    }
    {...rest}
  >
    {children}
  </UnstyledNavLink>
);
