import { useParams } from "react-router";

import { bankanTabAtom } from "@/atoms";
import { adaptSearchParams } from "@/components/adapt-search-params";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { useAtom } from "jotai";
import { BankanPolicyColumn, BankanQuoteColumn, MarketingPlanBankanColumn, Roles } from "src/generated/graphql";
import { InsuredColumn } from "./insured-column";
import { MarketingPlanColumn } from "./marketing-plan-column";
import { PolicyColumn } from "./policy-column";
import { QuoteColumn } from "./quote-column";

const planColumns = [
  MarketingPlanBankanColumn.WaitingForCoverages,
  MarketingPlanBankanColumn.InformationGathering,
  MarketingPlanBankanColumn.InformationReview,
  MarketingPlanBankanColumn.MarketingInfoRequired,
  MarketingPlanBankanColumn.MarketingReady,
];
const quoteColumns = [
  BankanQuoteColumn.ReadyToSend,
  BankanQuoteColumn.Delivered,
  BankanQuoteColumn.BindRequestReceived,
];
const policyColumns = [BankanPolicyColumn.AwaitingCarrier, BankanPolicyColumn.ReceivedFromCarrier];

const statesByRole = (role: Roles) => {
  switch (role) {
    case Roles.Dj:
      return {
        planColumns: [
          MarketingPlanBankanColumn.UnassignedInformationGathering,
          MarketingPlanBankanColumn.UnassignedWaitingForCoverages,
          MarketingPlanBankanColumn.UnassignedOther,
        ],
        quoteColumns: [],
        policyColumns: [],
      };
    case Roles.UnderwritingAssistant:
      return {
        planColumns: [],
        quoteColumns: [BankanQuoteColumn.BindRequestReceived],
        policyColumns: [
          ...policyColumns,
          BankanPolicyColumn.IssuedEndorsementsRequested,
          BankanPolicyColumn.ExpiringSoon,
        ],
      };
    default:
      return { planColumns, quoteColumns, policyColumns };
  }
};

export const BankanColumns = () => {
  const [selectedRole] = useAtom(bankanTabAtom);
  const { brokerId: brokerIdParam } = useParams();
  const [searchParams] = useUpsertSearchParams();
  const { ...filterArgs } = adaptSearchParams(searchParams);

  const brokerId = brokerIdParam === "unassigned" ? null : brokerIdParam || undefined;
  const { planColumns, quoteColumns, policyColumns } = statesByRole(selectedRole);

  return (
    <section className="flex max-md:flex-col flex-auto gap-2 overflow-auto px-4 w-full">
      {selectedRole === Roles.Dj && <InsuredColumn agencyId={filterArgs.agencyId} />}
      {planColumns.map((column) => (
        <MarketingPlanColumn key={column} column={column} agencyId={filterArgs.agencyId} brokerId={brokerId} />
      ))}
      {quoteColumns.map((column) => (
        <QuoteColumn key={column} column={column} agencyId={filterArgs.agencyId} brokerId={brokerId} />
      ))}
      {policyColumns.map((column) => (
        <PolicyColumn key={column} column={column} agencyId={filterArgs.agencyId} brokerId={brokerId} />
      ))}
    </section>
  );
};
