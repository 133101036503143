import { cn } from "@/utils";
import { UserAccountFragment } from "src/generated/graphql";
import { Icon, IconString } from "./icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export const Avatar = ({
  user,
  icon,
  disableTooltip = false,
  className,
}: {
  user?: Pick<UserAccountFragment, "firstName" | "lastName"> | null;
  icon?: IconString;
  disableTooltip?: boolean;
  className?: string;
}) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <div
        className={cn(
          "flex flex-none h-[2em] items-center justify-center overflow-hidden relative rounded-full w-[2em]",
          user ? "gradient-fuchs shadow-contrast" : "bg-destructive/20 text-destructive",
          disableTooltip && "pointer-events-none",
          className
        )}
      >
        {icon ? (
          <Icon icon={icon} className="filled -mt-[0.05em] text-[1.25em]" />
        ) : (
          <span className={cn("font-semibold -mt-[0.05em] text-[0.875em]")}>
            {user ? `${user.firstName[0]}${user.lastName[0]}` : "U"}
          </span>
        )}
      </div>
    </TooltipTrigger>
    {user && (
      <TooltipContent side="right" align="center">
        {user.firstName} {user.lastName}
      </TooltipContent>
    )}
  </Tooltip>
);
