import { Outlet } from "react-router";

import { Breadcrumbs } from "@/components/breadcrumbs";
import { Container, ContainerHeader } from "@/components/layout";

export const MarketingPlans = () => (
  <Container id="Marketing Plans">
    <ContainerHeader>
      <Breadcrumbs />
    </ContainerHeader>
    <Outlet />
  </Container>
);
