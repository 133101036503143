import { Outlet } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";

import { Container, ContainerHeader } from "@/components/layout";
import { InsuredsListFilter } from "./insureds-list-filter";

export const InsuredsRoot: React.FC = () => {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  return (
    <Container>
      <ContainerHeader>
        <div className="mr-auto space-y-1">
          <div className="font-semibold text-xs">
            {user.firstName} {user.lastName}
          </div>
          <div className="text-2xs">{user.agency.name}</div>
        </div>
        <HasInternalRole>
          <InsuredsListFilter />
        </HasInternalRole>
      </ContainerHeader>
      <Outlet />
    </Container>
  );
};
