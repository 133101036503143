import { isEqual } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { UUID } from "@cp/toolkit";
import {
  AppetiteOpportunityFragment,
  useCreateOpportunityMutation,
  useVerticalByCglQuery,
} from "src/generated/graphql";
import { navLinkClassName } from "./nav";

export const NavCreateMarketingPlan = ({
  cgl,
  opportunities,
}: {
  cgl: string | undefined;
  opportunities: AppetiteOpportunityFragment[];
}) => {
  const [open, setOpen] = useState(false);
  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: cgl || "" } },
    skip: !cgl,
  });
  const { marketingPlanTemplates } = verticalByCGL || {};

  const validMarketingPlanTemplates =
    marketingPlanTemplates?.filter(
      (template) => !opportunities.some((opportunity) => isEqual(opportunity.selectedLinesOfBusiness, template.lobs))
    ) || [];

  return (
    <>
      {validMarketingPlanTemplates[0] ? (
        <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
          <DialogTrigger asChild>
            <div className={cn(navLinkClassName, navClassName)}>
              Create Marketing Plan
              <span className="bg-primary flex items-center h-[1.75em] justify-center min-w-[1.75em] px-[0.5em] rounded-full text-3xs text-background">
                {validMarketingPlanTemplates.length}
              </span>
            </div>
          </DialogTrigger>
          <DialogContent className="gap-0 overflow-hidden p-0 rounded-b-lg">
            <DialogHeader className="bg-accent border-b p-4">
              <DialogTitle>Create Marketing Plan</DialogTitle>
            </DialogHeader>
            <div className="bg-border space-y-px" onClick={() => setOpen(false)}>
              <CreateMarketingPlanButton className={dialogClassName}>Custom Marketing Plan</CreateMarketingPlanButton>
              {validMarketingPlanTemplates.map((template) => (
                <CreateMarketingPlanButton
                  key={template.id}
                  selectedLinesOfBusiness={template.lobs}
                  className={dialogClassName}
                >
                  {template.lobs.join(", ")}
                </CreateMarketingPlanButton>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <CreateMarketingPlanButton>Create Marketing Plan</CreateMarketingPlanButton>
      )}
    </>
  );
};

const navClassName = "bg-background border border-primary font-semibold h-12 rounded-lg text-primary text-sm";
const dialogClassName = "border-none font-normal h-12 rounded-none text-foreground text-sm";

const CreateMarketingPlanButton = ({
  children,
  selectedLinesOfBusiness,
  className,
}: {
  children: React.ReactNode;
  selectedLinesOfBusiness?: string[];
  className?: string;
}) => {
  const navigate = useNavigate();
  const { insuredId } = useParams();

  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`${data.createOpportunity.id}`),
  });

  return (
    <div
      className={cn(navLinkClassName, navClassName, className)}
      onClick={() => {
        void createOpportunity({
          variables: {
            input: {
              insuredId: insuredId as UUID,
              desiredEffectiveDate: new Date(),
              selectedLinesOfBusiness,
            },
          },
          onCompleted: (data) => {
            navigate(`/insured/${insuredId}/${data.createOpportunity.id}`);
          },
          refetchQueries: ["InsuredOpportunities"],
        });
      }}
    >
      {children}
      <Icon icon="add_circle" className="filled" />
    </div>
  );
};
