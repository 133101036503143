import { MarketingPlanState } from "src/generated/graphql";
import { MarketingPlan } from "../marketing-plan-details";
import { Complete } from "./complete";
import { Marketing } from "./marketing";

export const MarketingPlanActions = ({ marketingPlan }: { marketingPlan: MarketingPlan }) => {
  const renderMarketingPlanState = () => {
    switch (marketingPlan.state) {
      case MarketingPlanState.Marketing:
        return <Marketing marketingPlan={marketingPlan} />;
      case MarketingPlanState.Complete:
        return <Complete marketingPlan={marketingPlan} />;
      default:
        return null;
    }
  };

  return <div className="flex gap-2 items-center">{renderMarketingPlanState()}</div>;
};
