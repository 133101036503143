import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateStringDistance } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText } from "./bankan-cards";

type Insured = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const ExpiringPoliciesCard = ({ insured }: { insured: Insured }) => {
  const { insuredId, insuredName, policies } = insured;
  return (
    <BankanCard header={insuredName}>
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, expirationDate } = policy;
        return (
          <BankanLink key={id} to={`/insured/${insuredId}/policies/${id}`} className="border-destructive">
            <BankanLinkText>
              <strong>{appetiteProduct.carrierName}: </strong>
              {appetiteProduct.carrierProductName}
            </BankanLinkText>
            <BankanLinkFooter>
              <div>#{policyNumber}</div>
              <div>Expiring {formatTimezoneDateStringDistance(expirationDate, true)}</div>
            </BankanLinkFooter>
          </BankanLink>
        );
      })}
    </BankanCard>
  );
};
