import { ExpiredPolicyState, StateIndicator } from "@/metadata";
import { PolicyFragment, PolicyState } from "src/generated/graphql";
import { NavBlockLink, NavBlockLinkContainer, NavHeader, NavSection } from "./nav";

const now = Date.now();

export const NavPolicies = ({ policies }: { policies: PolicyFragment[] }) => (
  <NavSection>
    <NavHeader label="Active Policies" />
    <NavBlockLinkContainer>
      {policies.map((policy) => {
        const { id: policyId, expirationDate, state, policyNumber, appetiteProduct } = policy;
        const { carrierName, carrierProductName } = appetiteProduct;
        const expired = Date.parse(expirationDate) < now;
        return (
          <NavBlockLink key={policyId} to={`policies/${policyId}`}>
            <div className="text-xs truncate">
              <span className="font-semibold">{carrierName}: </span> {carrierProductName}
            </div>
            <div className="flex justify-between text-2xs">
              <StateIndicator state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state} />
              <span>#{policyNumber}</span>
            </div>
          </NavBlockLink>
        );
      })}
    </NavBlockLinkContainer>
  </NavSection>
);
