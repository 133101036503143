import { useAtom } from "jotai";
import React, { useEffect } from "react";

import { correspondenceAtom, foldersVisibleAtom, selectTagsAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/utils";
import { File_Audience, useFileTagsQuery } from "src/generated/graphql";
import { useMyAccount } from "../auth/useMyAccount";
import { FileDropZone } from "./file-upload-dropzone";
import { FilesList } from "./files-list";
import { FilesOptions } from "./files-options";
import { TagRow } from "./tag-row";

export const Files: React.FC = () => {
  const [foldersVisible, setFoldersVisible] = useAtom(foldersVisibleAtom);
  const [correspondence, setCorrespondence] = useAtom(correspondenceAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectTagsAtom);
  const { data: userData } = useMyAccount();

  let { data: { fileTags = [] } = {} } = useFileTagsQuery({
    variables: {
      input: {},
    },
  });

  fileTags = fileTags.filter((t) => t?.parentTags?.length === 0) || [];

  fileTags = correspondence ? fileTags : fileTags.filter((tag) => tag.label !== "Correspondence");

  useEffect(() => {
    setFoldersVisible(false);
  }, []);

  return (
    <FileDropZone
      className="@container"
      audience={File_Audience.Internal}
      clickable={false}
      editFileDialog={userData?.internal}
    >
      <Card className="gap-px @5xl:grid grid-cols-4">
        <div className="@5xl:border-r col-span-1">
          <header className="hidden @5xl:flex h-12 items-center justify-between p-4 pb-0">
            <CardTitle>Folders</CardTitle>
          </header>
          <header className="flex @5xl:hidden items-center justify-between p-4">
            <Button
              variant="outline"
              className="gap-3"
              onClick={() => {
                void setFoldersVisible((curr) => !curr);
              }}
            >
              <Icon icon="folder_open" />
              {selectedTags || "All Files"}
              <Icon
                icon={foldersVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                className="bg-accent rounded-full"
              />
            </Button>
            <FilesOptions />
          </header>
          <header className="border-b hidden @5xl:flex h-12 items-center mb-px px-4 text-xs text-muted-foreground">
            Name
          </header>
          <div className={cn(foldersVisible ? "block" : "hidden", "@5xl:block")}>
            <div className="border-t px-4 py-2">
              {fileTags.map((t) => (
                <TagRow key={t.label} label={t.label} depth={0} />
              ))}
            </div>
            <div className="border-t flex items-center gap-3 mt-px px-4 py-4 text-xs">
              <Switch
                id="correspondence"
                checked={correspondence}
                onCheckedChange={(val) => {
                  void setSelectedTags("");
                  void setCorrespondence(val);
                }}
              />
              <Label htmlFor="correspondence">Display Correspondence</Label>
            </div>
          </div>
        </div>
        <FilesList />
      </Card>
    </FileDropZone>
  );
};
