import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { reloadOpportunity } from "@/hooks/useOpportunity";
import { useCreateBrokerageFolderForOpportunityMutation } from "src/generated/graphql";

export const GoogleDriveButton = ({
  opportunityId,
  brokerageFolderId,
}: {
  opportunityId: string;
  brokerageFolderId: string | null | undefined;
}) => {
  return (
    <>
      {brokerageFolderId ? (
        <Button variant="secondary" size="sm" className="gap-2" asChild>
          <a href={`https://drive.google.com/drive/folders/${brokerageFolderId}`} target="_blank" rel="noreferrer">
            Google Drive
            <Icon icon="open_in_new" />
          </a>
        </Button>
      ) : (
        <CreateFolderButton opportunityId={opportunityId} />
      )}
    </>
  );
};

const CreateFolderButton = ({ opportunityId }: { opportunityId: string }) => {
  const { toast } = useToast();
  const [createBrokerageFolder, { loading }] = useCreateBrokerageFolderForOpportunityMutation({
    variables: {
      id: opportunityId,
    },
    ...reloadOpportunity,
    onCompleted: () => {
      toast({ title: "Google Drive folder created" });
    },
    onError: () => {
      toast({ title: "Error", description: "Failed to create Google Drive folder", variant: "destructive" });
    },
  });

  return (
    <Button variant="secondary" size="sm" className="gap-2" onClick={() => createBrokerageFolder()}>
      {loading ? (
        <>
          Creating
          <Spinner />
        </>
      ) : (
        <>
          Create Google Drive Folder
          <Icon icon="create_new_folder" />
        </>
      )}
    </Button>
  );
};
